import React from "react";
import "react-step-progress-bar/styles.css";
import { Container, Grid } from "@mui/material";
import ProgressMoon from "./ProgressMoon";
import ErrorAlert from "../../images/errorAlert.png";
import BodyBox from "../modal/BodyBox";
import BoxButton from "../tile/BoxButton";

function JumboProgress({
  progress,
  log,
  error,
  totalSteps,
  hints,
  action,
  onClose,
  doNotOpenIf,
}) {
  return !doNotOpenIf && progress >= 0 ? (
    <div className={"underJumbo"}>
      <div className={"vertical-center"}>
        <Grid
          className={"jumboProgress"}
          container
          spacing={0}
          alignItems="flex-end"
        >
          <Grid item xs={12} sm={12} md={12}>
            {log || error ? (
              <>
                <div className={"action centered"}>{action}</div>
                {error ? (
                  ""
                ) : action ? (
                  <div className={"subAction centered"}>
                    Hover over each node to in the timeline to learn more about
                    each step.
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : null}
            {progress > -1 && totalSteps > 0 && !error ? (
              <Container
                disableGutters
                maxWidth="sm"
                component="main"
                sx={{ pt: 8, pb: 6 }}
                style={{ padding: "0 0 12px" }}
              >
                <ProgressMoon
                  progress={progress}
                  totalSteps={totalSteps}
                  hints={hints}
                />
              </Container>
            ) : null}
            {log || error ? (
              <Container
                disableGutters
                maxWidth="sm"
                component="main"
                sx={{ pt: 8, pb: 6 }}
                style={{ padding: 0 }}
              >
                {error ? (
                  <>
                    <BodyBox className={"errorBox centered"}>
                      <div className={"errorIcon"}>
                        <img src={ErrorAlert} alt={"error alert"} />
                      </div>
                      <div className={"errorTextTitle bold"}>
                        An error occurred!
                      </div>
                      <div className={"errorText light"}>
                        {Array.isArray(error)
                          ? error.map((e, index) => (
                              <p key={"key" + index}>{e}</p>
                            ))
                          : error}
                      </div>
                    </BodyBox>
                    <BoxButton
                      buttonText={"close"}
                      onClick={onClose}
                      className={"popupClose"}
                    />
                  </>
                ) : log ? (
                  <div
                    style={{
                      // padding: progress < 100 ? "24px 0 0px" : 0,
                      // marginBottom: 32,
                      textAlign: "center",
                    }}
                  >
                    {/*{progress < 100 ? <LinearProgress color="inherit" /> : null}*/}

                    <div style={{ padding: "0 0 16px", color: "#8835EF" }}>
                      {log}
                    </div>
                  </div>
                ) : null}
              </Container>
            ) : null}
          </Grid>
        </Grid>
      </div>{" "}
    </div>
  ) : null;
}

export default JumboProgress;
