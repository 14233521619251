import _ from "lodash";

import { BN } from "./PayloadUtils";
import { tokenTypes } from "../config";

import {
  calculateTaxOnRewards,
  calculateUntaxedRewards,
  boostRewards,
} from "./sidePoolViews";

function pendingRewards(extraConf, user, blockTimestamp) {
  let rewards = untaxedPendingRewards(extraConf, user, blockTimestamp);
  if (rewards > 0) {
    let tax = calculateTaxOnRewards(extraConf, rewards);
    rewards = rewards.sub(tax);
  }
  return rewards;
}

function untaxedPendingRewards(extraConf, user, timestamp) {
  let rewards = BN();
  for (let i = 0; i < user.deposits.length; i++) {
    rewards = rewards.add(
      calculateUntaxedRewards(
        extraConf,
        user.deposits[i],
        timestamp,
        user.lastRewardsAt
      )
    );
  }
  if (rewards > 0) {
    rewards = boostRewards(
      extraConf,
      rewards,
      user.stakedAmount,
      user.passAmountForBoost,
      user.blueprintAmountForBoost
    );
  }
  return rewards;
}

function canUnstakeWithoutTax(deposit, blockTimestamp) {
  return deposit.lockedUntil > 0 && blockTimestamp > deposit.lockedUntil;
}

function getGenerator(conf, extraConf, amount = 0, tokenType) {
  amount = BN(amount);
  return amount
    .mul(
      tokenType === tokenTypes.S_SYNR_SWAP ? conf.swapFactor : conf.stakeFactor
    )
    .div(100)
    .mul(extraConf.priceRatio)
    .div(10000);
}

async function getFullConf(pool) {
  return Object.assign(await getConf(pool), await getExtraConf(pool));
}

async function getConf(pool) {
  return _.pick(await pool.conf(), [
    "rewardsFactor",
    "decayInterval",
    "decayFactor",
    "maximumLockupTime",
    "poolInitAt",
    "lastRatioUpdateAt",
    "swapFactor",
    "stakeFactor",
    "taxPoints",
    "coolDownDays",
    "status",
  ]);
}

async function getExtraConf(pool) {
  return _.pick(await pool.extraConf(), [
    "blueprintAmount",
    "sPSynrEquivalent",
    "sPBoostFactor",
    "sPBoostLimit",
    "bPSynrEquivalent",
    "bPBoostFactor",
    "bPBoostLimit",
    "burnRatio",
    "priceRatio",
  ]);
}

async function getUser(pool, address) {
  let user = _.pick(await pool.users(address), [
    "passAmount",
    "passAmountForBoost",
    "blueprintAmount",
    "blueprintAmountForBoost",
    "stakedAmount",
    "generator",
    "lastRewardsAt",
  ]);
  user.deposits = [];
  let len = await pool.getDepositsLength(address);
  for (let i = 0; i < len; i++) {
    user.deposits.push(await getDeposit(pool, address, i));
  }
  return user;
}

async function getDeposit(pool, address, index) {
  return _.pick(await pool.getDepositByIndex(address, index), [
    "tokenType",
    "lockedFrom",
    "lockedUntil",
    "tokenAmountOrID",
    "otherChain",
    "stakedAmount",
    "tokenID",
    "unlockedAt",
    "mainIndex",
    "generator",
    "rewardsFactor",
    "extra",
    "extra1",
    "extra2",
    "extra3",
    "extra4",
  ]);
}

async function getSeedTvl(pool) {
  return _.pick(await pool.conf(), ["blueprintAmount"]);
}

function calculateTokenAmount(conf, extraConf, amount, tokenType) {
  return BN(amount)
    .mul(
      tokenType === tokenTypes.S_SYNR_SWAP ? conf.swapFactor : conf.stakeFactor
    )
    .mul(extraConf.priceRatio)
    .div(1000000);
}

function getStakedAndLockedAmount(conf, extraConf, tokenType, tokenAmountOrID) {
  let stakedAmount = BN();
  let generator = BN();
  if (tokenType === tokenTypes.S_SYNR_SWAP) {
    generator = calculateTokenAmount(
      conf,
      extraConf,
      tokenAmountOrID,
      tokenType
    );
  } else if (tokenType === tokenTypes.SYNR_STAKE) {
    generator = calculateTokenAmount(
      conf,
      extraConf,
      tokenAmountOrID,
      tokenType
    );
    stakedAmount = BN(tokenAmountOrID);
  } else if (tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_SEEDS) {
    stakedAmount = BN(extraConf.sPSynrEquivalent).mul(1e18);
    generator = calculateTokenAmount(conf, extraConf, stakedAmount, tokenType);
  } else if (tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_SEEDS) {
    stakedAmount = BN(extraConf.bPSynrEquivalent).mul(1e18);
    generator = calculateTokenAmount(conf, extraConf, stakedAmount, tokenType);
  }
  return { stakedAmount, generator };
}

export {
  pendingRewards,
  untaxedPendingRewards,
  canUnstakeWithoutTax,
  getFullConf,
  getSeedTvl,
  getExtraConf,
  getConf,
  getDeposit,
  getUser,
  getGenerator,
  getStakedAndLockedAmount,
  calculateTokenAmount,
};
