import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import RewardsIcon from "../../images/SEED_Double_small.png";

import { isMobile, parseAnd2Decimals, enUsFormat } from "../../utils";

import Popup from "../modal/Popup";
import TitleBox from "../modal/TitleBox";
import BodyBox from "../modal/BodyBox";
import BoxButton from "../tile/BoxButton";
import Paper from "@mui/material/Paper";

const styles = {
  body: {
    maxHeight: 140,
    borderRadius: 20,
    backgroundColor: "transparent !important",
    backgroundImage: "none",
  },
};

function SeedRewardsDialogue({
  pool,
  opened,
  setOpened,
  claim,
  pendingRewards = "0",
  boostedPendingRewards = "0",
  untaxedPendingRewards = "0",
}) {
  // eslint-disable-next-line
  const [error, setError] = useState("");

  const closeDialog = () => {
    setOpened(false);
  };

  const onClaimClick = async (ev) => {
    if (!error) {
      setOpened(false);
      claim();
    }
  };

  let untaxed = "0";
  let taxes = "0";
  let boost = "0";

  if (untaxedPendingRewards !== "0" && boostedPendingRewards !== "0") {
    untaxed = untaxedPendingRewards;
    boost = boostedPendingRewards.sub(untaxed);
    taxes = boostedPendingRewards.sub(pendingRewards);
  }

  if (pool) {
    return (
      <Popup
        onClose={closeDialog}
        show={opened}
        style={{ minWidth: isMobile() ? "90%" : "30%" }}
        // additionalClass="Seed"
      >
        <TitleBox id="staking-dialog-title" icon={RewardsIcon}>
          Pending Rewards
        </TitleBox>
        <BodyBox>
          <TableContainer
            component={Paper}
            sx={{
              minWidth: isMobile() ? 0 : 560,
              width: isMobile() ? "96%" : "70%",
              margin: "auto",
              boxShadow: "none",
            }}
            style={styles.body}
            className={"left-container"}
          >
            <Table aria-label="staking pools">
              <TableHead>
                <TableRow>
                  <TableCell className="tableCell" align="center">
                    <span className={"light"}>Base</span>
                  </TableCell>
                  <TableCell className="tableCell" align="center">
                    <span className={"light"}>Boost</span>
                  </TableCell>
                  <TableCell className="tableCell" align="center">
                    <span className={"light"}>Total untaxed</span>
                  </TableCell>
                  <TableCell className="tableCell" align="center">
                    <span className={"light"}>Taxes</span>
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    <span className={"light"}>Claimable balance</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center" className="tableCell">
                    {enUsFormat(parseAnd2Decimals(untaxed))}
                  </TableCell>
                  <TableCell align="center" className="tableCell">
                    {enUsFormat(parseAnd2Decimals(boost))}
                  </TableCell>
                  <TableCell align="center" className="tableCell">
                    {enUsFormat(parseAnd2Decimals(boostedPendingRewards))}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ whiteSpace: "nowrap" }}
                    className="tableCell"
                  >
                    -{enUsFormat(parseAnd2Decimals(taxes))}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ whiteSpace: "nowrap" }}
                    className="tableCell"
                  >
                    <b>{enUsFormat(parseAnd2Decimals(pendingRewards))}</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className={"lowerButton"}>
            <BoxButton
              onClick={onClaimClick}
              buttonText={"CLAIM SEED"}
              disabled={!parseFloat(pendingRewards) || !!error}
              className={"bottomButton"}
            />
          </div>
        </BodyBox>
      </Popup>
    );
  }

  return null;
}

export default SeedRewardsDialogue;
