import superagent from "superagent";

class ClientApi {
  setDefaultHeaders(wallet, chainId) {
    this.connectedWallet = wallet;
    this.chainId = chainId;
    this.isTestnet = chainId > 56;
  }

  async request(
    api,
    method = "get",
    params = {},
    query = {},
    multipart,
    headers = {}
  ) {
    headers = Object.assign(
      {
        Accept: "application/json",
        "Connected-wallet": this.connectedWallet || "",
        "Chain-id": this.chainId,
        "Is-testnet": this.isTestnet,
      },
      headers
    );
    if (multipart) {
      headers["Content-Type"] = "multipart/form-data";
    }
    let endpoint;
    // if (/local/.test(window.location.origin)) {
    //   endpoint = "http://localhost:3003/api";
    // } else {
    endpoint = "https://api.mob.land";
    // }
    const res = await superagent[method](`${endpoint}/v1/${api}`)
      .set(headers)
      .query(query)
      .send(params);
    return res.body;
  }
}

let instance;
if (!instance) instance = new ClientApi();

export default instance;
