import { isTestnet } from "../config";
import { toHex } from "../utils/networkUtils";

async function switchTo(set) {
  if (set === 1) {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: isTestnet ? toHex(42) : toHex(1) }],
    });
  } else {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: isTestnet ? toHex(44787) : toHex(1) }],
    });
  }
}

function Switch(props) {
  if (props.section === "core") {
    return (
      <span
        style={{ cursor: "pointer", color: "#ffee00" }}
        onClick={() => switchTo(1)}
      >
        Click here to switch to {isTestnet ? "Kovan testnet" : "Ethereum"}
      </span>
    );
  } else {
    return (
      <span
        style={{ cursor: "pointer", color: "#ffee00" }}
        onClick={() => switchTo(2)}
      >
        Click here to switch to {isTestnet ? "Celo Alfajores" : "Ethereum"}
      </span>
    );
  }
}
export default Switch;
