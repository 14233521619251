import React from "react";

function UnderTilesNFT() {
  return (
    <div className={"centered light z8rem"} style={{ paddingTop: 6 }}>
      <div>
        Get SYNR Pass on{" "}
        <a href={"https://opensea.io/collection/mobland-genesis-synr-pass"}>
          OpenSea
        </a>{" "}
        or{" "}
        <a
          href={"https://element.market/collections/mobland-genesis-synr-pass"}
        >
          Element
        </a>
        , and Blueprints on{" "}
        <a
          href={
            "https://www.binance.com/en/nft/collection/syn-city-limited-blueprint-519022065384943617?orderBy=favorites&orderType=-1&isBack=1&id=519022065384943617&order=favorites%40-1"
          }
        >
          Binance
        </a>
        {" or "}
        <a href={"https://element.market/collections/mobland-blueprints"}>
          Element
        </a>{" "}
        or{" "}
        <a href={"https://marketplace.mob.land/?tab=blueprints"}>
          MOBLAND Marketplace
        </a>
      </div>
      {/*<div>*/}
      {/*  The MOBLAND Marketplace is temporarily offline 🏗️ Team is working on it*/}
      {/*  & we'll update you once its back online 🙏*/}
      {/*  <br />*/}
      {/*  For all official updates, please join the MOBLAND Discord server:{" "}*/}
      {/*  <a href={"https://discord.gg/mobland"}>discord.gg/mobland</a>*/}
      {/*</div>*/}
    </div>
  );
}

export default UnderTilesNFT;
