import React, { useContext } from "react";
import { Card, CardContent } from "@mui/material";

import Icon from "./Icon";
import Title from "./Title";
import Description from "./Description";
import { StoreContext } from "../../contexts/StoreContext";
import BoxButton from "./BoxButton";
import { setSection } from "../../utils";

function TileItem({
  cls = "",
  alt,
  title,
  icon,
  navigateTo,
  description,
  titleUpperCase,
  iconStyle,
  currentSection,
  buttonText,
  route,
  myref = null,
  buttonComponent,
  disable,
  children,
}) {
  const { store, db } = useContext(StoreContext);
  return (
    <div className={"tile-base"} ref={myref}>
      <Card className={"paperContainer " + cls}>
        <CardContent>
          {icon ? (
            <Icon src={icon} alt={alt || title} iconStyle={iconStyle} />
          ) : null}
          {title ? (
            <Title
              navigateTo={navigateTo}
              title={title}
              upperCase={titleUpperCase}
            />
          ) : null}
          {children ? <div>{children}</div> : null}
          {description ? <Description description={description} /> : null}
          {buttonComponent && !disable ? (
            buttonComponent
          ) : buttonText ? (
            <BoxButton
              onClick={() =>
                currentSection
                  ? currentSection === "core"
                    ? setSection(store, db, "seed")
                    : setSection(store, db, "core")
                  : null
              }
              buttonText={buttonText}
              disabled={disable}
              route={route}
            />
          ) : null}
        </CardContent>
      </Card>
    </div>
  );
}

export default TileItem;
