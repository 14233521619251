import { FormControl, Grid, Typography } from "@mui/material";
import { enUsFormat, parseAnd2Decimals } from "../../utils";
import React from "react";
import { tokenTypes } from "../../config";

function StakedAmount({ amount, onChange, onBtnClick, balance, tokenType }) {
  return (
    <Grid item xs={12} justifyContent="center">
      <FormControl component="fieldset" sx={{ width: "100%" }}>
        <Grid container>
          <Grid item xs={6}>
            <div className={"light"}>Amount</div>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              <span className={"light"}>Balance:</span>{" "}
              <span className={"bold"}>
                {enUsFormat(parseAnd2Decimals(balance))}{" "}
                {tokenType === tokenTypes.SYNR_STAKE ? "SYNR" : "sSYNR"}
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} justifyContent="center">
            <input
              id="staked-amount"
              type="number"
              value={amount}
              onChange={onChange}
              className={"darkBg"}
            />
            <div>
              <button
                onClick={() => onBtnClick(25)}
                className={"underInputButton"}
              >
                25%
              </button>
              <button
                onClick={() => onBtnClick(50)}
                className={"underInputButton"}
              >
                50%
              </button>
              <button
                onClick={() => onBtnClick(75)}
                className={"underInputButton"}
              >
                75%
              </button>
              <button
                onClick={() => onBtnClick(100)}
                className={"underInputButton"}
              >
                100%
              </button>
            </div>
          </Grid>
        </Grid>
        {/*<Grid container>*/}
        {/*  <Grid item xs={12}>*/}
        {/*    <FormHelperText>*/}
        {/*      Est APY: {amount !== 0 ? localeFormat(apy) + "%" : "--"}*/}
        {/*    </FormHelperText>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </FormControl>
    </Grid>
  );
}

export default StakedAmount;
