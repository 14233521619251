import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import React from "react";

function Button2({
  onClick = function () {},
  buttonText,
  route,
  link,
  disabled,
  className = "",
}) {
  return (
    <Button
      className={"tile-button " + (disabled ? "disabled " : "") + className}
      onClick={onClick}
      variant="outlined"
      disabled={disabled}
      style={{ margin: "0 12px" }}
    >
      {route || link ? (
        <Link className={"simple-text"} to={route || link}>
          {buttonText}
        </Link>
      ) : (
        buttonText
      )}
    </Button>
  );
}

export default Button2;
