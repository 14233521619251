import React, { useContext, useState, useEffect } from "react";
import {
  DialogActions,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  CircularProgress,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import AlertIcon from "@mui/icons-material/Warning";

import { enUsFormat, isMobile, parseAnd2Decimals } from "../../utils";
import { Web3Context } from "../../contexts/Web3Context";
import { tokenTypes } from "../../config";
import { calculatePenaltyForEarlyUnstake } from "../../utils/mainPool";
import { getVestedPercentage } from "../../utils/sidePoolViews";
import { timestamp, BN } from "../../utils/PayloadUtils";
import Popup from "../modal/Popup";
import TitleBox from "../modal/TitleBox";
import BoxButton from "../tile/BoxButton";

const styles = {
  buttonStyle: {
    backgroundColor: "#00000060",
    color: "#FFEE00",
    fontSize: "95%",
  },
  body: {
    // backgroundColor: "#301",
    backgroundColor: "transparent !important",
    backgroundImage: "none",
  },
};

function UnstakingDialog({
  pool,
  deposit,
  opened,
  setOpened,
  unstake,
  convertInDays,
}) {
  const [unstakedAmount, setUnstakedAmount] = useState(0);
  const { web3NetworkDetails } = useContext(Web3Context);
  const [sidePenalty, setSidePenalty] = useState(0);
  const [mainPenalty, setMainPenalty] = useState(0);
  const [mainProceeds, setMainProceeds] = useState(0);
  const [ready, setReady] = useState(false);
  const [gotten, setGotten] = useState(-1);
  const [sideProceeds, setSideProceeds] = useState(0);

  const fullDays = (sec) => {
    if (sec / (24 * 3600) > 1) {
      return Math.round(sec / (24 * 3600)) + " Days";
    } else {
      return Math.round(sec / 3600) + " Hours";
    }
  };

  useEffect(() => {
    (async () => {
      if (gotten !== deposit.mainIndex) {
        setGotten(deposit.mainIndex);
        if (deposit?.tokenType === tokenTypes.S_SYNR_SWAP) {
          let vested = getVestedPercentage(
            timestamp(),
            deposit.lockedFrom,
            deposit.lockedUntil
          );
          let generator = BN(deposit.generator);
          let amount = generator.mul(vested).div(10000);
          let penalties = generator.sub(amount);
          setSideProceeds(enUsFormat(parseAnd2Decimals(amount)));
          setSidePenalty(enUsFormat(parseAnd2Decimals(penalties)));
          setUnstakedAmount(enUsFormat(parseAnd2Decimals(generator)));
        } else if (deposit?.tokenType === tokenTypes.SYNR_STAKE) {
          let mainConf = await pool.mainPoolConf(web3NetworkDetails);
          let penalty = calculatePenaltyForEarlyUnstake(
            mainConf,
            timestamp(),
            deposit
          );
          setMainPenalty(enUsFormat(parseAnd2Decimals(penalty)));
          setMainProceeds(
            enUsFormat(parseAnd2Decimals(BN(deposit.stakedAmount).sub(penalty)))
          );
        }
      }
    })();
  }, [gotten, setGotten, deposit, pool, web3NetworkDetails]);

  const closeDialog = () => {
    setReady(false);
    setOpened(false);
    setGotten(-1);
    setSideProceeds(0);
    setSidePenalty(0);
    setUnstakedAmount(0);
    setMainProceeds(0);
    setMainPenalty(0);
  };

  const handleChange = () => {
    setReady(!ready);
  };

  const onUnstakeClick = async (ev) => {
    setOpened(false);
    setReady(false);
    unstake(undefined, true);
  };

  function modalRender() {
    if (gotten > -1) {
      if (deposit.tokenType === tokenTypes.SYNR_STAKE) {
        return (
          <div>
            <div className={"overTable purple"}>
              You still have {convertInDays(deposit.lockedUntil)} remaining on
              your agreed {fullDays(deposit.lockedUntil - deposit.lockedFrom)}{" "}
              locking period
            </div>
            <div className={"over-left-container typed"}>
              <TableContainer
                component={Paper}
                sx={{
                  minWidth: isMobile() ? 0 : 480,
                  width: isMobile() ? "96%" : "70%",
                  margin: "auto",
                }}
                style={styles.body}
              >
                <Table aria-label="staking pools" className={"redAlertTable"}>
                  <TableBody>
                    <TableRow>
                      <TableCell className="tableCell" align="center">
                        Staked SYNR
                      </TableCell>
                      <TableCell className="tableCell" align="center">
                        SYNR Penalty
                      </TableCell>
                      <TableCell className="tableCell" align="center">
                        SYNR Claim
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        scope="row"
                        className="tableCell"
                        align="center"
                      >
                        {enUsFormat(parseAnd2Decimals(deposit.stakedAmount))}
                      </TableCell>
                      <TableCell className="tableCell" align="center">
                        {mainPenalty}
                      </TableCell>
                      <TableCell className="tableCell" align="center">
                        {mainProceeds}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        );
      } else if (deposit.tokenType === tokenTypes.S_SYNR_SWAP) {
        return (
          <div>
            <div className={"overTable purple"}>
              You still have {convertInDays(deposit.lockedUntil)} remaining on
              the 14 days germination period
            </div>
            <div className={"over-left-container typed"}>
              <TableContainer
                component={Paper}
                sx={{
                  minWidth: isMobile() ? 0 : 480,
                  width: isMobile() ? "96%" : "70%",
                  margin: "auto",
                }}
                style={styles.body}
              >
                <Table aria-label="staking pools" className={"redAlertTable"}>
                  <TableBody>
                    <TableRow>
                      <TableCell className="tableCell" align="center">
                        SEED Amount
                      </TableCell>
                      <TableCell className="tableCell" align="center">
                        SEED Penalty
                      </TableCell>
                      <TableCell className="tableCell" align="center">
                        SEED Claim
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        className="tableCell"
                        scope="row"
                        align="center"
                      >
                        {unstakedAmount}
                      </TableCell>
                      <TableCell className="tableCell" align="center">
                        {sidePenalty}
                      </TableCell>
                      <TableCell className="tableCell" align="center">
                        {sideProceeds}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className={"centered"}>
          <CircularProgress />
        </div>
      );
    }
  }

  if (pool) {
    return (
      <Popup
        onClose={closeDialog}
        show={opened}
        style={{ minWidth: isMobile() ? "90%" : "50%", borderColor: "#745094" }}
      >
        <TitleBox id="staking-dialog-title">
          <AlertIcon
            style={{
              position: "relative",
              top: 5,
              color: "red",
              marginRight: 2,
            }}
          />{" "}
          WARNING: Early Unlock Penalty!
        </TitleBox>
        {modalRender()}
        <DialogActions>
          <div className="underRadio">
            <div className="underRadio" style={{ float: "left" }}>
              <label className={"light bit-smaller"}>
                <input
                  type={"checkbox"}
                  checked={ready}
                  onChange={() => handleChange()}
                  value={true}
                  style={{
                    filter: "invert(100%) hue-rotate(226deg) brightness(1.0)",
                    marginRight: 8,
                  }}
                />
                I understand and agree that I will be penalized for{" "}
                {deposit.tokenType === tokenTypes.S_SYNR_SWAP
                  ? "claiming my SEED"
                  : "unlocking my deposit"}{" "}
                before the pre-determined locking period has completed. All
                pending SEED rewards accumulated across all my stakes will be
                auto-claimed by executing this function.
              </label>
            </div>
          </div>
          <div
            className={"centered"}
            style={{ float: "right", paddingRight: 8 }}
          >
            <BoxButton
              onClick={onUnstakeClick}
              buttonText={"UNSTAKE"}
              disabled={!ready}
            />
          </div>
        </DialogActions>
      </Popup>
    );
  }

  return null;
}

export default UnstakingDialog;
