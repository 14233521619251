import { tokenTypes } from "../config";
import Address from "./Address";

function allContracts(networkDetails) {
  const { contracts } = networkDetails;

  const {
    MainPool,
    SyndicateERC20,
    SyntheticSyndicateERC20,
    SyndicateCorePool,
    SynCityPasses,
    SynrBridge,
    MainTesseract,
    SideTesseract,

    SynCityCoupons,
    SeedFactory,
    SeedPool,
    SeedToken,
  } = contracts;

  return {
    MainPool,
    SyndicateERC20,
    SyntheticSyndicateERC20,
    SyndicateCorePool,
    SynCityPasses,
    SynrBridge,
    MainTesseract,
    SideTesseract,

    SynCityCoupons,
    SeedFactory,
    SeedPool,
    SeedToken,
  };
}

async function isApproved(
  networkDetails,
  amountOrID,
  pool = "SyndicateCorePool",
  tokenType = tokenTypes.SYNR_STAKE
) {
  let allowance;
  let approved;
  const { connectedWallet } = networkDetails;
  const {
    SyndicateERC20,
    SyntheticSyndicateERC20,
    SynCityPasses,
    SynCityCoupons,
  } = allContracts(networkDetails);
  pool = allContracts(networkDetails)[pool];

  switch (tokenType) {
    case tokenTypes.S_SYNR_SWAP:
      allowance = await SyntheticSyndicateERC20.allowance(
        connectedWallet,
        pool.address
      );
      return allowance.gte(amountOrID);
    case tokenTypes.SYNR_STAKE:
      allowance = await SyndicateERC20.allowance(connectedWallet, pool.address);
      return allowance.gte(amountOrID);
    case tokenTypes.SYNR_PASS_STAKE_FOR_BOOST:
    case tokenTypes.SYNR_PASS_STAKE_FOR_SEEDS:
      approved = await SynCityPasses.getApproved(amountOrID);
      return Address.equal(pool.address, approved);
    case tokenTypes.BLUEPRINT_STAKE_FOR_BOOST:
    case tokenTypes.BLUEPRINT_STAKE_FOR_SEEDS:
      approved = await SynCityCoupons.getApproved(amountOrID);
      return Address.equal(pool.address, approved);
    default:
  }
}

async function approveAmount(
  networkDetails,
  amountOrID,
  pool = "SyndicateCorePool",
  tokenType = tokenTypes.SYNR_STAKE,
  setLog
) {
  const { signer } = networkDetails;
  const {
    SyndicateERC20,
    SyntheticSyndicateERC20,
    SynCityPasses,
    SynCityCoupons,
  } = allContracts(networkDetails);

  pool = allContracts(networkDetails)[pool];

  function log() {
    if (setLog) {
      setLog("Waiting for confirmation...");
    }
  }

  try {
    let tx;
    switch (tokenType) {
      case tokenTypes.S_SYNR_SWAP:
        tx = await SyntheticSyndicateERC20.connect(signer).approve(
          pool.address,
          amountOrID
        );
        log();
        await tx.wait();
        break;
      case tokenTypes.SYNR_STAKE:
        tx = await SyndicateERC20.connect(signer).approve(
          pool.address,
          amountOrID
        );
        log();
        await tx.wait();
        break;
      case tokenTypes.SYNR_PASS_STAKE_FOR_BOOST:
      case tokenTypes.SYNR_PASS_STAKE_FOR_SEEDS:
        tx = await SynCityPasses.connect(signer).approve(
          pool.address,
          amountOrID
        );
        log();
        await tx.wait();
        break;
      case tokenTypes.BLUEPRINT_STAKE_FOR_BOOST:
      case tokenTypes.BLUEPRINT_STAKE_FOR_SEEDS:
        tx = await SynCityCoupons.connect(signer).approve(
          pool.address,
          amountOrID
        );
        log();
        await tx.wait();
        break;
      default:
    }
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export { isApproved, approveAmount, allContracts };
