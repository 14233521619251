// React
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { authFilter } from "../utils";
import usePageViewTracking from "../hooks/usePageViewTracking";

// MUI
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// internal imports
import Web3ContextProvider from "../contexts/Web3Context";
import StoreContextProvider from "../contexts/StoreContext";
import PoolContextProvider from "../contexts/PoolContext";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";
import Staking from "./Staking";
import Vesting from "./Vesting";
import config, { isProd, isStage, isJero } from "../config";
import Error404 from "./Error404";
import CoreDashboard from "./corePool/CoreDashboard";
import Overview from "./overview/Overview";
import SeedDashboard from "./seedFarm/SeedDashboard";
import TermsAndConditions from "./TermsAndConditions";
import sha256 from "js-sha256";
import { ethers } from "ethers";
import DevTools from "./DevTools";
import { minimalisticHash } from "../utils";

let initBgDone = false;
let ready = isProd;

function App() {
  const { location } = window;

  const [showTerms, setShowTerms] = useState(false);

  function detectSimulation() {
    const query = new URLSearchParams(location.search);
    let simulate = query.get("simulate");
    if (simulate) {
      simulate = simulate.split(":");
      if (
        sha256(simulate[0]) ===
          "0a3ad29d26dcdc14a7986b7c6fa3ca6ed2e4ce261c15b8d0407087bed52acfc5" &&
        ethers.utils.isAddress(simulate[1])
      ) {
        localStorage.setItem("simulate", simulate[1]);
      } else {
        localStorage.removeItem("simulate");
      }
    }
  }

  detectSimulation();

  if (isProd) {
    ready = true;
  } else {
    const auth = authFilter(location);
    // if (auth[1]) {
    //   showAll = false;
    // }
    if (auth[0]) {
      ready = true;
    }
  }

  const theme = createTheme({
    palette: {
      mode: "dark",
      secondary: {
        main: "#4E4E4E",
        darker: "#4E4E4E",
      },
      primary: {
        main: "#8835EF",
        darker: "#8835EF",
      },
      info: {
        main: "#FFFFFF",
        darker: "#FFFFFF",
      },
    },

    typography: {
      fontFamily: ['"Modeco"'].join(","),
    },
  });

  if (!/local/.test(location.origin)) {
    if (window.location.protocol === "http:") {
      window.location = "https://" + location.host + location.pathname;
    }
  }

  function getWidth() {
    return window.innerWidth;
  }

  // eslint-disable-next-line no-unused-vars
  const [windowWidth, setWindowWidth] = useState(getWidth());

  if (!initBgDone) {
    // const s = (window.location.pathname || "").split("/")[1];
    initBgDone = true;
  }

  useEffect(() => {
    return () => {
      window.addEventListener("resize", () => setWindowWidth(getWidth()));
    };
  }, []);

  return (
    <StoreContextProvider>
      <Web3ContextProvider config={config}>
        <PoolContextProvider>
          <BrowserRouter>
            <usePageViewTracking />
            <ThemeProvider theme={theme}>
              <GlobalStyles
                styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
              />
              <CssBaseline />
              {ready ? (
                <>
                  <NavigationBar />
                  <Routes>
                    <Route
                      exact
                      path="/"
                      element={<Navigate to={"/core/dashboard"} />}
                    />
                    {/*CORE*/}
                    <Route
                      exact
                      path="/core/dashboard"
                      element={<CoreDashboard />}
                    />
                    <Route exact path="/seed/staking" element={<Staking />} />
                    <Route exact path="/seed/vesting" element={<Vesting />} />
                    <Route
                      exact
                      path="/seed/dashboard"
                      element={<SeedDashboard />}
                    />
                    <Route
                      exact
                      path="/info/overview"
                      element={<Overview subsection={"howtoplay"} />}
                    />
                    <Route
                      exact
                      path="/info/overview/howtoplay"
                      element={<Overview subsection={"howtoplay"} />}
                    />
                    <Route
                      exact
                      path="/info/overview/seedfarmguide"
                      element={<Overview subsection={"seedfarmguide"} />}
                    />
                    <Route
                      exact
                      path="/info/overview/assetsguide"
                      element={<Overview subsection={"assetsguide"} />}
                    />
                    {window.location.href.includes("localhost") ? (
                      <Route exact path="/dev" element={<DevTools />} />
                    ) : null}
                    <Route exact path="*" element={<Error404 />} />
                  </Routes>
                  {isStage && !isJero ? (
                    <div className="alpha-message">
                      The current data & parameters are for focus group alpha
                      tests. They do not reflect the final parameters
                    </div>
                  ) : null}
                  <Footer setShowTerms={setShowTerms} />
                  <TermsAndConditions
                    showTerms={showTerms}
                    setShowTerms={setShowTerms}
                  />
                </>
              ) : null}
            </ThemeProvider>
          </BrowserRouter>
        </PoolContextProvider>
      </Web3ContextProvider>
    </StoreContextProvider>
  );
}

export default App;
