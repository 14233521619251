import React, { useState, useContext, useEffect } from "react";
import BoxButton from "./tile/BoxButton";
import { Web3Context } from "../contexts/Web3Context";
import {
  WORMHOLE_BRIDGE_TYPE,
  WORMHOLE_RPC_HOSTS,
  WORMHOLE_TESTNET_RPC_HOSTS,
} from "../config/constants";
import { wormholeConfig } from "../config";
import { StoreContext } from "../contexts/StoreContext";

function DevTools() {
  let { web3NetworkDetails } = useContext(Web3Context);
  const { db } = useContext(StoreContext);

  function isMainChain(net) {
    return net.chainId === 1 || net.chainId === 44787;
  }

  function isSideChain(net) {
    return net.chainId === 56 || net.chainId === 43113;
  }

  const [tx, setTx] = useState("");
  const [vaaArray, setVaaArray] = useState("");
  const [hexVaa, setHexVaa] = useState("");
  const [message, setMessage] = useState("");

  const { MainWormholeBridge, SideWormholeBridge } =
    web3NetworkDetails.contracts;

  const wormholeBridge = MainWormholeBridge || SideWormholeBridge;

  async function getVaa() {
    resetDb();
    const whChainId = wormholeConfig.byChainId[web3NetworkDetails.chainId][0];

    const { parseSequenceFromLogEth, getEmitterAddressEth } =
      global.wormhole || require("@certusone/wormhole-sdk");

    const { getSignedVAAWithRetry } =
      global.wormhole ||
      require("@certusone/wormhole-sdk/lib/esm/rpc/getSignedVAAWithRetry");

    const sendReceipt = await web3NetworkDetails.provider.getTransactionReceipt(
      tx
    );
    const wormholeAddress = await wormholeBridge.wormhole();
    const sequence = parseSequenceFromLogEth(sendReceipt, wormholeAddress);

    const { vaaBytes } = await getSignedVAAWithRetry(
      web3NetworkDetails.chainId === 1 || web3NetworkDetails.chainId === 56
        ? WORMHOLE_RPC_HOSTS
        : WORMHOLE_TESTNET_RPC_HOSTS,
      whChainId,
      getEmitterAddressEth(wormholeBridge.address),
      sequence.toString()
    );

    let vaaArray = [];
    for (let k in vaaBytes) {
      vaaArray.push(vaaBytes[k]);
    }
    // const int32VaaBytes = new Int32Array(vaaArray);
    const hexVaaArray =
      "0x" + vaaArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    setHexVaa(hexVaaArray);
    setVaaArray(vaaArray);
    // console.log(vaaBytes);
    // console.log(vaaArray);
    // console.log(int32VaaArray);
  }

  const onChange = ({ target: { value } }) => {
    setTx(value);
  };

  const executeTransfer = async () => {
    if (message) {
      await completeTransfer();
    } else {
      db.update({ vaa: vaaArray, startedOn: web3NetworkDetails.chainId });
      db.set();
      alert(
        "VAA saved to local storage. Switch to the other chain and click the 'Complete transfer' button on the other chain's dev tools page."
      );
    }
  };

  const completeTransfer = async () => {
    const { signer, contracts, connectedWallet, chainId } = web3NetworkDetails;
    const vaa = db.get("vaa");
    const startedOn = db.get("startedOn");
    if (chainId === startedOn) {
      alert(
        "You cannot complete a transfer on the same chain that you started it on."
      );
      return;
    }
    const vaaBytes = new Int32Array(vaa);
    const { Tesseract, SeedPool } = contracts;
    let gasLimit = 400000;
    if (isSideChain(web3NetworkDetails)) {
      const len = (
        await SeedPool.getDepositsLength(connectedWallet)
      ).toNumber();
      gasLimit += len * 20000;
    }
    setMessage("Completing transfer...");
    try {
      const tx = await Tesseract.connect(signer).completeCrossChainTransfer(
        WORMHOLE_BRIDGE_TYPE,
        vaaBytes,
        { gasLimit }
      );
      setMessage("Waiting for completion...");
      await tx.wait();
      setMessage("Transfer completed!");
    } catch (e) {
      setMessage("Transfer failed with error: " + e.message);
    }
    resetDb();
  };

  function resetDb() {
    db.update({ vaa: null, startedOn: null });
    db.set();
  }

  useEffect(() => {
    if (!message) {
      const vaa = db.get("vaa");
      if (vaa) {
        setMessage(
          "You have a pending transfer. Click the 'Complete transfer' button to complete it."
        );
      }
    }
  }, [message, setMessage, db]);

  return (
    <div style={{ marginTop: "10%" }} className={"rec-tx"}>
      <div style={{ textAlign: "center", fontSize: "x-large" }}>
        DEV TOOLS PAGE
      </div>
      {message ? (
        <div
          style={{ textAlign: "center", fontSize: "large", color: "yellow" }}
        >
          {message}
        </div>
      ) : null}
      <div style={{ padding: "3%" }}>
        <label>
          Paste the Hash of a transaction to get VAA of a Wormhole Transaction:
        </label>
        <input
          style={{ marginBottom: "5%" }}
          id="tx-hash"
          type="text"
          value={tx}
          onChange={onChange}
        />
        <BoxButton onClick={getVaa} buttonText={"Get VAA"} display={"inline"} />
      </div>
      {/*<div style={{ overflow: "scroll" }}>{JSON.stringify(vaaArray)}</div>*/}
      <div className={"centered"}>
        <textarea
          style={{
            width: "80%",
            height: "300px",
            padding: 10,
          }}
          wrap="soft"
          defaultValue={hexVaa}
        />
      </div>
      {hexVaa || message ? (
        <div className={"centered"}>
          <BoxButton
            onClick={executeTransfer}
            buttonText={"Complete cross-chain transfer"}
            display={"inline"}
          />
        </div>
      ) : null}
    </div>
  );
}

export default DevTools;
