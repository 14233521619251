import React, { createContext, useContext, useState, useEffect } from "react";

import { ethers } from "ethers";

import { parseAnd2Decimals, isMobile } from "../utils";
import WalletConnectProvider from "@walletconnect/web3-provider";
import clientApi from "../utils/ClientApi";
import { StoreContext } from "./StoreContext";

function getContracts(config, chainId, web3Provider) {
  let contracts = {};
  let networkNotSupported = false;
  let connectedNetwork = null;
  if (config.supportedId[chainId]) {
    let addresses = config.contracts[chainId];
    connectedNetwork = config.supportedId[chainId].chainName;
    for (let contractName in addresses) {
      try {
        contracts[contractName] = new ethers.Contract(
          addresses[contractName],
          config.abi[contractName],
          web3Provider
        );
      } catch (e) {
        console.error("Error reading contracts", e.message);
      }
    }
  } else {
    networkNotSupported = true;
  }
  return {
    contracts,
    connectedNetwork,
    networkNotSupported,
  };
}

export const Web3Context = createContext();

let web3SetUp;

function Web3ContextProvider({ config, children }) {
  const { store, db } = useContext(StoreContext);
  const { connectedWith } = store;

  const [networkDetails, setNetworkDetails] = useState({
    provider: "",
    signer: "",
    connectedWallet: "",
    chainId: "",
    contracts: "",
    connectedNetwork: "",
    networkNotSupported: "",
    // used for caching by the pool
    db,
  });

  async function setWallet(eth, connectedWith) {
    try {
      const provider = new ethers.providers.Web3Provider(eth);
      const signer = provider.getSigner();
      const chainId = (await provider.getNetwork()).chainId;
      let connectedWallet = await signer.getAddress();
      const { contracts, connectedNetwork, networkNotSupported } = getContracts(
        config,
        chainId,
        provider
      );
      const simulate = localStorage.getItem("simulate");
      if (simulate && ethers.utils.isAddress(simulate)) {
        connectedWallet = simulate;
      }
      const prefix = chainId === 1 || chainId === 56 ? "M_" : "T_";
      setNetworkDetails({
        provider,
        signer,
        connectedWallet,
        chainId,
        contracts,
        connectedNetwork,
        networkNotSupported,
        prefix,
        db,
      });
      localStorage.setItem("wallet", connectedWallet);
      db.update({ connectedWith, connectedWallet, chainId, prefix });
      db.set();
      db.initTransfers();
      clientApi.setDefaultHeaders(connectedWallet, chainId);
    } catch (e) {
      console.error(e);
    }
  }
  async function connect() {
    let eth = null;
    const query = new URLSearchParams(window.location.search);
    let mobileActive = true;
    let mobile = query.get("mobile");
    mobile = mobile === "true" ? true : mobile === "false" ? false : undefined;
    if (typeof mobile === "boolean") {
      db.update({ mobile });
      db.set();
    }
    if (db.get("mobile") === false) {
      mobileActive = false;
    }
    if (isMobile() && mobileActive) {
      eth = new WalletConnectProvider({
        infuraId: "a5d8ae5cf48e49269d71a5cf25289c0d",
        qrcodeModalOptions: {
          mobileLinks: ["metamask"],
        },
      });
      await eth.enable();
      setWallet(eth, "metamask");

      if (await eth.request({ method: "eth_requestAccounts" })) {
        eth.on("accountsChanged", (accounts) => {
          window.reload();
        });
        eth.on("chainChanged", (chainId) => {
          window.reload();
        });
        eth.on("disconnect", (code, reason) => {
          window.reload();
        });
        setWallet(eth, "metamask");
      }
    } else if (typeof window.ethereum !== "undefined") {
      let eth = window.ethereum;
      if (await eth.request({ method: "eth_requestAccounts" })) {
        eth.on("accountsChanged", () => window.location.reload());
        eth.on("chainChanged", () => window.location.reload());
        eth.on("disconnect", () => window.location.reload());
        setWallet(eth, "metamask");
      }
    }
  }

  const isConnected = () => !!networkDetails.connectedWallet;

  useEffect(() => {
    (async () => {
      // we use web3Setup to avoid executing this function again and again
      if (connectedWith === "metamask" && !web3SetUp) {
        web3SetUp = true;
        await connect();
      }
    })();
  });

  return (
    <Web3Context.Provider
      value={{
        web3NetworkDetails: networkDetails,
        web3Connect: connect,
        web3IsConnected: isConnected,
        tokenAddress: () => {
          const addresses = config.contracts[networkDetails.chainId];
          return addresses ? addresses.SyndicateERC20 : "";
        },
        tokenContract: () => networkDetails.contracts.SyndicateERC20,
        tokenBalance: async (
          connectedWallet,
          token = "SyndicateERC20",
          asIs
        ) => {
          try {
            if (asIs) {
              return networkDetails.contracts[token].balanceOf(connectedWallet);
            } else {
              return parseAnd2Decimals(
                await networkDetails.contracts[token].balanceOf(
                  connectedWallet
                ),
                null,
                "JUST-STRING"
              );
            }
          } catch (e) {
            if (asIs) {
              return ethers.BigNumber.from("0");
            } else {
              return "0.00";
            }
          }
        },
        nftBalance: async (connectedWallet, token = "SynCityPasses") => {
          try {
            return (
              await networkDetails.contracts[token].balanceOf(connectedWallet)
            ).toNumber();
          } catch (e) {
            console.error(e);
            return 0;
          }
        },
        isMainnet:
          networkDetails.chainId === 1 || networkDetails.chainId === 56,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
}

export default Web3ContextProvider;
