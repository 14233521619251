import { ethers } from "ethers";
import { tokenTypes } from "../config";
import { DAY, WEEK, YEAR } from "../config/constants";

const BN = (s, zeros = 0) => {
  s = (s || 0).toString();
  if (/\./.test(s)) {
    return ethers.utils.parseEther(s);
  } else {
    if (/[a-fA-F]+/.test(s) && !/^0x/.test(s)) {
      s = "0x" + s;
    }
    return ethers.BigNumber.from(s + "0".repeat(zeros));
  }
};

function BNMulBy(param, num = 1, repeat = 0) {
  const n = param instanceof ethers.BigNumber ? param : BN(param.toString());
  if (repeat) {
    return n.mul(BN(num, repeat));
  }
  return n.mul(num);
}

function fromDepositToTransferPayload(deposit) {
  try {
    let { tokenAmountOrID } = deposit;
    if (!tokenAmountOrID) {
      tokenAmountOrID =
        deposit.tokenType < tokenTypes.SYNR_PASS_STAKE_FOR_BOOST
          ? deposit.stakedAmount
          : deposit.tokenID;
    }
    return BN(deposit.tokenType)
      .add(BNMulBy(deposit.lockedFrom, 100))
      .add(BNMulBy(deposit.lockedUntil, 1, 12))
      .add(BNMulBy(deposit.mainIndex, 1, 22))
      .add(BNMulBy(tokenAmountOrID, 1, 27));
  } catch (e) {
    // return undefined if the deposit is malformed
  }
}

function serializeInput(tokenType, lockupTime, tokenAmountOrID) {
  return BN(tokenType)
    .add(BNMulBy(lockupTime, 100))
    .add(BNMulBy(tokenAmountOrID, 1, 5));
}

function deserializeInputPayload(payload) {
  payload = BN(payload);
  return {
    tokenType: payload.mod(100).toNumber(),
    lockupTime: payload.div(100).mod(1e5).toNumber(),
    amount: payload.div(1e5),
  };
}

function normalize(val, decimals) {
  return val + "0".repeat(decimals);
}

function deserializeTransferPayload(payload) {
  payload = BN(payload);
  return {
    tokenType: payload.mod(100).toNumber(),
    lockedFrom: payload.div(100).mod(1e10).toNumber(),
    lockedUntil: payload.div(1e12).mod(1e10).toNumber(),
    mainIndex: payload.div(normalize(1, 22)).mod(1e5).toNumber(),
    tokenAmountOrID: payload.div(normalize(1, 27)),
  };
}

function timestamp(date = new Date()) {
  if (typeof date === "number") {
    date = new Date(date);
  }
  return parseInt((date.getTime() / 1000).toString());
}

function parseEther(amount) {
  return ethers.utils.parseEther(amount.toString());
}

export {
  fromDepositToTransferPayload,
  serializeInput,
  deserializeInputPayload,
  timestamp,
  deserializeTransferPayload,
  normalize,
  parseEther,
  DAY,
  WEEK,
  YEAR,
  BN,
};
