import { Box } from "@mui/system";

function Icon({ src, alt = "", iconStyle = {} }) {
  return (
    <Box className={"tile-icon"}>
      <img src={src} alt={alt} style={iconStyle} />
    </Box>
  );
}

export default Icon;
