const wormholeConfig = require("./wormholeConfig");
const contracts = require("./mergeDeployed");
const { toHex } = require("../utils/networkUtils");

let isDev;
let isTestnet;
let isStage;
let isProd;
let isJero;

if (typeof window !== "undefined") {
  const { hostname } = window.location;
  isDev = /localhost/.test(hostname);
  isStage = /(staking-stage|jeroyafra).mob.land(|\.local)$/.test(hostname);
  isTestnet = isDev || isStage;
  isProd = /staking.mob.land/.test(hostname);
  isJero = /jeroyafra/.test(hostname);
} else if (!!process) {
  isDev = isTestnet = true;
}

const abi = Object.assign(
  require("./ABIs.json").contracts,
  require("./seedABIs.json").contracts
);

const config = {
  supportedId: {
    1: {
      chainId: toHex(1),
      chainName: "Ethereum",
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: ["https://mainnet.infura.io/v3/"],
      blockExplorerUrls: ["https://etherscan.io"],
    },
    56: {
      chainId: toHex(56),
      chainName: "BNB Chain",
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: ["https://bsc-dataseed.binance.org"],
      blockExplorerUrls: ["https://bscscan.com"],
    },
    80001: isDev
      ? {
          chainId: toHex(80001),
          chainName: "Mumbai Polygon Testnet",
          rpcUrls: ["https://rpc-mumbai.matic.today"],
          blockExplorerUrls: ["https://mumbai-explorer.matic.today"],
          nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18,
          },
        }
      : undefined,
    // 3:
    //   isStage || isDev
    //     ? {
    //         chainId: toHex(3),
    //         chainName: "Ropsten",
    //         nativeCurrency: {
    //           name: "rETH",
    //           symbol: "ETH",
    //           decimals: 18,
    //         },
    //         rpcUrls: ["https://ropsten.infura.io/v3/"],
    //         blockExplorerUrls: ["https://ropsten.etherscan.io"],
    //         isTestnet: true,
    //       }
    //     : undefined,
    44787:
      isStage || isDev
        ? {
            chainId: toHex(44787),
            chainName: "Alfajores",
            nativeCurrency: {
              name: "CELO",
              symbol: "CELO",
              decimals: 18,
            },
            rpcUrls: ["https://alfajores-forno.celo-testnet.org"],
            blockExplorerUrls: [
              "https://alfajores-blockscout.celo-testnet.org",
            ],
            isTestnet: true,
          }
        : undefined,
    // 5: isDev
    //   ? {
    //       chainId: toHex(5),
    //       chainName: "Goerli",
    //       nativeCurrency: {
    //         name: "gETH",
    //         symbol: "ETH",
    //         decimals: 18,
    //       },
    //       rpcUrls: ["https://goerli.infura.io/v3/"],
    //       blockExplorerUrls: ["https://goerli.etherscan.io"],
    //     }
    //   : undefined,
    42:
      isDev || isStage
        ? {
            chainId: toHex(42),
            chainName: "Kovan",
            nativeCurrency: {
              name: "kETH",
              symbol: "ETH",
              decimals: 18,
            },
            rpcUrls: ["https://kovan.infura.io/v3/"],
            blockExplorerUrls: ["https://kovan.etherscan.io"],
            isTestnet: true,
          }
        : undefined,
    // 97: isDev
    //   ? {
    //       chainId: toHex(97),
    //       chainName: "BNB Chain Testnet",
    //       nativeCurrency: {
    //         name: "BNB",
    //         symbol: "BNB",
    //         decimals: 18,
    //       },
    //       rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
    //       blockExplorerUrls: ["https://testnet.bscscan.com"],
    //     }
    //   : undefined,
    1337: isDev
      ? {
          chainId: toHex(1337),
          chainName: "Localhost 8545",
          nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18,
          },
          rpcUrls: ["http://localhost:8545"],
          blockExplorerUrls: [],
        }
      : undefined,
    43113:
      isStage || isDev
        ? {
            chainId: toHex(43113),
            chainName: "Fuji",
            nativeCurrency: {
              name: "AVAX",
              symbol: "AVAX",
              decimals: 18,
            },
            rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
            blockExplorerUrls: ["https://cchain.explorer.avax-test.network"],
            isTestnet: true,
          }
        : undefined,
  },
  contracts,
  abi,
  isTestnet,
  isDev,
  isStage,
  isProd,
  isJero,
  title: "Mobland",
  wormholeConfig,
  toChain: wormholeConfig.toChain,
  tokenTypes: {
    S_SYNR_SWAP: 1,
    SYNR_STAKE: 2,
    SYNR_PASS_STAKE_FOR_BOOST: 3,
    SYNR_PASS_STAKE_FOR_SEEDS: 4,
    BLUEPRINT_STAKE_FOR_BOOST: 5,
    BLUEPRINT_STAKE_FOR_SEEDS: 6,
    SEED_SWAP: 7,
  },
  chainToChain: {
    44787: 43113,
    43113: 44787,
    1: 56,
    56: 1,
  },
  conf: require("./parameters"),
};

module.exports = config;
