import { FormControl, Grid, Slider, Typography } from "@mui/material";
import React from "react";

function LockPeriod({
  value,
  weight,
  onChange,
  amount,
  minLockTime,
  forSeed = false,
}) {
  if (value < minLockTime) {
    value = minLockTime;
  }
  let max = forSeed ? 365 : 52;
  return (
    <Grid item xs={12} justifyContent="center" className={"lessPaddingTop"}>
      <FormControl component="fieldset" sx={{ width: "100%" }}>
        <Grid container>
          <Grid item xs={6} style={{ textAlign: "left" }}>
            <Typography>
              <span className={"light"}>Lock for:</span>{" "}
              <span className={"bold"}>
                {value} {forSeed ? "days" : "weeks"}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">Token Weight: {weight}x</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} className={"synrDialog"}>
            <Slider
              id="locked-period"
              aria-label="locked period"
              valueLabelDisplay="auto"
              size="small"
              marks={[
                { value: minLockTime, label: `${minLockTime}` },
                { value: max, label: max.toString() },
              ]}
              min={minLockTime}
              max={max}
              step={1}
              defaultValue={1}
              value={value}
              onChange={(ev) => onChange(ev, amount)}
              style={{ color: forSeed ? "#af15fd" : "#ffee00" }}
            />
          </Grid>
        </Grid>
      </FormControl>
    </Grid>
  );
}

export default LockPeriod;
