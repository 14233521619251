import { CircularProgress } from "@mui/material";

function SmallCircularProgress() {
  const circularSx = { width: 20, height: 20, color: "#b401ff" };

  return (
    <div className={"centered"}>
      <CircularProgress style={circularSx} />
    </div>
  );
}
export default SmallCircularProgress;
