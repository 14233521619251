import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import SwitchIcon from "../images/SwitchChain.png";
import { isMobile } from "../utils";

const MenuPopupState = (props) => {
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });

  if (isMobile()) {
    return <span />;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          // justifyContent: "right",
          // alignItems: "baseline",
          position: "absolute",
          top: 2,
          right: 20,
          mb: 2,
          paddingRight: "10px",
        }}
      >
        <div className="tile-address" style={{ marginRight: 8 }}>
          {props.title}
        </div>
        <Button
          className="switch-button"
          variant="contained"
          {...bindTrigger(popupState)}
          // style={{
          //   minWidth: "50px !important",
          //   padding: "3px !important"
          // }}
        >
          <img
            alt={"switch"}
            src={SwitchIcon}
            style={{ width: 30, margin: 0 }}
          />
        </Button>
        {props.items.length ? (
          <Menu {...bindMenu(popupState)}>
            {props.items.map((item) => (
              <MenuItem
                key={"mi" + item.label}
                onClick={() => {
                  popupState.close();
                  item.onClick();
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

export default MenuPopupState;
