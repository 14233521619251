import { isMobile } from "../utils";
import mLogo from "../images/LogoOnlyM.png";
import { useState } from "react";
import logo from "../images/Mobland_Title_Stylized1000.png";

function Logo(props) {
  // eslint-disable-next-line
  const [src, setSrc] = useState(getSrc());

  function getSrc() {
    return isMobile() || window.innerWidth < 900 ? mLogo : logo;
  }

  window.addEventListener("resize", () => {
    setSrc(getSrc());
  });

  return (
    <div>
      <img
        className={props.cls}
        src={logo}
        alt={"logo"}
        style={props.style || {}}
      />{" "}
    </div>
  );
}
export default Logo;
