import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React from "react";

const CoreLightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#222000",
    color: "#fec",
    boxShadow: theme.shadows[1],
    border: "1px solid #88800060",
    fontSize: 12,
    padding: 16,
    borderRadius: 12,
  },
}));

export default CoreLightTooltip;
