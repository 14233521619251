import React, { useContext } from "react";
import PageHero from "./PageHero";
import SeedPool from "./seedFarm/SeedPool";

import { StoreContext } from "../contexts/StoreContext";
import Switch from "./Switch";
import { PoolContext } from "../contexts/PoolContext";
import { Web3Context } from "../contexts/Web3Context";
import Description from "./tile/Description";

function Staking() {
  const { web3NetworkDetails } = useContext(Web3Context);
  const { store } = useContext(StoreContext);
  const { section } = store;

  let { pools } = useContext(PoolContext);
  const pool = section === "core" ? pools[0] : pools[1];

  let isSupported = pool.isSupported(web3NetworkDetails);

  const params = {
    seed: {
      pools: [{ id: "seed", label: "SEED", ui: null }],
      title: (
        <span className={"light"}>
          <b>SEED GENERATOR</b>
        </span>
      ),
      description: [
        <span>
          <b style={{ fontSize: "20px" }}>
            Use Any of the MOBLAND Assets Below to Generate SEED & Fuel the
            Cross-Chain #MafiaMetaverse
          </b>
        </span>,
        <span>
          SYNR, sSYNR & SYNR Pass require both ETH & BNB for gas fees;
          Blueprints require BNB
        </span>,
      ],
    },
  };

  const param = params[section];

  return (
    <>
      <PageHero title={param.title} img={param.img} />
      <div>
        <Description description={param.description} />
      </div>

      {isSupported ? (
        <SeedPool />
      ) : (
        <>
          <div className={"centeredError"}>
            <Switch section={section} />
          </div>
        </>
      )}
    </>
  );
}

export default Staking;
