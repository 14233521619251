import React, { useContext, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Switch from "../Switch";
import RewardsDialogue from "./RewardsDialogue";
import { PoolContext } from "../../contexts/PoolContext";
import { Web3Context } from "../../contexts/Web3Context";
import BoxButton from "../tile/BoxButton";

import MessageDialog from "../modal/MessageDialog";

function RewardsButton({
  flowNumber,
  setFlowNumber,
  pendingRewards,
  setPendingRewards,
}) {
  let { web3NetworkDetails } = useContext(Web3Context);

  const { pools } = useContext(PoolContext);
  const pool = pools[0];
  const [poolSet, setPoolSet] = useState(false);
  // const [detailsOpened, setDetailsOpened] = useState(false);
  const [stakingOpened, setStakingOpened] = useState(false);
  const [myFlowNumber, setMyFlowNumber] = useState(0);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [ok, setOk] = useState("");
  const [claimingNow, setClaimingNow] = useState(false);
  // eslint-disable-next-line
  const [claimingDone, setClaimingDone] = useState(false);
  const [approvalNeeded, setApprovalNeeded] = useState(false);

  // const [stakingDone, setStakingDone] = useState(false);
  const [isSupported, setIsSupported] = useState(1);
  const resetMessageDialog = () => {
    setDialogTitle("");
    setMessage("");
    setOk("");
    setError("");
  };

  async function claim(useSSYN) {
    resetMessageDialog();
    setError("");
    setClaimingNow(true);
    try {
      setMessage("Claiming rewards");
      await pool.claim(web3NetworkDetails, useSSYN);
      setClaimingDone(true);
      setMessage("Rewards Claimed");
      setOk("close");
      pool.init(web3NetworkDetails);
      setTimeout(() => setClaimingDone(false), 3000);
    } catch (e) {
      setMessage("");
      setOk("");
      setApprovalNeeded(false);
      setClaimingNow(false);
      setError(e.message);
    }

    setClaimingNow(false);
    await pool.init(web3NetworkDetails);
    setPoolSet(false);
  }

  const makeStakeClickHandler = () => {
    setStakingOpened(true);
  };

  useEffect(() => {
    if (!poolSet || flowNumber !== myFlowNumber) {
      setPoolSet(true);
      if (web3NetworkDetails.connectedNetwork) {
        (async () => {
          // await sleep(parseInt(100 * Math.random()));
          if (await pool.init(web3NetworkDetails)) {
            setIsSupported(2);
            setPendingRewards(await pool.pendingRewards(web3NetworkDetails));
          } else {
            setIsSupported(0);
          }
        })();
      }
      setMyFlowNumber(flowNumber);
    }
    // eslint-disable-next-line
  }, [flowNumber, myFlowNumber, poolSet, pendingRewards]);

  if (!isSupported) {
    if (!poolSet) {
      return <CircularProgress />;
    } else {
      return (
        <>
          <div className={"centeredError"}>
            <Switch section={"core"} />
          </div>
        </>
      );
    }
  } else if (!poolSet || !pools.length) {
    return <CircularProgress />;
  } else {
    const disabled = pendingRewards === -1;
    return (
      <>
        <BoxButton
          onClick={makeStakeClickHandler}
          disabled={disabled}
          buttonText={"Rewards"}
        />
        <RewardsDialogue
          pool={pool}
          opened={stakingOpened}
          setOpened={setStakingOpened}
          claim={claim}
          pendingRewards={pendingRewards}
        />
        <MessageDialog
          message={message}
          error={error}
          ok={ok}
          title={dialogTitle}
          opened={!!(message || error)}
          onClose={resetMessageDialog}
          borderColor={"#ffee00"}
          color={"#ffee00"}
        />
      </>
    );
  }
}

export default RewardsButton;
