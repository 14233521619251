import React, { useContext, useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import Switch from "../Switch";

import { PoolContext } from "../../contexts/PoolContext";
import { Web3Context } from "../../contexts/Web3Context";

import BoxButton from "../tile/BoxButton";
import SeedRewardsDialogue from "./SeedRewardsDialogue";
import MessageDialog from "../modal/MessageDialog";
import Button from "@mui/material/Button";
import { switchTo } from "../../utils/networkUtils";
import { supportedId } from "../../config";
import BasicModal from "../BasicModal";

function SeedRewardsButton() {
  const { web3NetworkDetails } = useContext(Web3Context);

  let { pools } = useContext(PoolContext);
  const pool = pools[1];

  const [isSupported, setIsSupported] = useState(1);
  const [rewardsOpened, setRewardsOpened] = useState(false);
  const [poolSet, setPoolSet] = useState(false);
  const [pendingRewards, setPendingRewards] = useState("0");
  const [untaxedPendingRewards, setUntaxedPendingRewards] = useState("0");
  const [boostedPendingRewards, setBoostedPendingRewards] = useState("0");
  const [claimingNow, setClaimingNow] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [ok, setOk] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [modal, setModal] = useState({
    show: false,
  });
  // eslint-disable-next-line
  const [progress, setProgress] = useState(-1);
  // eslint-disable-next-line
  let [log, setLog] = useState("");

  const readRewards = async () => {
    setPendingRewards(await pool.pendingRewards(web3NetworkDetails));
    const { untaxed, boosted } = await pool.boostedAndUnboostedPendingRewards(
      web3NetworkDetails
    );
    setUntaxedPendingRewards(untaxed);
    setBoostedPendingRewards(boosted);
  };

  const resetMessageDialog = () => {
    setDialogTitle("");
    setMessage("");
    setOk("");
    setError("");
  };

  async function setInitialPool() {
    setPoolSet(true);
    if (web3NetworkDetails.connectedNetwork) {
      if (pool.isSupported(web3NetworkDetails)) {
        await readRewards();
        setIsSupported(2);
      } else {
        setIsSupported(0);
      }
    }
  }
  const makeRewardClickHandler = () => {
    if (!pool.isSideChain(web3NetworkDetails)) {
      const sideChainId = web3NetworkDetails.chainId === 1 ? 56 : 43113;
      setModal({
        show: true,
        title: `Wrong network`,
        body: (
          <span>
            <Button
              onClick={async () => {
                await switchTo(sideChainId);
              }}
            >
              Click to switch to {supportedId[sideChainId].chainName}
            </Button>
          </span>
        ),
        onClose: () => {
          setModal({
            show: false,
          });
        },
      });
    } else {
      setRewardsOpened(true);
    }
  };

  useEffect(() => {
    if (!poolSet) {
      setInitialPool();
    }
  });

  async function claim() {
    setError("");
    setMessage("Claiming rewards");
    setClaimingNow(true);
    setProgress(25);
    try {
      await pool.collectUnlockedRewards(
        web3NetworkDetails,
        setLog,
        setError,
        setProgress
      );
      await readRewards();
      setMessage("Rewards Claimed");
      setOk("close");
    } catch (e) {
      setMessage("");
      setOk("");
      setError(e.message);
    }
    setClaimingNow(false);
    await pool.init(web3NetworkDetails);
    setPoolSet(false);
  }

  if (!isSupported) {
    if (!poolSet) {
      return <CircularProgress />;
    } else {
      return (
        <>
          <div className={"centeredError"}>
            <Switch section={"core"} />
          </div>
        </>
      );
    }
  } else if (!poolSet || !pools.length) {
    return <CircularProgress />;
  } else {
    return (
      <>
        <BoxButton
          onClick={makeRewardClickHandler}
          disabled={claimingNow}
          buttonText={"Rewards"}
        />
        <SeedRewardsDialogue
          pool={pool}
          opened={rewardsOpened}
          setOpened={setRewardsOpened}
          claim={claim}
          pendingRewards={pendingRewards}
          boostedPendingRewards={boostedPendingRewards}
          untaxedPendingRewards={untaxedPendingRewards}
        />
        <MessageDialog
          message={message}
          error={error}
          ok={ok}
          title={dialogTitle}
          opened={!!(message || error)}
          onClose={resetMessageDialog}
          borderColor={"#7020C8"}
          color={"#7020C8"}
        />
        <BasicModal
          show={modal.show}
          title={modal.title}
          body={modal.body}
          onClose={modal.onClose}
        />
      </>
    );
  }
}

export default SeedRewardsButton;
