import { Container } from "@mui/material";
import React from "react";

import { isMobile } from "../utils";

function PageHero({ title, description, img, className, paddingTop }) {
  return (
    <Container
      disableGutters
      component="main"
      sx={{ pt: 8, pb: 6, width: isMobile() ? "96%" : "auto" }}
      style={{ paddingTop: paddingTop || 100 }}
      className={"page-hero"}
    >
      <div className={className || ""}>
        <div align="center" style={{ paddingTop: 12 }}>
          {img ? <img src={img} style={{ width: "20%" }} alt={title} /> : ""}
          <div
            className={"page-hero-title"}
            style={{ marginTop: img ? -30 : "inherit" }}
          >
            {title}
          </div>
        </div>
        <div className={"centered"}>{description}</div>
      </div>
    </Container>
  );
}

export default PageHero;
