import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  RadioGroup,
  CircularProgress,
  DialogActions,
} from "@mui/material";
import TitleBox from "../modal/TitleBox";
import SeedDouble from "../../images/SEED_Double_small.png";
import BoxButton from "../tile/BoxButton";
import { Web3Context } from "../../contexts/Web3Context";
import { tokenTypes } from "../../config";
import { isMobile, addSomeDecimals, BN, localeFormat } from "../../utils";
import Popup from "../modal/Popup";
import BodyBox from "../modal/BodyBox";
import { DAY, parseEther, timestamp } from "../../utils/PayloadUtils";
import { getGenerator } from "../../utils/sidePool";
import { ethers } from "ethers";
import {
  calculateTaxOnRewards,
  calculateUntaxedRewards,
} from "../../utils/sidePoolViews";
import { conf } from "../../config";
import LockPeriod from "./LockPeriod";
const { bPSynrEquivalent, sPSynrEquivalent } = conf;

function calculateLockWeight(days) {
  let factor = 1 / 365;
  return addSomeDecimals(1 + days * factor);
}

function IDList({ pool, setId, tokenType, setLoaded }) {
  const { web3NetworkDetails } = useContext(Web3Context);

  const [ids, setIds] = useState(false);
  const [tempId, setTempId] = useState(false);
  const [tempIds, setTempIds] = useState(false);

  function set(i) {
    setId(i);
    setTempId(i);
  }

  useEffect(() => {
    (async () => {
      if (ids === false) {
        setTempIds(false);
        await setIds(
          await pool.getNFTIds(web3NetworkDetails, true, setTempIds)
        );
      }
    })();
  }, [ids, setIds, pool, web3NetworkDetails]);

  const circularSx = { width: 20, height: 20, color: "#b401ff" };

  if (Array.isArray(ids)) {
    setLoaded(true);
  }

  let key = 0;

  return (
    <>
      {Array.isArray(ids) ? (
        <div style={{ marginBottom: 32 }}>
          {tokenType === 3 || tokenType === 4 ? (
            <div style={{ align: "center", marginBottom: 24 }}>
              Please select the SYNR Pass you wish to stake.
              <br />
              You can click on the ID number to view the SYNR Pass.
            </div>
          ) : (
            <div style={{ align: "center", marginBottom: 24 }}>
              Please select the Blueprint you wish to stake.
              <br />
              You can click on the ID number to view the Blueprint.
            </div>
          )}
          <div className={"over-left-container typed"}>
            <div
              style={{ padding: "0 24px", maxHeight: 120 }}
              className={"left-container"}
            >
              <RadioGroup
                row
                aria-label="gender"
                name="row-radio-buttons-group"
              >
                {ids.map((id) => (
                  <div className={"tokenIdList"} key={"id" + id}>
                    <div className={"underRadio"}>
                      <label className={"radio-form-control"}>
                        <input
                          type={"radio"}
                          checked={tempId === id}
                          onChange={() => set(id)}
                          value={true}
                        />
                        {tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_BOOST ||
                        tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_SEEDS ? (
                          <div className={"overLabel"}>
                            SYNR Pass{" "}
                            <a
                              href={
                                tokenType ===
                                tokenTypes.SYNR_PASS_STAKE_FOR_BOOST
                                  ? "https://opensea.io/assets/ethereum/0x6f24a2a5ecfffb0610f69929e9aa7fcef441897a/" +
                                    id
                                  : "https://blueprints.mob.land/" + id
                              }
                              target={"more-info"}
                              style={{
                                color: "#f0c0ff",
                                textDecoration: "none",
                              }}
                            >
                              #{id}
                            </a>
                          </div>
                        ) : (
                          <div className={"overLabel"}>Blueprint #{id}</div>
                        )}
                      </label>
                    </div>
                  </div>
                ))}
              </RadioGroup>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ padding: "6px 0 24px" }}>
          <div style={{ padding: 4 }}>Loading token IDs</div>
          <div>
            {Array.isArray(tempIds) ? (
              <span>
                {tempIds.map((e) => {
                  return (
                    <span key={"tempIds_" + key++}>
                      <span>#{e}</span>{" "}
                    </span>
                  );
                })}
                ...
              </span>
            ) : (
              "..."
            )}
          </div>
          <CircularProgress style={circularSx} />
        </div>
      )}
    </>
  );
}

function SeedDialogNft({
  pool,
  opened,
  setOpened,
  setOpen,
  stake,
  // eslint-disable-next-line
  mainPoolConf,
  sidePoolConf,
  sidePoolExtraConf,
  tokenType,
}) {
  const initialLockPeriod = 112; //mainPoolConf.minimumLockupTime;
  const [lockPeriod, setLockPeriod] = useState(initialLockPeriod);
  const [lockWeight, setLockWeight] = useState(
    calculateLockWeight(initialLockPeriod)
  );
  const [id, setId] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [scope, setScope] = useState(0);
  // eslint-disable-next-line
  const [stakingType, setStakingType] = useState("locked");
  const [estimatedSeed, setEstimatedSeed] = useState(false);
  const [ready, setReady] = useState(false);

  let title = "";

  const equivalent =
    tokenType >= tokenTypes.BLUEPRINT_STAKE_FOR_BOOST
      ? bPSynrEquivalent
      : sPSynrEquivalent;

  if (
    tokenType !== 0 &&
    sidePoolExtraConf &&
    sidePoolExtraConf.sPSynrEquivalent &&
    lockPeriod === initialLockPeriod
  ) {
  }

  function EstimatedSeed({ amount }) {
    if (!amount) {
      amount = estimatedSeedAmount(
        parseEther(equivalent),
        initialLockPeriod * DAY
      ).toString();
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <div className={"centered bitLarger"}>
            Estimated SEED reward:{" "}
            <span className={"white"}>{localeFormat(amount)}</span>{" "}
          </div>
        </Grid>
      </Grid>
    );
  }

  if (tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_BOOST) {
    title = "STAKING BLUEPRINT AS A MULTIPLIER";
  } else if (tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_SEEDS) {
    title = "STAKING BLUEPRINT AS SYNR EQUIVALENT";
  } else if (tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_SEEDS) {
    title = "STAKING SYNR PASS AS SYNR EQUIVALENT";
  } else if (tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_BOOST) {
    title = "STAKING SYNR PASS AS A MULTIPLIER";
  }
  const closeDialog = () => {
    setOpen(false);
    setOpened(false);
    setEstimatedSeed(0);
    setScope(0);
    setId(false);
    setReady(false);
    setLockWeight(calculateLockWeight(initialLockPeriod));
    setLockPeriod(initialLockPeriod);
  };

  const onLockPeriodChange = (ev) => {
    let value = ev.target.value.toString();
    if (!value) {
      value = "0";
    } else {
      value = value.replace(/^0+([1-9])/, "$1");
    }
    setLockPeriod(value);
    setLockWeight(calculateLockWeight(value));
    setEstimatedSeed(
      estimatedSeedAmount(
        parseEther(equivalent),
        parseInt(value) * DAY
      ).toString()
    );
  };

  function estimatedSeedAmount(amount, lockupTime) {
    amount = BN(amount);
    const generator = getGenerator(
      sidePoolConf,
      sidePoolExtraConf,
      amount,
      tokenType
    );
    const { rewardsFactor } = sidePoolConf;
    const lockedFrom = timestamp();
    const lockedUntil = lockedFrom + lockupTime;
    const deposit = {
      rewardsFactor,
      generator,
      tokenType,
      unlockedAt: 0,
      lockedFrom,
      lockedUntil,
    };
    const untaxed = calculateUntaxedRewards(
      sidePoolConf,
      deposit,
      lockedUntil,
      lockedFrom
    );
    const taxes = calculateTaxOnRewards(sidePoolConf, untaxed);
    return ethers.utils.formatEther(untaxed.sub(taxes).toString());
  }

  const onStakeClick = async () => {
    setScope(0);
    setOpen(false);
    setOpened(false);
    stake(pool, id, lockPeriod, tokenType);
  };

  if (pool && lockPeriod) {
    return (
      <Popup
        onClose={closeDialog}
        show={opened}
        style={{ minWidth: isMobile() ? "90%" : "40%" }}
        // additionalClass={"Seed"}
      >
        <TitleBox id="staking-dialog-title" icon={SeedDouble}>
          {title}
        </TitleBox>
        <BodyBox className={"centered light bit-smaller"}>
          <IDList
            pool={pool}
            setId={setId}
            tokenType={tokenType}
            scope={scope}
            setLoaded={setLoaded}
          />
          {!loaded ? null : tokenType ===
              tokenTypes.SYNR_PASS_STAKE_FOR_BOOST ||
            tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_BOOST ? (
            <div className={"lowerButton"} style={{ paddingTop: "0px" }}>
              <BoxButton
                onClick={() => onStakeClick()}
                buttonText={"STAKE"}
                disabled={!id}
                className={"bottomButtonSeed"}
              />
            </div>
          ) : (
            <>
              <div className={"rounderWarning"}>
                WARNING: When staking assets as SYNR equivalent, you will not be
                able to unstake your NFT prior to the completion of the lockup
                duration.
              </div>
              <LockPeriod
                locked={stakingType === "locked"}
                value={lockPeriod}
                weight={lockWeight}
                onChange={onLockPeriodChange}
                amount={id}
                minLockTime={initialLockPeriod}
                forSeed={true}
              />
              <div className={"smallWarning "} style={{ textAlign: "center" }}>
                Please note: unlocking your stake early will be subject to early
                unlock penalty
              </div>
              <EstimatedSeed amount={estimatedSeed} />
              {isMobile() ? (
                <>
                  <div className="underRadio" style={{ float: "left" }}>
                    <label className={"light bit-smaller"}>
                      <input
                        type={"checkbox"}
                        checked={ready}
                        onChange={() => setReady(!ready)}
                        value={true}
                        style={{
                          filter:
                            "invert(100%) hue-rotate(226deg) brightness(1.0)",
                          marginRight: 8,
                        }}
                      />
                      I understand that I need BOTH ETH and BNB for gas fees.
                      All pending SEED rewards accumulated across all my stakes
                      will be auto-claimed by executing this function.
                    </label>
                  </div>
                  <div className={"centered"}>
                    <BoxButton
                      onClick={() => onStakeClick()}
                      buttonText={"STAKE"}
                      disabled={!id || !ready}
                      className={"bottomButtonSeedRight"}
                    />
                  </div>
                </>
              ) : (
                <DialogActions>
                  <div className="underRadio" style={{ float: "left" }}>
                    <label className={"light bit-smaller"}>
                      <input
                        type={"checkbox"}
                        checked={ready}
                        onChange={() => setReady(!ready)}
                        value={true}
                        style={{
                          filter:
                            "invert(100%) hue-rotate(226deg) brightness(1.0)",
                          marginRight: 8,
                        }}
                      />
                      I understand that I need BOTH ETH and BNB for gas fees.
                      All pending SEED rewards accumulated across all my stakes
                      will be auto-claimed by executing this function.
                    </label>
                  </div>
                  <div
                    className={"centered"}
                    style={{ float: "right", paddingRight: 8 }}
                  >
                    <BoxButton
                      onClick={() => onStakeClick()}
                      buttonText={"STAKE"}
                      disabled={!id || !ready}
                      className={"bottomButtonSeedRight"}
                    />
                  </div>
                </DialogActions>
              )}
            </>
          )}
        </BodyBox>
      </Popup>
    );
  }

  return null;
}

export default SeedDialogNft;
