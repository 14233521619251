import { Container, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import { isMobile } from "../../utils";

import { Web3Context } from "../../contexts/Web3Context";
import { StoreContext } from "../../contexts/StoreContext";
import { PoolContext } from "../../contexts/PoolContext";
import Case from "case";
import TileItem from "../tile/TileItem";
import CoreStakingIcon from "../../images/SYNR_Double_small.png";
import SeedIcon from "../../images/SEED_Forward_small.png";
import VestingIcon from "../../images/Seed_Pile_small.png";

import PageHero from "../PageHero";
import Switch from "../Switch";
import GenerateSeed from "./GenerateSeed";
import BasicModal from "../BasicModal";

import UnderTiles from "../UnderTiles";
import SeedRewardsButton from "./SeedRewardsButton";
import RewardsIcon from "../../images/SEED_Double_small.png";

function SeedDashboard() {
  const { web3NetworkDetails } = useContext(Web3Context);
  const { store } = useContext(StoreContext);
  const { section } = store;

  // eslint-disable-next-line
  const [modal, setModal] = useState({
    show: false,
  });
  const [generateOpened, setGenerateOpened] = useState(false);

  let { pools } = useContext(PoolContext);
  const pool = pools[1];

  let isSupported = pool.isSupported(web3NetworkDetails);

  const title = (
    <span>
      <span className={"bold"}>SEED GENERATOR DASHBOARD</span>{" "}
    </span>
  );

  const styles = {
    mobile: {
      marginLeft: isMobile() ? "20px" : "0px",
    },
  };

  const tiles = [
    {
      title: "Generate SEED",
      icon: SeedIcon,
      description: "Stake/Swap MOBLAND Assets to Generate SEED.",
      button: false,
      route: "/seed/staking",
      buttonText: "Generate SEED",
      useForWidth: true,
      buttonVariant: "outlined",
    },
    {
      title: "Rewards",
      icon: RewardsIcon,
      description: "Claim your pending SEED rewards on the BNB Chain",
      button: true,
      buttonText: "Rewards",
      buttonVariant: "outlined",
      buttonComponent: <SeedRewardsButton />,
    },
    {
      title: "Vesting",
      icon: VestingIcon,
      description: "View and claim your vested SEED rewards and deposits.",
      button: false,
      buttonText: "Vests",
      buttonVariant: "outlined",
      useForWidth: true,
      route: "/seed/vesting",
    },
    {
      title: "Core Pool",
      icon: CoreStakingIcon,
      description: "Prefer to stake in the Core Pool? Hit the button below!",
      button: true,
      buttonText: "Core Pool",
      buttonVariant: "outlined",
      currentSection: section,
      route: "/core/dashboard",
    },
  ];

  return (
    <>
      <PageHero
        title={title}
        description={
          <div
            style={{ width: "70%", margin: "auto" }}
            className={"light white bit-smaller"}
          >
            <p>
              <b style={{ fontSize: "20px" }}>
                The GameFi 2.0 Cross-Chain Engine
              </b>
              <br />
              Powered by Tesseract, a Layer 0 interoperability technology
              developed by Superpower
            </p>
          </div>
        }
      />
      <div className="small-title">
        <p>
          Generate SEED, a token that fuels the chain-agnostic #MafiaMetaverse
        </p>
      </div>
      <Container maxWidth="lg" component="main">
        {isSupported ? (
          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            style={styles.mobile}
          >
            {tiles.map((tile, index) => {
              return (
                <Grid item xs={10} sm={10} md={3} key={"tiles_" + index}>
                  <TileItem
                    title={tile.title}
                    titleUpperCase={true}
                    cls={Case.kebab(tile.title)}
                    icon={tile.icon}
                    alt={tile.title}
                    currentSection={tile.currentSection}
                    iconStyle={{ height: 120 }}
                    description={tile.description}
                    buttonText={tile.buttonText}
                    route={tile.route}
                    buttonComponent={tile.buttonComponent}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <>
            <div className={"centeredError"}>
              <Switch section={section} />
            </div>
          </>
        )}
        <UnderTiles />
      </Container>
      <GenerateSeed
        pool={pool}
        opened={generateOpened}
        setOpened={setGenerateOpened}
      />
      <BasicModal
        show={modal.show}
        title={modal.title}
        body={modal.body}
        onClose={modal.onClose}
      />
    </>
  );
}

export default SeedDashboard;
