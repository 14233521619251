import { ethers } from "ethers";
import { isApproved } from "../../utils/poolUtils";
import { isStage, isDev } from "../../config";
import _ from "lodash";
import Cache from "../Cache";
import { enUsFormat } from "../../utils";

let storedCache;

const CorePool = {
  id: "syn",
  label: "SYNR Core Pool",
  isSupported: (networkDetails) => {
    const { chainId } = networkDetails;
    if (chainId && !storedCache) {
      storedCache = new Cache("corePoolCache_" + chainId, 1);
    }
    if (isDev) {
      return [1, 42, 1337].includes(chainId);
    } else if (isStage) {
      return [1, 42].includes(chainId);
    } else {
      return chainId === 1;
    }
  },
  cache: {
    weight: 200,
    tvl: "0",
    apy: 12,
    rewards: 33.0,
    maxApy: "0",
  },
  state: {
    initialSet: false,
  },
  init: async (networkDetails, force) => {
    if (CorePool.isSupported(networkDetails)) {
      await Promise.all([
        CorePool.tvl(networkDetails),
        CorePool.apy(networkDetails, 10000, 52, true),
      ]);
      await Promise.all([
        CorePool.weight(networkDetails),
        CorePool.liquidity(networkDetails),
        CorePool.getDeposits(networkDetails, true),
        CorePool.apy(networkDetails, 10000, 16),
        CorePool.liquidity(networkDetails),
        CorePool.pendingRewards(networkDetails),
      ]);
      return true;
    } else {
      return false;
    }
  },
  weight: async (networkDetails) => {
    let Weight = storedCache.getValue(networkDetails, "weight");
    if (!Weight) {
      Weight = (
        await networkDetails.contracts.SyndicateCorePool.weight()
      ).toString();
      storedCache.updateValue(networkDetails, "weight", Weight);
    }
    return Weight;
  },
  minLockTime: async (networkDetails) => {
    let lock = storedCache.getValue(networkDetails, "minLockTime");
    if (!lock) {
      lock = (
        await networkDetails.contracts.SyndicateCorePool.minLockTime()
      ).toString();
      storedCache.updateValue(networkDetails, "minLockTime", lock);
    }
    return lock;
  },
  tvl: async (networkDetails) => {
    let TVL = storedCache.getValue(networkDetails, "tvl");
    if (!TVL) {
      TVL = (
        await networkDetails.contracts.SyndicateCorePool.poolTokenReserve()
      ).toString();
      storedCache.updateValue(networkDetails, "tvl", TVL);
    }
    return TVL;
  },
  marketCap: async (networkDetails, price) => {
    let marketCap = storedCache.getValue(networkDetails, "marketCap");
    if (!marketCap) {
      try {
        let contract = networkDetails.contracts["SyndicateERC20"];
        let maxSupply = await contract.totalSupply();
        maxSupply = parseInt(ethers.utils.formatUnits(maxSupply));
        const synrBank =
          networkDetails.chainId === 1
            ? "0xa71692d3dc79b0f5843cdb16b0eafb0b54e4dd0d"
            : "0x34923658675B99B2DB634cB2BC0cA8d25EdEC743";
        let balance = await contract.balanceOf(synrBank);
        balance = parseInt(ethers.utils.formatUnits(balance));
        marketCap = (maxSupply - balance) * price;
        storedCache.updateValue(networkDetails, "marketCap", marketCap);
      } catch (e) {
        marketCap = 0;
      }
    }
    return enUsFormat(marketCap);
  },
  apy: async (networkDetails, amount, lockedTime, setMax, isSyn = true) => {
    if (!parseInt(amount)) {
      amount = 10000;
    }
    amount = ethers.utils.parseEther(amount.toString());
    if (!lockedTime) {
      lockedTime = "16";
    }
    lockedTime = parseInt(lockedTime) * 3600 * 24 * 7;
    const factoryContract = networkDetails.contracts.SyndicatePoolFactory;
    if (!CorePool.cache.synrPerBlock) {
      CorePool.cache.synrPerBlock = await factoryContract.synrPerBlock();
    }
    const poolContract = networkDetails.contracts.SyndicateCorePool;
    CorePool.cache.usersLockingWeight = await poolContract.usersLockingWeight();
    const poolFactor = 2389091;
    const totalYieldOverYear = CorePool.cache.synrPerBlock.mul(poolFactor);

    const depositWeight = await poolContract.getStakeWeight(lockedTime, amount);

    const yieldOnAmount = totalYieldOverYear
      .mul(depositWeight)
      .div(depositWeight.add(CorePool.cache.usersLockingWeight));

    const apy = yieldOnAmount.mul(100).div(amount).toString();

    if (CorePool.cache.maxApy === "0" && setMax) {
      CorePool.cache.maxApy = "" + apy;
    }
    return (CorePool.cache.apy = apy);
  },
  pendingRewards: async (networkDetails) => {
    return (
      await networkDetails.contracts.SyndicateCorePool.pendingYieldRewards(
        networkDetails.connectedWallet
      )
    ).toString();
  },
  getDeposits: async (networkDetails, refresh) => {
    if (
      refresh ||
      (!CorePool.cache.getDepositsStarted && !CorePool.cache.deposits)
    ) {
      CorePool.cache.getDepositsStarted = true;
      const deposits = [];
      let poolContract = networkDetails.contracts.SyndicateCorePool;
      if (!poolContract) {
        return;
      }
      const depositLength = (
        await poolContract.getDepositsLength(networkDetails.connectedWallet)
      ).toNumber();
      for (let i = 0; i < depositLength; i++) {
        const deposit = _.pick(
          await poolContract.getDeposit(networkDetails.connectedWallet, i),
          ["isYield", "lockedFrom", "lockedUntil", "tokenAmount", "weight"]
        );
        deposit.id = i;
        deposits.push(deposit);
      }
      CorePool.cache.deposits = deposits;
    }
    return CorePool.cache.deposits;
  },
  liquidity: async (networkDetails) => {
    let liquid = storedCache.getValue(networkDetails, "liquidity");
    if (!liquid) {
      liquid = (
        await networkDetails.contracts.SyndicateCorePool.balanceOf(
          networkDetails.connectedWallet
        )
      ).toString();
      storedCache.updateValue(networkDetails, "liquidity", liquid);
    }
    return liquid;
  },
  stake: async (
    amount,
    lockedWeeks,
    networkDetails,
    tokenBalance,
    useSsyn,
    setMessage
  ) => {
    if (amount <= 0) {
      throw new Error("Amount cannot be zero");
    }
    amount = ethers.utils.parseEther(amount.toString());
    const poolContract = networkDetails.contracts.SyndicateCorePool;
    if (poolContract) {
      const balance = ethers.utils.parseEther(
        await tokenBalance(networkDetails.connectedWallet)
      );
      if (amount.gt(balance)) {
        throw new Error("Insufficient balance");
      }
      let approved = await isApproved(networkDetails, amount);
      if (!approved) {
        throw new Error("Amount not approved");
      }
      let lockedSeconds = lockedWeeks === 16 ? 3600 : 0;
      if (approved) {
        const lockUntil = lockedWeeks
          ? (await networkDetails.provider.getBlock()).timestamp +
            lockedWeeks * 7 * 24 * 3600 +
            lockedSeconds
          : 0;
        const tx = await poolContract
          .connect(networkDetails.signer)
          .stake(amount, lockUntil, !!useSsyn, {
            gasLimit: 300000,
          });
        setMessage("Waiting for confirmations...");
        await tx.wait();
        storedCache.resetCache(networkDetails);
        return tx;
      }
    }
  },
  unstake: async (networkDetails, depositId, amount, useSSynr) => {
    const poolContract = networkDetails.contracts.SyndicateCorePool;
    if (poolContract) {
      const tx = await poolContract
        .connect(networkDetails.signer)
        .unstake(depositId, amount, useSSynr, {
          gasLimit: 250000,
        });
      await tx.wait();
      storedCache.resetCache(networkDetails);
      return tx;
    }
  },
  claim: async (networkDetails, useSSYN) => {
    const poolContract = networkDetails.contracts.SyndicateCorePool;
    if (poolContract) {
      const tx = await poolContract
        .connect(networkDetails.signer)
        .processRewards(useSSYN);
      await tx.wait();
      storedCache.resetCache(networkDetails);
      return tx;
    }
  },
};

export default CorePool;
