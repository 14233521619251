import { Box } from "@mui/system";
import Button2 from "./Button2";
import React from "react";

function BoxButton({
  onClick = function () {},
  buttonText,
  route,
  link,
  disabled,
  className,
  buttonClassName,
  display = "flex",
}) {
  return buttonText ? (
    <Box
      sx={{
        display,
        justifyContent: "center",
        mb: 2,
      }}
      className={className || ""}
    >
      <Button2
        buttonText={buttonText}
        onClick={onClick}
        route={route}
        link={link}
        disabled={disabled}
        className={buttonClassName}
      />
    </Box>
  ) : null;
}

export default BoxButton;
