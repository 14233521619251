class Cache {
  constructor(name, version) {
    this.name = name;
    this.version = version;
  }

  storedCache(net) {
    const { connectedWallet, db } = net;
    let cache = db.get(this.name);
    if (!cache || cache.version !== this.version) {
      cache = {
        version: this.version,
      };
    }
    if (!cache[connectedWallet]) {
      cache[connectedWallet] = {};
      db.updateOne(this.name, cache);
      db.set();
    }
    return cache[connectedWallet];
  }

  resetCache(net) {
    const { connectedWallet, db } = net;
    let cache = db.get(this.name);
    cache[connectedWallet] = {};
    db.updateOne(this.name, cache);
    db.set();
  }

  updateCache(net, props) {
    const { db } = net;
    db.updateItem(this.name, props);
    db.set();
  }

  getValue(net, key, exp = 3600 * 1000) {
    const cache = this.storedCache(net);
    if (cache[key] && Date.now() - cache[key].gotAt < exp) {
      return cache[key].value;
    }
  }

  updateValue(net, key, value) {
    const cache = this.storedCache(net);
    cache[key] =
      value === null
        ? null
        : {
            value,
            gotAt: Date.now(),
          };
    const props = {};
    props[net.connectedWallet] = cache;
    this.updateCache(net, props);
  }

  cleanValue(net, key) {
    this.updateValue(net, key, null);
  }
}

export default Cache;
