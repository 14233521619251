import { Container, Grid } from "@mui/material";
import React, { useContext, useState, useRef, useEffect } from "react";

import StakingButton from "./StakingButton";
import RewardsButton from "./RewardsButton";
import VestingButton from "./VestingButton";
import Arrow from "../Arrow";
import { parseAnd2Decimals, enUsFormat } from "../../utils";

import { Web3Context } from "../../contexts/Web3Context";
import { StoreContext } from "../../contexts/StoreContext";
import { PoolContext } from "../../contexts/PoolContext";
import UnderTiles from "../UnderTiles";
import TwoColumns from "../TwoColumns";

import SynrIcon from "../../images/SYNR_Forward.png";
import VestingIcon from "../../images/SYNR_Pile.png";
import RewardsIcon from "../../images/SYNR_Double_small.png";
import SeedIcon from "../../images/SEED_Double_small.png";

import PageHero from "../PageHero";
import Switch from "../Switch";
import Case from "case";
import TileItem from "../tile/TileItem";
import SmallItem from "../tile/SmallItem";
// import VolumeChart from "./VolumeChart";
import { getSYNRPrice } from "../../utils/networkUtils";
import SmallCircularProgress from "../SmallCircularProgress";

function CoreDashboard() {
  const { web3NetworkDetails } = useContext(Web3Context);
  const [price, setPrice] = useState(false);
  const [rewards, setRewards] = useState(-1);
  const [totaldeposits, setTotaldeposits] = useState(-1);
  const [lockedRewards, setLockedRewards] = useState(-1);
  const [maxApy, setMaxApy] = useState(0);
  const [balance, setBalance] = useState(-1);
  const [flowNumber, setFlowNumber] = useState(1);
  const { store } = useContext(StoreContext);
  const { section } = store;
  // const [margin, setMargin] = useState([0, 0]);
  // const [windowWidth, setWindowWidth ] = useState(0);
  const ref = useRef(null);

  let { pools } = useContext(PoolContext);
  const pool = pools[0];

  let isSupported = pool.isSupported(web3NetworkDetails);

  const title = (
    <span>
      <span className={"bold"}>CORE POOL</span>{" "}
      <span className={"light"}>Dashboard</span>{" "}
    </span>
  );

  const tiles = [
    {
      title: "Stake SYNR",
      icon: SynrIcon,
      important: true,
      description: [
        "Stake your SYNR tokens to earn rewards in SYNR, sSYNR, or both!",
        <span>
          SYNR Balance:
          <br />
          {balance > -1 ? (
            <span className={"regular"}>{`${enUsFormat(
              parseAnd2Decimals(balance)
            )} SYNR`}</span>
          ) : (
            <SmallCircularProgress />
          )}
        </span>,
      ],
      button: true,
      buttonText: "Generate Seed",
      buttonVariant: "outlined",
      // route: "/core/staking",
      useForWidth: true,
      buttonComponent: (
        <StakingButton
          flowNumber={flowNumber}
          setFlowNumber={setFlowNumber}
          setBalance={setBalance}
        />
      ),
    },
    {
      title: "Rewards",
      icon: RewardsIcon,
      description: [
        "Claim your staking rewards in either SYNR or sSYNR.",
        <span>
          Total Rewards:
          <br />
          {rewards > -1 ? (
            <span className={"regular"}>{`${enUsFormat(
              parseAnd2Decimals(rewards)
            )} SYNR`}</span>
          ) : (
            <SmallCircularProgress />
          )}
        </span>,
      ],
      button: true,
      buttonText: "Reward",
      buttonVariant: "outlined",
      //  route: "/core/rewards",
      buttonComponent: (
        <RewardsButton
          flowNumber={flowNumber}
          setFlowNumber={setFlowNumber}
          pendingRewards={rewards}
          setPendingRewards={setRewards}
        />
      ),
    },
    {
      title: "Vesting",
      icon: VestingIcon,
      description: [
        "View and claim your vested rewards and deposits.",
        <div style={{ paddingTop: 4, paddingBottom: 12 }}>
          <TwoColumns
            style={{
              margin: "0 auto 18px",
              maxWidth: "200px",
            }}
            left={
              <span>
                Deposits:
                <br />
                {totaldeposits > -1 ? (
                  <span className={"regular"}>{totaldeposits}</span>
                ) : (
                  <SmallCircularProgress />
                )}
              </span>
            }
            right={
              <span>
                Rewards:
                <br />
                {lockedRewards > -1 ? (
                  <span className={"regular"}>{lockedRewards}</span>
                ) : (
                  <SmallCircularProgress />
                )}
              </span>
            }
          />
        </div>,
      ],
      button: true,
      buttonText: "Vests",
      buttonVariant: "outlined",
      // route: "/core/vesting",
      buttonComponent: (
        <VestingButton
          flowNumber={flowNumber}
          setFlowNumber={setFlowNumber}
          setTotaldeposits={setTotaldeposits}
          setLockedRewards={setLockedRewards}
        />
      ),
    },
  ];
  const tiles2 = [
    {
      title: "SEED Farm",
      icon: SeedIcon,
      description: [
        "Want to grow SEED instead?",
        "SEED fuels the cross-chain MOBLAND economy and is a key asset to generate revenue for GameFi 2.0 participants.",
        "SEED can be generated by staking either SYNR, SYNR Passes or Blueprints, and also by Swapping sSYNR.",
        "Head to the SEED Farm to start your path to becoming a MOBLAND kingpin!",
      ],
      button: true,
      buttonText: "SEED Farm",
      buttonVariant: "outlined",
      currentSection: section,
      route: "/seed/dashboard",
    },
  ];

  useEffect(() => {
    async function getData() {
      if (web3NetworkDetails.contracts) {
        const priceAndChange = await getSYNRPrice();
        setPrice(priceAndChange);
        setMaxApy(await pool.apy(web3NetworkDetails, 10000, 52, true));
      }
    }
    getData();
    // eslint-disable-next-line
  }, [setPrice, web3NetworkDetails, setMaxApy]);

  const smallItems = [
    {
      title: "SYNR Price",
      description: !!price ? (
        <Arrow value={price} />
      ) : (
        <SmallCircularProgress />
      ),
      cls: "yellow bold",
    },
    {
      title: "Max APY",
      description: !!maxApy ? maxApy + "%" : <SmallCircularProgress />,
      cls: "yellow bold",
    },
    {
      title: "Holders",
      description: "~8,591",
      cls: "yellow bold",
    },
  ];

  return (
    <>
      <PageHero title={title} description="" />

      <Container maxWidth="lg" component="main">
        {isSupported ? (
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} sm={12} md={9}>
              <Grid container spacing={2} alignItems="flex-end">
                {tiles.map((tile, index) => {
                  return (
                    <Grid item xs={12} sm={12} md={4} key={"tiles_" + index}>
                      <TileItem
                        title={tile.title}
                        titleUpperCase={true}
                        cls={Case.kebab(tile.title)}
                        icon={tile.icon}
                        alt={tile.title}
                        iconStyle={{ height: 120 }}
                        description={tile.description}
                        buttonText={tile.buttonText}
                        route={tile.route}
                        myref={tile.useForWidth ? ref : null}
                        buttonComponent={tile.buttonComponent}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={2} alignItems="flex-end">
                    {smallItems.map((tile, index) => {
                      return (
                        <Grid
                          item
                          xs={4}
                          sm={4}
                          md={4}
                          key={"smallItems_" + index}
                        >
                          <SmallItem
                            title={
                              <div>
                                {tile.title}
                                <br />
                                <span className={"yellow"}>
                                  {tile.description}
                                </span>
                              </div>
                            }
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                {/*<Grid item xs={12} sm={12} md>*/}
                {/*  <VolumeChart />*/}
                {/*</Grid>*/}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              {tiles2.map((tile, index) => {
                return (
                  <Grid item xs={12} sm={12} md={12} key={"tiles2_" + index}>
                    <TileItem
                      title={tile.title}
                      titleUpperCase={true}
                      cls={Case.kebab(tile.title)}
                      icon={tile.icon}
                      alt={tile.title}
                      currentSection={tile.currentSection}
                      iconStyle={{ height: 120 }}
                      description={tile.description}
                      buttonText={tile.buttonText}
                      route={tile.route}
                      disable={tile.disabled}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        ) : (
          <>
            <div className={"centeredError"}>
              <Switch section={section} />
            </div>
          </>
        )}
        <UnderTiles justFT={true} />
      </Container>
    </>
  );
}

export default CoreDashboard;
