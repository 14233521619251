import React from "react";
import Popup from "./Popup";
import BodyBox from "./BodyBox";
import { CircularProgress } from "@mui/material";
import ErrorAlert from "../../images/errorAlert.png";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

function MessageDialog({
  opened,
  onClose,
  color,
  message,
  error,
  borderColor,
  ok,
  title,
}) {
  if (error) {
    borderColor = "#CC2B26";
  }

  return (
    <Popup
      onClose={onClose}
      noClose={!!message}
      show={opened}
      style={{ minWidth: "30%", borderColor: borderColor || "inherit" }}
      closeButton={ok}
      additionalClass={"translucid"}
    >
      {error ? (
        <BodyBox className={"errorBox centered"}>
          <div className={"errorIcon"}>
            <img src={ErrorAlert} alt={"error alert"} />
          </div>
          <div className={"errorTextTitle bold"}>
            {title || "An error occurred!"}
          </div>
          <div className={"errorText light"}>{error}</div>
        </BodyBox>
      ) : (
        <BodyBox
          className={"messageBox centered " + (ok ? " noPaddingBottom" : "")}
          sx={{ color: color || "inherit" }}
        >
          {ok ? (
            <div className={"messageOk"}>
              <TaskAltIcon />
            </div>
          ) : (
            <div className={"messageProgress"}>
              <CircularProgress color="inherit" />
            </div>
          )}
          <div className={"messageTextTitle bold"}>{title}</div>
          <div className={"messageText"}>{message}</div>
        </BodyBox>
      )}
    </Popup>
  );
}

export default MessageDialog;
