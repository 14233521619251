import React, { useContext, useEffect, useState } from "react";
import { Grid, DialogActions } from "@mui/material";
import { ethers } from "ethers";
import {
  calculateTaxOnRewards,
  calculateUntaxedRewards,
} from "../../utils/sidePoolViews";
import { Web3Context } from "../../contexts/Web3Context";
import { DAY, BN, timestamp, parseEther } from "../../utils/PayloadUtils";
import { getGenerator } from "../../utils/sidePool";

import synr_Seed from "../../images/SYNR+SEED.png";
import sSynr_Seed from "../../images/sSYNR_Seed.png";

import LockPeriod from "./LockPeriod";
import StakedAmount from "../corePool/StakedAmount";

import {
  isMobile,
  localeFormat,
  sleep,
  addSomeDecimals,
  enUsFormat,
  parseAnd2Decimals,
} from "../../utils";
import { tokenTypes } from "../../config";

import Popup from "../modal/Popup";
import TitleBox from "../modal/TitleBox";
import BodyBox from "../modal/BodyBox";
import BoxButton from "../tile/BoxButton";

import Loading from "../../images/loading-svgrepo-com.svg";

function calculateLockWeight(days) {
  let factor = 1 / 365;
  return addSomeDecimals(1 + days * factor);
}

function SeedDialog({
  pool,
  opened,
  setOpened,
  stake,
  mainPoolConf,
  sidePoolConf,
  sidePoolExtraConf,
  tokenType,
}) {
  const { web3NetworkDetails, tokenBalance } = useContext(Web3Context);
  const initialLockPeriod = mainPoolConf.minimumLockupTime;
  // eslint-disable-next-line
  const [stakingType, setStakingType] = useState("locked");
  const [tokenAmountOrID, setTokenAmountOrID] = useState(0);
  // eslint-disable-next-line
  const [minLockTime, setMinLockTime] = useState(initialLockPeriod);
  const [balance, setBalance] = useState(0);
  const [error, setError] = useState("");
  // eslint-disable-next-line
  const [choseRewards, setChoseRewards] = useState(false);
  const [deposits, setDeposits] = useState("");
  const [ready, setReady] = useState(false);
  const [lockPeriod, setLockPeriod] = useState(initialLockPeriod);
  const [lockWeight, setLockWeight] = useState(
    calculateLockWeight(initialLockPeriod)
  );
  const [estimatedSeed, setEstimatedSeed] = useState(0);

  const [pendingRewards, setPendingRewards] = useState(false);

  useEffect(() => {
    if (!pendingRewards) {
      readRewards();
    }
    // eslint-disable-next-line
  }, [pendingRewards]);

  const readRewards = async () => {
    let rewards = await pool.pendingRewards(web3NetworkDetails);
    setPendingRewards(enUsFormat(parseAnd2Decimals(rewards)));
  };

  const PendingRewards = (
    <div className={"floatRight pendingRewards light"}>
      Pending Rewards
      <br />
      <b>
        {pendingRewards || (
          <img
            alt={"Loading..."}
            src={Loading}
            style={{ width: 14, height: 14, filter: "invert(1)" }}
          />
        )}
      </b>{" "}
      SEED
    </div>
  );

  const closeDialog = () => {
    setOpened(false);
    setReady(false);
    setEstimatedSeed(0);
    setBalance(0);
    setLockWeight(calculateLockWeight(initialLockPeriod));
    setLockPeriod(initialLockPeriod);
    setTokenAmountOrID(0);
  };

  const onStakeClick = async (ev) => {
    if (!error) {
      setOpened(false);
      stake(pool, tokenAmountOrID, lockPeriod, tokenType);
    }
  };

  const onStakedAmountChange = (ev) => {
    let value = ev.target.value
      .replace(/^0+([1-9])/, "$1")
      .replace(/(\w+)\..+/, "$1");
    if (!value) {
      value = "0";
    }
    setTokenAmountOrID(value);
    if (isNaN(parseFloat(value))) {
      setError("Invalid amount");
    } else if (parseFloat(value) > parseFloat(balance.replace(/,/g, ""))) {
      setError("Insufficient balance");
    } else if (parseFloat(value) < 0) {
      setError("Amount cannot be negative");
    } else {
      setError("");
      setEstimatedSeed(
        estimatedSeedAmount(
          parseEther(value),
          parseInt(lockPeriod) * DAY
        ).toString()
      );
    }
  };

  function estimatedSeedAmount(amount, lockupTime) {
    amount = BN(amount);
    const generator = getGenerator(
      sidePoolConf,
      sidePoolExtraConf,
      amount,
      tokenType
    );
    if (tokenType === tokenTypes.S_SYNR_SWAP) {
      return ethers.utils.formatEther(generator.toString());
    } else {
      const { rewardsFactor } = sidePoolConf;
      const lockedFrom = timestamp();
      const lockedUntil = lockedFrom + lockupTime;
      const deposit = {
        rewardsFactor,
        generator,
        tokenType,
        unlockedAt: 0,
        lockedFrom,
        lockedUntil,
      };
      const untaxed = calculateUntaxedRewards(
        sidePoolConf,
        deposit,
        lockedUntil,
        lockedFrom
      );
      const taxes = calculateTaxOnRewards(sidePoolConf, untaxed);
      return ethers.utils.formatEther(untaxed.sub(taxes).toString());
    }
  }

  const onMaxStakeClick = async (percentage) => {
    const fullBalance = await tokenBalance(
      web3NetworkDetails.connectedWallet,
      tokenType === tokenTypes.SYNR_STAKE
        ? "SyndicateERC20"
        : "SyntheticSyndicateERC20",
      true
    );
    const balance = ethers.utils
      .formatEther(fullBalance.mul(percentage).div(100).toString())
      .replace(/(\w+)\..+/, "$1");
    setTokenAmountOrID(balance);
    setEstimatedSeed(
      estimatedSeedAmount(
        parseEther(balance),
        parseInt(lockPeriod) * DAY
      ).toString()
    );
  };

  const onLockPeriodChange = (ev, amount, locked) => {
    let weeks = ev.target.value;
    setLockPeriod(weeks);
    setLockWeight(calculateLockWeight(weeks));
    setEstimatedSeed(
      estimatedSeedAmount(
        parseEther(tokenAmountOrID),
        parseInt(weeks) * DAY
      ).toString()
    );
  };

  useEffect(() => {
    (async () => {
      setDeposits(await pool.getDeposits(web3NetworkDetails));
      setBalance(
        await tokenBalance(
          web3NetworkDetails.connectedWallet,
          tokenType === tokenTypes.SYNR_STAKE
            ? "SyndicateERC20"
            : "SyntheticSyndicateERC20"
        )
      );
      while (!pool || !deposits) {
        await sleep(1000);
      }
      if (deposits.length) {
        setChoseRewards(true);
      }
    })();
  });

  function EstimatedSeed({ amount }) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <div className={"centered bitLarger"}>
            Estimated SEED reward:{" "}
            <span className={"white"}>
              {amount ? localeFormat(amount) : "--"}
            </span>{" "}
          </div>
        </Grid>
      </Grid>
    );
  }

  if (pool) {
    return (
      <Popup
        onClose={closeDialog}
        show={opened}
        style={{ minWidth: isMobile() ? "90%" : "50%" }}
        // additionalClass={"Seed"}
      >
        {/*<Dialog*/}

        {/*  aria-labelledby="staking-dialog-title"*/}
        {/*  aria-describedby="staking-dialog-description"*/}
        {/*  PaperProps={{*/}
        {/*    style: {*/}
        {/*      backgroundColor: "",*/}
        {/*      opacity: 0.9,*/}
        {/*      boxShadow: "none",*/}
        {/*    },*/}
        {/*  }}*/}
        {/*>*/}
        {tokenType === tokenTypes.SYNR_STAKE ? (
          <TitleBox id="staking-dialog-title" icon={synr_Seed}>
            {PendingRewards}
            STAKING SYNR FOR SEED
          </TitleBox>
        ) : (
          <TitleBox id="staking-dialog-title" icon={sSynr_Seed}>
            {PendingRewards}
            SWAPPING sSYNR FOR SEED
          </TitleBox>
        )}
        <BodyBox>
          <StakedAmount
            balance={balance}
            amount={tokenAmountOrID}
            onChange={onStakedAmountChange}
            onBtnClick={onMaxStakeClick}
            forSeed={true}
            tokenType={tokenType}
          />
          {tokenType === tokenTypes.SYNR_STAKE ? (
            <>
              <LockPeriod
                locked={stakingType === "locked"}
                value={lockPeriod}
                weight={lockWeight}
                onChange={onLockPeriodChange}
                amount={tokenAmountOrID}
                minLockTime={minLockTime}
                forSeed={true}
              />
              <div className={"smallWarning "} style={{ textAlign: "center" }}>
                Please note: unlocking your stake early will be subject to early
                unlock penalty
              </div>
              <EstimatedSeed amount={estimatedSeed} />
            </>
          ) : (
            <>
              <EstimatedSeed amount={estimatedSeed} />
              <div
                className={"smallWarningSwap "}
                // style={{ paddingLeft: "15%" }}
              >
                Claim your SEED after the {sidePoolConf.coolDownDays} Day
                Germination Period or be subject to a penalty
              </div>
            </>
          )}
          {isMobile() ? (
            <>
              <div className="underRadio">
                <div className="underRadio" style={{ float: "left" }}>
                  <label className={"light bit-smaller"}>
                    <input
                      type={"checkbox"}
                      checked={ready}
                      onChange={() => setReady(!ready)}
                      value={true}
                      style={{
                        filter:
                          "invert(100%) hue-rotate(226deg) brightness(1.0)",
                        marginRight: 8,
                      }}
                    />
                    I understand that I need BOTH ETH and BNB for gas fees. All
                    pending SEED rewards accumulated across all my stakes will
                    be auto-claimed by executing this function.
                  </label>
                </div>
              </div>
              <div className={"centered"}>
                {tokenType === tokenTypes.SYNR_STAKE ? (
                  <BoxButton
                    onClick={onStakeClick}
                    buttonText={"STAKE"}
                    disabled={!parseFloat(tokenAmountOrID) || !ready}
                    className={"bottomButtonSeedRight"}
                  />
                ) : (
                  <BoxButton
                    onClick={onStakeClick}
                    buttonText={"SWAP"}
                    disabled={!parseFloat(tokenAmountOrID) || !ready}
                    className={"bottomButtonSeedRight"}
                  />
                )}
              </div>
            </>
          ) : (
            <DialogActions>
              <div className="underRadio">
                <div className="underRadio" style={{ float: "left" }}>
                  <label className={"light bit-smaller"}>
                    <input
                      type={"checkbox"}
                      checked={ready}
                      onChange={() => setReady(!ready)}
                      value={true}
                      style={{
                        filter:
                          "invert(100%) hue-rotate(226deg) brightness(1.0)",
                        marginRight: 8,
                      }}
                    />
                    I understand that I need BOTH ETH and BNB for gas fees. All
                    pending SEED rewards accumulated across all my stakes will
                    be auto-claimed by executing this function.
                  </label>
                </div>
              </div>
              <div
                className={"centered"}
                style={{ float: "right", paddingRight: 8 }}
              >
                <BoxButton
                  onClick={onStakeClick}
                  buttonText={
                    tokenType === tokenTypes.SYNR_STAKE ? "STAKE" : "SWAP"
                  }
                  disabled={
                    isNaN(parseFloat(tokenAmountOrID)) ||
                    parseFloat(tokenAmountOrID) < 1 ||
                    !ready
                  }
                  className={"bottomButtonSeedRight"}
                />
              </div>
            </DialogActions>
          )}
        </BodyBox>
      </Popup>
    );
  }

  return null;
}

export default SeedDialog;
