import React, { useState } from "react";
import { Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import SynrDouble from "../../images/SYNR_Double_small.png";

import { isMobile, parseAnd2Decimals, enUsFormat } from "../../utils";
import { title } from "../../config";
import CoreLightTooltip from "./CoreLightTooltip";

import Popup from "../modal/Popup";
import TitleBox from "../modal/TitleBox";
import BodyBox from "../modal/BodyBox";
import BoxButton from "../tile/BoxButton";

function RewardsDialogue({ pool, opened, setOpened, claim, pendingRewards }) {
  // eslint-disable-next-line
  const [error, setError] = useState("");
  const [useSsyn, setUseSsyn] = useState(true);

  const closeDialog = () => {
    setOpened(false);
  };

  const onClaimClick = async (ev) => {
    if (!error) {
      setOpened(false);
      claim(useSsyn);
    }
  };

  if (pool) {
    return (
      <Popup
        onClose={closeDialog}
        show={opened}
        style={{ minWidth: isMobile() ? "90%" : "50%" }}
      >
        <TitleBox id="staking-dialog-title" icon={SynrDouble}>
          Core Pool Rewards
        </TitleBox>
        <BodyBox>
          <div className={"overRadio"}>
            <Typography align="left">
              <span className={"light"}>Rewards:</span>{" "}
              <span className={"bold"}>
                {enUsFormat(parseAnd2Decimals(pendingRewards))} SYNR
              </span>
            </Typography>

            <div className="underRadio">
              <label htmlFor={"useSsyn"} className={"radio-form-control"}>
                <input
                  type={"radio"}
                  id={"useSsyn"}
                  checked={useSsyn}
                  onChange={() => setUseSsyn(true)}
                  value={true}
                />

                <div className={"overLabel"}>
                  Receive rewards in sSYNR - immediate access
                  <CoreLightTooltip
                    title={`sSYNR rewards are sent immediately to your wallet. You can use sSYNR to buy assets in the ${title} marketplace.`}
                    className={"smallTooltip"}
                  >
                    <HelpOutlineIcon
                      style={{
                        position: "relative",
                        top: 6,
                        left: 4,
                      }}
                    />
                  </CoreLightTooltip>
                </div>
              </label>
              <label htmlFor={"useSyn"} className={"radio-form-control"}>
                <input
                  type={"radio"}
                  id={"useSyn"}
                  checked={!useSsyn}
                  onChange={() => setUseSsyn(false)}
                  value={true}
                />
                <div className={"overLabel"}>
                  Receive rewards in SYNR — locked for 12 months
                  <CoreLightTooltip
                    title="SYNR rewards are subject to a 12 month vesting period. Though, while they are vesting, they compound your rewards."
                    className={"smallTooltip"}
                  >
                    <HelpOutlineIcon
                      style={{
                        position: "relative",
                        top: 6,
                        left: 4,
                      }}
                    />
                  </CoreLightTooltip>
                </div>
              </label>
            </div>
          </div>
          <div className={"lowerButton"}>
            <BoxButton
              onClick={onClaimClick}
              buttonText={"Claim"}
              disabled={!parseFloat(pendingRewards) || !!error}
              className={"bottomButton"}
            />
          </div>
        </BodyBox>
      </Popup>
    );
  }

  return null;
}

export default RewardsDialogue;
