import React, { useState, useContext, useEffect } from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Button,
  TableHead,
  Grid,
  Container,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import getRevertReason from "eth-revert-reason";
import { isMobile } from "../../utils";
import BoxButton from "../tile/BoxButton";
import {
  fromDepositToTransferPayload,
  deserializeTransferPayload,
} from "../../utils/PayloadUtils";
import { decodeMetamaskError } from "../../utils/networkUtils";
import { PoolContext } from "../../contexts/PoolContext";
import { StoreContext } from "../../contexts/StoreContext";
import { Web3Context } from "../../contexts/Web3Context";
import { supportedId, tokenTypes } from "../../config";
import CoreStakingIcon from "../../images/SYNR_Double_small.png";
import JumboProgress from "./JumboProgress";
import SeedVestingDialogue from "./SeedVestingDialogue";
import UnderTiles from "../UnderTiles";

import {
  TRANSFER_INITIATED,
  VAA_BYTES_GOT,
  COMPLETE_TRANSFER_MINED,
  SKIP,
  REC_TX_FAILED,
  LOST_TRANSACTION,
} from "../../config/constants";
import BasicModal from "../BasicModal";
import { switchTo } from "../../utils/networkUtils";
import UnstakingDialog from "./UnstakingDialog";
import _ from "lodash";
import LostTransactionError from "./LostTransactionError";
import CompleteTransactionError from "./CompleteTransactionError";
import SmallCircularProgress from "../SmallCircularProgress";
import TileItem from "../tile/TileItem";
import Case from "case";
import sSynr_Seed from "../../images/sSYNR_Seed.png";
import SYNRPass_Seed from "../../images/SYNRPass_SEED.png";
import Blueprint_SEED from "../../images/Blueprint_SEED.png";
import TwoColumns from "../TwoColumns";
import MessageDialog from "../modal/MessageDialog";
import { useBeforeunload } from "react-beforeunload";

let isRefreshing = false;

function SeedDeposits() {
  let { web3NetworkDetails } = useContext(Web3Context);
  const { db } = useContext(StoreContext);

  const { pools } = useContext(PoolContext);
  const pool = pools[1];
  const [unstakingOpened, setUnstakingOpened] = useState(false);
  const [unstakeOpened, setUnstakeOpened] = useState(false);
  const [ok, setOk] = useState("");
  const [poolSet, setPoolSet] = useState(false);
  const [userDeposits, setUserDeposits] = useState(false);
  const [listingDeposits, setListingDeposits] = useState(false);
  const [mainDeposits, setMainDeposits] = useState(false);
  const [progress, setProgress] = useState(-1);
  let [log, setLog] = useState("");
  let [action, setAction] = useState("");
  let [totalSteps, setTotalSteps] = useState(5);
  const [recoverStatus, setRecoverStatus] = useState(0);
  const [unstakeDeposit, setUnstakeDeposit] = useState({});
  const [tx, setTx] = useState("");
  const [dialogOpened, setDialogOpened] = useState(false);
  const [showTxInput, setShowTxInput] = useState();
  const [txError, setTxError] = useState("");
  const [recoverTx, setRecoverTx] = useState("");
  const [loadingTx, setLoadingTx] = useState(false);
  const [sidePoolConf, setSidePoolConf] = useState({});
  const [sidePoolExtraConf, setSidePoolExtraConf] = useState({});
  const [possibleMissed, setPossibleMissed] = useState(0);
  const [finishingUnstake, setFinishingUnstake] = useState(false);

  const [swapList, setSwapList] = useState(false);
  const [activeDeposits, setActiveDeposits] = useState(0);
  const [modal, setModal] = useState({
    show: false,
  });

  let [error, setError] = useState("");

  const [synrTableBalance, setSynrTableBalance] = useState(-1);
  const [sSynrTableBalance, setSSynrTableBalance] = useState(-1);
  const [passTableBalance, setPassTableBalance] = useState([-1, -1]);
  const [blueprintTableBalance, setBlueprintTableBalance] = useState([-1, -1]);

  const [alertSeen, setAlertSeen] = useState(
    !(db.lastSeen && Date.now() - db.lastSeen > 3600000)
  );

  const resetMessageDialog = () => {
    setError("");
  };

  useBeforeunload((event) => {
    if (finishingUnstake) {
      event.preventDefault();
    }
  });

  async function setInitialPool() {
    if (web3NetworkDetails.networkNotSupported) {
      setPoolSet(true);
    } else if (web3NetworkDetails.connectedNetwork) {
      const { sidePoolConf, sidePoolExtraConf } = await pool.getConf(
        web3NetworkDetails
      );
      setSidePoolExtraConf(sidePoolExtraConf);
      setSidePoolConf(sidePoolConf);
      setRecoverStatus(1);
      setPoolSet(true);
      await readDeposits();
    }
  }

  function refreshDeposits() {
    if (!isRefreshing) {
      isRefreshing = true;
      setSynrTableBalance(-1);
      setSSynrTableBalance(-1);
      setPassTableBalance([-1, -1]);
      setBlueprintTableBalance([-1, -1]);
      readDeposits(true);
    }
  }

  async function readDeposits(force) {
    if (pool.isMainChain(web3NetworkDetails)) {
      setUserDeposits(
        await pool.getDeposits(web3NetworkDetails, true, true, force)
      );
      setMainDeposits(
        await pool.getDeposits(web3NetworkDetails, undefined, false, force)
      );
    } else {
      setUserDeposits(
        await pool.getDeposits(web3NetworkDetails, undefined, true, force)
      );
      setMainDeposits(
        await pool.getDeposits(web3NetworkDetails, true, false, force)
      );
    }
    await pool.stakedBalances(web3NetworkDetails);
    isRefreshing = false;
  }

  function findDepositsToList() {
    let mainDepositsLocked = {};
    for (let d of mainDeposits) {
      if (d.unlockedAt === 0) {
        mainDepositsLocked[d.mainIndex] = true;
      }
    }
    let deposits = [];
    let possibleMissed = 0;
    // let depositList = false;
    setSwapList(false);
    if (userDeposits) {
      for (let i = 0; i < userDeposits.length; i++) {
        let d = userDeposits[i];
        let t = d.tokenType;
        let crossChain =
          t > tokenTypes.S_SYNR_SWAP &&
          t < tokenTypes.BLUEPRINT_STAKE_FOR_BOOST;
        let incomplete =
          crossChain && d.unlockedAt !== 0 && mainDepositsLocked[d.mainIndex];
        if (d.unlockedAt === 0 || incomplete) {
          deposits.push(d);
          if (t === tokenTypes.S_SYNR_SWAP) {
            setSwapList(true);
          }
          // else {
          //   depositList = true;
          // }
          if (pool.isSideChain(web3NetworkDetails) && incomplete) {
            possibleMissed++;
          }
        }
      }
    }

    setListingDeposits(deposits);
    let synrStakes = 0;
    let sSynrSwaps = 0;
    let stakedPassesBoost = 0;
    let stakedPassesEQ = 0;
    let stakedBlueprintsBoost = 0;
    let stakedBlueprintsEQ = 0;
    for (let i in deposits) {
      if (deposits[i].tokenType === tokenTypes.S_SYNR_SWAP) {
        sSynrSwaps += 1;
      } else if (deposits[i].tokenType === tokenTypes.SYNR_STAKE) {
        synrStakes += 1;
      } else if (
        deposits[i].tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_BOOST
      ) {
        stakedPassesBoost += 1;
      } else if (
        deposits[i].tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_SEEDS
      ) {
        stakedPassesEQ += 1;
      } else if (
        deposits[i].tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_BOOST
      ) {
        stakedBlueprintsBoost += 1;
      } else if (
        deposits[i].tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_SEEDS
      ) {
        stakedBlueprintsEQ += 1;
      }
    }
    setSynrTableBalance(synrStakes);
    setSSynrTableBalance(sSynrSwaps);
    setPassTableBalance([stakedPassesBoost, stakedPassesEQ]);
    setBlueprintTableBalance([stakedBlueprintsBoost, stakedBlueprintsEQ]);

    setPossibleMissed(possibleMissed);
    setSwapList(swapList);
    // setDepositList(depositList);
  }

  const getTransfer = async (deposit, sendTx) => {
    let transfer = db.getTransfer("side", web3NetworkDetails.isMainnet);
    if (deposit) {
      let p0, p1;
      if (transfer) {
        p0 = fromDepositToTransferPayload(transfer.deposit);
        p1 = fromDepositToTransferPayload(deposit);
      }
      if (
        sendTx ||
        !transfer ||
        transfer.status === SKIP ||
        transfer.status === COMPLETE_TRANSFER_MINED ||
        !p0.eq(p1)
      ) {
        transfer = db.newTransfer(
          "side",
          deposit,
          sendTx,
          undefined,
          web3NetworkDetails.isMainnet
        );
      }
    }
    return transfer;
  };

  const onChange = ({ target: { value } }) => {
    setTxError("");
    setTx(value);
  };

  const setTransaction = async () => {
    if (tx && /^0x[a-z0-9]{64}/.test(tx)) {
      setLoadingTx(true);
      let receipt;
      try {
        receipt = await web3NetworkDetails.provider.getTransactionReceipt(tx);
      } catch (e) {}
      if (!receipt) {
        return setTxError("Transaction not found");
      }
      const { to, logs } = receipt;
      const { Tesseract, SeedPool } = pool.contracts(web3NetworkDetails);
      if (to === Tesseract.address) {
        let payload;
        for (let log of logs) {
          // this may change if Wormhole changes its internal events, but
          // for now it should work well
          if (log.data.length > 388) {
            payload = log.data.substring(388 - 64, 388);
            break;
          }
        }
        if (!payload) {
          setLoadingTx(false);
          return setTxError("Transfer not found");
        }

        setRecoverTx(tx);

        let deposit;
        const metaDeposit = deserializeTransferPayload("0x" + payload);
        const [depositIndex, exist] = await SeedPool.getDepositIndexByMainIndex(
          web3NetworkDetails.connectedWallet,
          metaDeposit.mainIndex
        );
        if (!exist) {
          setLoadingTx(false);
          return setTxError("Deposit not found");
        }
        deposit = await SeedPool.getDepositByIndex(
          web3NetworkDetails.connectedWallet,
          depositIndex
        );

        if (deposit) {
          deposit = _.pick(deposit, [
            "tokenType",
            "lockedFrom",
            "lockedUntil",
            "stakedAmount",
            "tokenID",
            "unlockedAt",
            "mainIndex",
            "tokenAmount",
            "lastRewardsAt",
            "rewardsFactor",
            "extra1",
            "extra2",
            "extra3",
            "extra4",
          ]);
        }
        if (!deposit) {
          setTxError("Deposit not found");
        } else if (deposit.unlockedAt === 0) {
          setTxError("Deposit not unlocked, yet");
        } else {
          const mainDeposits2 = await pool.getDeposits(
            web3NetworkDetails,
            true,
            true,
            true
          );
          for (let mainDeposit of mainDeposits2) {
            if (mainDeposit.mainIndex === deposit.mainIndex) {
              if (mainDeposit.unlockedAt !== 0) {
                return setTxError("This transfer has been completed");
              }
              break;
            }
          }
          setUnstakeDeposit(deposit);
          setLoadingTx(false);
          unstake(deposit, undefined, tx);
        }
      } else {
        setLoadingTx(false);
        setTxError("Not a bridge transaction");
      }
    } else {
      setLoadingTx(false);
      setTxError("Invalid transaction");
    }
  };

  const updateTransfer = (props) => {
    db.updateTransfer("side", props, web3NetworkDetails.isMainnet);
    return db.getTransfer("side", web3NetworkDetails.isMainnet);
  };

  const unstake = async (deposit, useUnstakeDeposit, tx) => {
    if (!deposit && useUnstakeDeposit) {
      deposit = unstakeDeposit;
    }
    const { tokenType } = deposit;
    const isSideChain = pool.isSideChain(web3NetworkDetails);
    if (!isSideChain) {
      setError("Wrong network");
      return;
    }
    setError("");
    if (
      tokenType !== tokenTypes.S_SYNR_SWAP &&
      tokenType < tokenTypes.BLUEPRINT_STAKE_FOR_BOOST
    ) {
      setTotalSteps(5);
    } else {
      setDialogOpened(true);
    }

    let isFt = tokenType < tokenTypes.SYNR_PASS_STAKE_FOR_BOOST;
    let isSSynr = tokenType === tokenTypes.S_SYNR_SWAP;
    let isBp = tokenType >= tokenTypes.BLUEPRINT_STAKE_FOR_BOOST;

    setAction(
      isSSynr
        ? `Unlocking your SEED`
        : isFt
        ? `Unlocking your SEED to unstake your SYNR`
        : `Unstaking your ${isBp ? "Blueprint" : "SYNR Pass"} #${
            deposit.tokenID
          }`
    );
    try {
      if (
        deposit.tokenType === tokenTypes.S_SYNR_SWAP ||
        deposit.tokenType >= tokenTypes.BLUEPRINT_STAKE_FOR_BOOST
      ) {
        setLog("Please, confirm the transaction");
        await pool.unstakeBlueprint(
          // and SEED from sSYNR
          web3NetworkDetails,
          deposit,
          setLog,
          setProgress,
          setError
        );
        setOk("Close");
        setLog(
          deposit.tokenType >= tokenTypes.BLUEPRINT_STAKE_FOR_BOOST
            ? "Your Blueprint has been unstaked."
            : "Your SEED has been unlocked!"
        );
      } else {
        setProgress(12);
        const transfer = await getTransfer(deposit, tx);
        setTx("");
        setRecoverTx("");
        setShowTxInput(false);

        // return;

        if (deposit.unlockedAt !== 0) {
          if (transfer.vaaBytes) {
            setLog(
              "Assets unstaked on BNB Chain. Please, switch to Ethereum to complete the process."
            );
            return await pool.switch(web3NetworkDetails);
          } else if (!transfer.sendTx) {
            return setError(
              "We cannot find the transaction in your local storage. Did you clear the browser cache? Please, contact Mobland support."
            );
          }
        }
        const good = await pool.startCrossChainTransfer(
          web3NetworkDetails,
          undefined,
          undefined,
          undefined,
          setLog,
          updateTransfer,
          transfer,
          setProgress,
          setError
        );
        if (good) {
          setLog(
            "Assets unstaked on BNB Chain. Please, switch to Ethereum to complete the process."
          );
          await pool.switch(web3NetworkDetails);
        } else if (good === false) {
          setLog("");
        } else {
          setLog("");
          if (!error) {
            setError("Something wrong happened, please, refresh the page");
          }
        }
      }
    } catch (e) {
      console.error(e);
      setLog("");
      if (!error) {
        setError(decodeMetamaskError(e));
      }
    }
  };

  function resetAll() {
    setProgress(-1);
    setLog("");
    setError("");
  }

  const completeUnstake = async () => {
    setError("");
    if (pool.isMainChain(web3NetworkDetails)) {
      try {
        // setStakingNow(true);
        const transfer = await getTransfer();
        if (
          transfer.status < VAA_BYTES_GOT ||
          transfer.status === COMPLETE_TRANSFER_MINED
        ) {
          return setError("There is not transfer to be completed");
        }
        setLog(
          `Unstaking your SYNR${
            transfer.deposit.tokenType === tokenTypes.SYNR_STAKE ? "" : " Pass"
          }...`
        );
        setFinishingUnstake(true);
        const good = await pool.completeCrossChainTransfer(
          web3NetworkDetails,
          transfer,
          setLog,
          updateTransfer,
          setProgress,
          setError
        );
        setFinishingUnstake(false);
        if (good) {
          setLog(
            <span>
              Congratulations, your asset has been unstaked.
              <br />
              <BoxButton
                buttonText={"close"}
                onClick={resetAll}
                className={"popupClose"}
              />
            </span>
          );
          setUserDeposits(await pool.getDeposits(web3NetworkDetails));
          setMainDeposits(await pool.getDeposits(web3NetworkDetails, true));
        } else if (good === false) {
          setLog("");
        } else {
          setLog("");
          if (!error) {
            setError("Something wrong happened, please, refresh the page");
          }
        }
      } catch (e) {
        console.error(e);
        setLog("");
        setError(e.message);
      }
    }
  };

  useEffect(() => {
    if (progress > -1 && progress < 100) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [progress]);

  function bigAlert() {
    setAlertSeen(true);
    db.updateOne("lastSeen", Date.now());
    db.set();
  }

  function unstakeButton(tokenType, disable = false) {
    //Deposits
    return (
      <BoxButton
        onClick={makeUnstakeClickHandler(tokenType)}
        buttonText={"View"}
        disabled={disable || (progress > -1 && progress < 100)}
      />
    );
  }

  const makeUnstakeClickHandler = (tokenType) => {
    return (ev) => {
      const { chainId } = web3NetworkDetails;
      if (!pool.isSideChain(web3NetworkDetails)) {
        const sideChainId = chainId === 1 ? 56 : 43113;
        setModal({
          show: true,
          title: `Wrong network`,
          body: (
            <span>
              <Button
                onClick={async () => {
                  await switchTo(sideChainId);
                }}
              >
                Click to switch to {supportedId[sideChainId].chainName}
              </Button>
            </span>
          ),
          onClose: () => {
            setModal({
              show: false,
            });
          },
        });
      } else {
        setActiveDeposits(tokenType);
        setUnstakingOpened(true);
      }
    };
  };

  function unstakeClick(deposit) {
    setUnstakeDeposit(deposit);
    const { chainId } = web3NetworkDetails;
    if (!pool.isSideChain(web3NetworkDetails)) {
      const sideChainId = chainId === 1 ? 56 : 43113;
      setModal({
        show: true,
        title: `Wrong network`,
        body: (
          <span>
            <Button
              onClick={async () => {
                await switchTo(sideChainId);
              }}
            >
              Click to switch to {supportedId[sideChainId].chainName}
            </Button>
          </span>
        ),
        onClose: () => {
          setModal({
            show: false,
          });
        },
      });
    } else if (
      !recoverTx &&
      deposit.tokenType &&
      deposit.tokenType < tokenTypes.BLUEPRINT_STAKE_FOR_BOOST &&
      !alertSeen
    ) {
      bigAlert();
    } else if (
      !recoverTx &&
      deposit.tokenType <= tokenTypes.SYNR_STAKE &&
      deposit.lockedUntil * 1000 > Date.now()
    ) {
    } else {
      unstake(deposit);
    }
  }

  const recoverProcess = async (pool) => {
    // const { connectedWallet } = web3NetworkDetails;
    setRecoverStatus(2);
    setLog("Looking to recover interrupted transfers");
    const transfer = await getTransfer();
    if (!transfer) {
      setLog("");
      return;
    }
    const { status, vaaBytes } = transfer;
    if (status === TRANSFER_INITIATED || status === COMPLETE_TRANSFER_MINED) {
      setLog("");
    } else if (typeof status === "number") {
      if (status < VAA_BYTES_GOT) {
        if (pool.isMainChain(web3NetworkDetails)) {
          setLog("Please, switch to the side chain");
          await pool.switch(web3NetworkDetails);
        } else {
          // TODO Something
          setLog("");
          await unstake(transfer.deposit);
        }
      } else {
        setProgress(63);
        if (vaaBytes && pool.isSideChain(web3NetworkDetails)) {
          setLog("Please, switch to the Main chain");
          await pool.switch(web3NetworkDetails);
        } else {
          const sx = _.clone(styles.buttonStyle);
          sx.margin = "27px 0 20px";
          if (status === REC_TX_FAILED) {
            if (transfer.recTx) {
              setProgress(-1);
              let reason;
              try {
                reason = await getRevertReason(
                  transfer.recTx,
                  undefined,
                  undefined,
                  web3NetworkDetails.provider
                );
              } catch (e) {
                reason = "Unknown";
              }
              setError(
                <CompleteTransactionError
                  chainId={web3NetworkDetails.chainId}
                  transfer={transfer}
                  updateTransfer={updateTransfer}
                  reason={reason}
                />
              );
            } else {
              setLog("");
              setProgress(-1);
            }
          } else if (status === LOST_TRANSACTION) {
            setProgress(-1);
            setError(
              <LostTransactionError
                chainId={web3NetworkDetails.chainId}
                transfer={transfer}
                updateTransfer={updateTransfer}
              />
            );
          } else if (status === SKIP) {
            setProgress(-1);
            setLog("");
          } else {
            setLog(
              <span>
                You are ready to complete the transfer.
                <br />
                <Button
                  style={sx}
                  // variant="outlined"
                  onClick={completeUnstake}
                >
                  <div style={{ fontSize: "1.2rem" }}>
                    Complete the unstaking
                  </div>
                </Button>
              </span>
            );
          }
        }
      }
    }
  };

  let styles = {
    buttonStyle: {
      backgroundColor: "#ffffff20",
      fontSize: "95%",
      borderColor: "#660",
      borderWidth: 1,
      color:
        pool.storedCache &&
        pool.storedCache.getValue(web3NetworkDetails, "pendingRewards") === "0"
          ? "#990"
          : "#FFEE00",
    },
    body: { backgroundColor: "transparent" },
  };

  function cancelTransaction() {
    setShowTxInput(false);
    setLoadingTx(false);
  }

  useEffect(() => {
    if (userDeposits && mainDeposits) {
      //
      findDepositsToList();
    }
    // eslint-disable-next-line
  }, [userDeposits, mainDeposits]);

  useEffect(() => {
    if (!poolSet) {
      setInitialPool();
    }
    if (recoverStatus === 1) {
      recoverProcess(pool);
    }
  });

  let deposits = listingDeposits;

  if (deposits.length === 0) {
    styles.buttonStyle.color = "#FFEE00";
    return (
      <TableContainer
        component={Paper}
        sx={{
          minWidth: isMobile() ? 0 : 480,
          width: isMobile() ? "96%" : "70%",
          margin: "auto",
        }}
        style={styles.body}
      >
        <Table aria-label="staking pools">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                <h1>No deposits here :-(</h1>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="center">
                Click on Staking to stake your assets
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    const convertInDays = (ts) => {
      const sec = secondsToUnlock(ts);
      if (sec) {
        if (sec / (24 * 3600) > 1) {
          return Math.round(sec / (24 * 3600)) + " Days";
        } else {
          return Math.round(sec / 3600) + " Hours";
        }
      } else {
        return "none";
      }
    };

    const secondsToUnlock = (ts) => {
      const now = parseInt(Date.now() / 1000);
      if (ts > now) {
        return ts - now;
      } else {
        return 0;
      }
    };

    const hints = [
      "Select and confirm the assets you wish to unstake, and for how long. Please note, you will need both ETH and BNB for gas fees.",
      "Please confirm to initialize Tesseract’s cross-chain capabilities. This step can take up to 10 minutes. You can hang around or come back later.",
      "When prompted to do so, switch networks to Eth Chain.",
      "Tesseract is active. Please confirm to send your assets across the metaverse. DO NOT REFRESH!",
      "Congratulations Synner, you have successfully unstaked your assets!",
    ];

    const tiles = [
      {
        title: "LOCKED SYNR",
        important: true,
        icon: CoreStakingIcon,
        description: [
          <span>
            SYNR Stakes
            <br />
            {synrTableBalance > -1 ? (
              <span className={"regular"}>{synrTableBalance}</span>
            ) : (
              <SmallCircularProgress />
            )}
          </span>,
        ],
        buttonVariant: "outlined",
        buttonComponent: unstakeButton(
          tokenTypes.SYNR_STAKE,
          synrTableBalance <= 0
        ),
      },
      {
        title: "sSYNR SWAPS",
        icon: sSynr_Seed,
        description: [
          <span>
            sSYNR Swaps
            <br />
            {sSynrTableBalance > -1 ? (
              <span className={"regular"}>{sSynrTableBalance}</span>
            ) : (
              <SmallCircularProgress />
            )}
          </span>,
        ],
        buttonVariant: "outlined",
        buttonComponent: unstakeButton(
          tokenTypes.S_SYNR_SWAP,
          sSynrTableBalance <= 0
        ),
      },
      {
        title: "SYNR PASSES",
        icon: SYNRPass_Seed,
        description: [
          <div style={{ paddingTop: 4, paddingBottom: 12 }}>
            <TwoColumns
              style={{
                margin: "0 auto 18px",
                maxWidth: "200px",
              }}
              left={
                <span>
                  Multiplier
                  <br />
                  {passTableBalance[0] > -1 ? (
                    <span className={"regular"}>{passTableBalance[0]}</span>
                  ) : (
                    <SmallCircularProgress />
                  )}
                </span>
              }
              right={
                <span>
                  Equivalent
                  <br />
                  {passTableBalance[1] > -1 ? (
                    <span className={"regular"}>{passTableBalance[1]}</span>
                  ) : (
                    <SmallCircularProgress />
                  )}
                </span>
              }
            />
          </div>,
        ],
        buttonVariant: "outlined",
        buttonComponent: unstakeButton(
          tokenTypes.SYNR_PASS_STAKE_FOR_BOOST,
          passTableBalance[0] <= 0 && passTableBalance[1] <= 0
        ),
      },
      {
        title: "BLUEPRINTS",
        icon: Blueprint_SEED,
        description: [
          <div style={{ paddingTop: 4, paddingBottom: 12 }}>
            <TwoColumns
              style={{
                margin: "0 auto 18px",
                maxWidth: "200px",
              }}
              left={
                <span>
                  Multiplier
                  <br />
                  {blueprintTableBalance[0] > -1 ? (
                    <span className={"regular"}>
                      {blueprintTableBalance[0]}
                    </span>
                  ) : (
                    <SmallCircularProgress />
                  )}
                </span>
              }
              right={
                <span>
                  Equivalent
                  <br />
                  {blueprintTableBalance[1] > -1 ? (
                    <span className={"regular"}>
                      {blueprintTableBalance[1]}
                    </span>
                  ) : (
                    <SmallCircularProgress />
                  )}
                </span>
              }
            />
          </div>,
        ],
        buttonVariant: "outlined",
        buttonComponent: unstakeButton(
          tokenTypes.BLUEPRINT_STAKE_FOR_BOOST,
          blueprintTableBalance[0] <= 0 && blueprintTableBalance[1] <= 0
        ),
      },
    ];

    function resetError() {
      setTxError("");
      setLoadingTx(false);
      setTx("");
      setOk("");
      setDialogOpened(false);
      setProgress(-1);
    }

    return (
      <>
        <JumboProgress
          error={error}
          log={log}
          progress={progress}
          totalSteps={totalSteps}
          action={action}
          hints={hints}
          onClose={resetAll}
          doNotOpenIf={dialogOpened}
        />
        <div
          className={"dont-see centered even-smaller light"}
          style={{ paddingBottom: 12 }}
        >
          Don't you see all your deposits?{" "}
          <span
            className={"command"}
            style={{
              color: "#b86Df7",
              whiteSpace: isMobile() ? "nowrap" : null,
            }}
            onClick={() => refreshDeposits()}
          >
            Refresh the balances
          </span>
        </div>
        <Container maxWidth="lg" component="main">
          <Grid container spacing={2} alignItems="flex-end">
            {tiles.map((tile, index) => {
              return (
                <Grid item xs={12} sm={12} md={3} key={"tiles_" + index}>
                  <TileItem
                    title={tile.title}
                    titleUpperCase={false}
                    cls={Case.kebab(tile.title)}
                    icon={tile.icon}
                    alt={tile.title}
                    iconStyle={{ height: 120 }}
                    description={tile.description}
                    description2={tile.balance}
                    buttonText={tile.buttonText}
                    route={tile.route}
                    myref={null}
                    buttonComponent={tile.buttonComponent}
                    balance={tile.balance}
                    disable={tile.disable}
                  />
                </Grid>
              );
            })}
          </Grid>
          {!log && !error ? (
            <>
              {possibleMissed > 0 ? (
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={12} sm={12} md={12}>
                    {" "}
                    <TileItem>
                      {showTxInput ? (
                        loadingTx ? (
                          <div className={"centered"}>
                            <SmallCircularProgress />
                            <div>Verifying the transaction...</div>
                          </div>
                        ) : (
                          <div className={"rec-tx"}>
                            <div style={{ padding: "12px 0" }}>
                              <label>
                                Paste the hash of the transaction that started
                                the process:
                              </label>
                              <input
                                id="tx-hash"
                                type="text"
                                value={tx}
                                onChange={onChange}
                              />
                            </div>
                            <div>
                              <BoxButton
                                // TODO cancel is do be defined
                                onClick={cancelTransaction}
                                buttonText={"Cancel"}
                                display={"inline"}
                                buttonClassName={"secondaryButton"}
                              />
                              <BoxButton
                                onClick={setTransaction}
                                buttonText={"Validate"}
                                display={"inline"}
                              />
                            </div>
                          </div>
                        )
                      ) : (
                        <span style={{ color: "#aaa" }}>
                          {possibleMissed} cross-chain transaction
                          {possibleMissed > 1 ? "s" : ""} seem
                          {possibleMissed === 1 ? "s" : ""} to be incomplete.{" "}
                          <span
                            className={"command"}
                            style={{
                              color: "#b86Df7",
                              whiteSpace: isMobile() ? "nowrap" : null,
                            }}
                            onClick={() => setShowTxInput(true)}
                          >
                            Try to recover an interrupted transfer
                          </span>
                        </span>
                      )}
                    </TileItem>
                  </Grid>
                </Grid>
              ) : null}
            </>
          ) : null}
          <UnderTiles />
        </Container>
        <SeedVestingDialogue
          pool={pool}
          opened={unstakingOpened}
          setOpened={setUnstakingOpened}
          deposits={listingDeposits}
          setDeposits={setListingDeposits}
          setError={setError}
          resetMessageDialog={resetMessageDialog}
          error={error}
          unstakeClick={unstakeClick}
          setUnstakeOpened={setUnstakeOpened}
          activeDeposits={activeDeposits}
          setActiveDeposits={setActiveDeposits}
          sidePoolConf={sidePoolConf}
          sidePoolExtraConf={sidePoolExtraConf}
        />
        <UnstakingDialog
          pool={pool}
          deposit={unstakeDeposit}
          opened={unstakeOpened}
          setOpened={setUnstakeOpened}
          unstake={unstake}
          convertInDays={convertInDays}
        />
        <BasicModal
          show={modal.show}
          title={modal.title}
          body={modal.body}
          onClose={modal.onClose}
        />
        <MessageDialog
          message={log}
          ok={ok}
          title={action}
          error={error || txError}
          opened={!!txError || dialogOpened}
          onClose={resetError}
          borderColor={"#7b16db"}
          color={"#9b36fb"}
        />
      </>
    );
  }
}

export default SeedDeposits;
