import * as React from "react";
import { Box } from "@mui/material";

export default function TitleBox(props) {
  return (
    <Box className={"titleBox"} style={props.style || {}}>
      {!!props.icon ? (
        <div className={"titleBoxIcon"}>
          <img src={props.icon} alt={props.altIcon || "icon"} />
        </div>
      ) : null}
      <div className={"titleBoxTitle "} style={props.style || {}}>
        {props.children}
      </div>
    </Box>
  );
}
