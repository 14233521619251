import React, { useContext, useState, useEffect } from "react";
import { Container, CircularProgress, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import getRevertReason from "eth-revert-reason";
import { supportedId, tokenTypes } from "../../config";
import { ethers } from "ethers";
import Switch from "../Switch";
import { switchTo } from "../../utils/networkUtils";
import BasicModal from "../BasicModal";
import CoreStakingIcon from "../../images/SYNR_Double_small.png";
import sSynrIcon from "../../images/sSYNR_Double.png";
import BlueprintIcon from "../../images/Blueprint.png";
import GenesisIcon from "../../images/GenesisPass.png";
import BoxButton from "../tile/BoxButton";
import { enUsFormat, isMobile, parseAnd2Decimals } from "../../utils";

import UnderTiles from "../UnderTiles";
import {
  TRANSFER_INITIATED,
  VAA_BYTES_GOT,
  COMPLETE_TRANSFER_MINED,
  LOST_TRANSACTION,
  SKIP,
  REC_TX_FAILED,
} from "../../config/constants";
import _ from "lodash";
import SeedDialog from "./SeedDialog";
import EquivalentOrBoostDialog from "./EquivalentOrBoostDialog";
import { PoolContext } from "../../contexts/PoolContext";
import { Web3Context } from "../../contexts/Web3Context";
import { approveAmount, isApproved } from "../../utils/poolUtils";
import {
  deserializeInputPayload,
  DAY,
  deserializeTransferPayload,
} from "../../utils/PayloadUtils";
import { StoreContext } from "../../contexts/StoreContext";
import LostTransactionError from "./LostTransactionError";
import CompleteTransactionError from "./CompleteTransactionError";
import JumboProgress from "./JumboProgress";

import Case from "case";
import TileItem from "../tile/TileItem";
import SmallCircularProgress from "../SmallCircularProgress";
import MessageDialog from "../modal/MessageDialog";
import { useBeforeunload } from "react-beforeunload";

function BN(integer = "0") {
  return ethers.BigNumber.from(integer.toString());
}

let isRefreshing = false;

function SeedPool() {
  const { web3NetworkDetails } = useContext(Web3Context);
  const [loadingTx, setLoadingTx] = useState(false);

  const { db } = useContext(StoreContext);

  let { pools } = useContext(PoolContext);
  pools = [pools[1]];
  const pool = pools[0];
  // eslint-disable-next-line
  const [tx, setTx] = useState("");
  const [showTxInput, setShowTxInput] = useState();
  const [txError, setTxError] = useState("");

  const [recoveredPayload, setRecoveredPayload] = useState("");
  const [dialogOpened, setDialogOpened] = useState(false);
  const [recoverTx, setRecoverTx] = useState("");
  const [stakeInfo, setStakeInfo] = useState(undefined);
  const [poolSet, setPoolSet] = useState(false);
  const [stakingOpened, setStakingOpened] = useState(false);
  const [stakingNftOpened, setStakingNftOpened] = useState(false);
  const [recoverStatus, setRecoverStatus] = useState(0);
  const [mainPoolConf, setMainPoolConf] = useState({});
  const [sidePoolConf, setSidePoolConf] = useState({});
  const [sidePoolExtraConf, setSidePoolExtraConf] = useState({});
  const [activeTokenType, setActiveTokenType] = useState(0);
  const [isSupported, setIsSupported] = useState(1);
  const [progress, setProgress] = useState(-1);
  const [ok, setOk] = useState("");

  const [modal, setModal] = useState({
    show: false,
  });
  const [alertSeen, setAlertSeen] = useState(
    !(db.lastSeen && Date.now() - db.lastSeen > 3600000)
  );

  let [log, setLog0] = useState("");

  const setLog = (l) => {
    setLog0(l);
  };

  let [action, setAction] = useState("");
  let [error, setError] = useState("");
  let [totalSteps, setTotalSteps] = useState(5);

  const [readingBalance, setReadingBalance] = useState(0);

  const [availableSSynr, setAvailableSSynr] = useState(-1);
  const [availableSynr, setAvailableSynr] = useState(-1);
  const [availableSynrPass, setAvailableSynrPass] = useState(-1);
  const [availableBlueprint, setAvailableBlueprint] = useState(-1);

  const [sideDeposits, setSideDeposits] = useState(false);
  const [mainDeposits, setMainDeposits] = useState(false);
  const [finishingStake, setFinishingStake] = useState(false);

  useBeforeunload((event) => {
    if (finishingStake) {
      event.preventDefault();
    }
  });

  // eslint-disable-next-line
  const [stakedBalances, setStakedBalances] = useState({
    synr: BN(),
    sSynr: BN(),
    pass: 0,
    bp: 0,
    unset: true,
  });

  function resetBalances() {
    setAvailableSSynr(-1);
    setAvailableSynr(-1);
    setAvailableSynrPass(-1);
    setAvailableBlueprint(-1);
  }

  function refreshBalances() {
    if (!isRefreshing) {
      isRefreshing = true;
      readBalance(true);
    }
  }

  const readBalance = async (force) => {
    if (force) {
      resetBalances();
    }
    if (!readingBalance || force) {
      setReadingBalance(1);
      setStakedBalances(
        await pool.stakedBalances(web3NetworkDetails, undefined, force)
      );
      const balances = await pool.tokenBalances(web3NetworkDetails, force);
      setAvailableSynr(balances.synrAmount);
      setAvailableSSynr(balances.sSynrAmount);
      setAvailableSynrPass(balances.synrPassAmount.toNumber());
      const sideBalances = await pool.sideTokenBalances(
        web3NetworkDetails,
        force
      );
      setAvailableBlueprint(sideBalances.blueprintAmount.toNumber());
      isRefreshing = false;
    }
  };

  async function readDeposits(force) {
    if (pool.isMainChain(web3NetworkDetails)) {
      setSideDeposits(
        await pool.getDeposits(web3NetworkDetails, true, true, force)
      );
      setMainDeposits(
        await pool.getDeposits(web3NetworkDetails, undefined, false, force)
      );
    } else {
      setSideDeposits(
        await pool.getDeposits(web3NetworkDetails, undefined, true, force)
      );
      setMainDeposits(
        await pool.getDeposits(web3NetworkDetails, true, false, force)
      );
    }
  }

  const styles = {
    buttonStyle: {
      backgroundColor: "#ffffff20",
      fontSize: "95%",
      borderColor: "#660",
      borderWidth: 1,
      width: "60%",
    },
    body: { backgroundColor: "transparent" },
  };

  function resetAll() {
    setProgress(-1);
    setLog("");
    setError("");
    setDialogOpened(false);
  }

  function bigAlert() {
    setAlertSeen(true);
    db.updateOne("lastSeen", Date.now());
    db.set();
    setModal({
      show: true,
      title: `Your attention, please`,
      body: (
        <span>
          A cross-chain transfer requires transitional data to be saved in your
          browser's local storage. After the process starts, DO NOT clean your
          browser cache. Cleaning the cache deletes your local storage, causing
          the interruption of the process and the potential impossibility of
          completing it later.
          <br />
          <br />
          Please, click again the unstake button to proceed.
        </span>
      ),
      onClose: () => {
        setModal({
          show: false,
        });
      },
    });
  }

  const getTransfer = (create) => {
    let transfer = db.getTransfer("main", web3NetworkDetails.isMainnet);
    if (
      create &&
      (recoverTx ||
        !transfer ||
        transfer.status === SKIP ||
        transfer.status === COMPLETE_TRANSFER_MINED)
    ) {
      transfer = db.newTransfer(
        "main",
        undefined,
        recoverTx,
        recoveredPayload,
        web3NetworkDetails.isMainnet
      );
    }
    return transfer;
  };

  const updateTransfer = (props) => {
    db.updateTransfer("main", props, web3NetworkDetails.isMainnet);
    return db.getTransfer("main", web3NetworkDetails.isMainnet);
  };

  function updateAction(tokenType, tokenAmountOrID, amount) {
    let isFt = tokenType < tokenTypes.SYNR_PASS_STAKE_FOR_BOOST;
    let isSSynr = tokenType === tokenTypes.S_SYNR_SWAP;
    let forBoost =
      tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_BOOST ||
      tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_BOOST;
    let isBp = tokenType >= tokenTypes.BLUEPRINT_STAKE_FOR_BOOST;

    amount =
      amount ||
      (isFt
        ? ethers.utils.parseEther(tokenAmountOrID.toString())
        : tokenAmountOrID);

    setAction(
      isSSynr
        ? `Swapping ${
            tokenAmountOrID ? enUsFormat(tokenAmountOrID) : ""
          } sSYNR to generate SEED. This process can take up to 10 minutes`
        : isFt
        ? `Staking ${
            tokenAmountOrID ? enUsFormat(tokenAmountOrID) : ""
          } SYNR to generate SEED. This process can take up to 10 minutes`
        : isBp
        ? `Staking Blueprint #${amount} to ${
            forBoost ? "boost rewards" : "get SEED"
          }`
        : `Staking SYNR Pass #${amount} to ${
            forBoost ? "boost rewards" : "get SEED"
          }. This process can take up to 10 minutes`
    );
    return amount;
  }

  const onChange = ({ target: { value } }) => {
    setTxError("");
    setTx(value);
  };

  function resetError() {
    setTxError("");
    setDialogOpened(false);
    setLoadingTx(false);
    setTx("");
    setOk("");
    setProgress(-1);
  }

  const setTransaction = async () => {
    if (tx && /^0x[a-z0-9]{64}/.test(tx)) {
      setLoadingTx(true);
      let receipt;
      try {
        receipt = await web3NetworkDetails.provider.getTransactionReceipt(tx);
        setLoadingTx(false);
      } catch (e) {
        return setTxError("Invalid transaction");
      }
      const { to, logs } = receipt;
      const { Tesseract } = pool.contracts(web3NetworkDetails);
      if (to === Tesseract.address) {
        let payload;
        for (let log of logs) {
          // this may change if Wormhole changes its internal events, but
          // for now it should work well
          if (log.data.length > 388) {
            payload = log.data.substring(388 - 64, 388);
            break;
          }
        }
        if (!payload) {
          setLoadingTx(false);
          return setTxError("Transfer not found");
        }
        const info = deserializeTransferPayload("0x" + payload);
        for (let i in sideDeposits) {
          if (sideDeposits[i].mainIndex === info.mainIndex) {
            return setTxError("This transfer has already been completed");
          }
        }
        setStakeInfo(info);
        setRecoverTx(tx);
        setRecoveredPayload(payload.replace(/^0+/, ""));
        setLoadingTx(false);
      } else {
        setTxError("Not a bridge transaction");
      }
    } else {
      setTxError("Invalid transaction");
    }
  };

  const stake = async (
    pool,
    tokenAmountOrID,
    lockPeriod,
    tokenType = tokenTypes.SYNR_STAKE
  ) => {
    setLog("Verifying your assets");
    const isMainChain = pool.isMainChain(web3NetworkDetails);
    setTotalSteps(isMainChain ? 5 : 0);
    if (
      (isMainChain && tokenType > tokenTypes.SYNR_PASS_STAKE_FOR_SEEDS) ||
      (pool.isSideChain(web3NetworkDetails) &&
        tokenType < tokenTypes.BLUEPRINT_STAKE_FOR_BOOST)
    ) {
      return setError("Wrong network");
    }
    setError("");
    const poolName = isMainChain ? "MainPool" : "SeedPool";

    let amount = updateAction(tokenType, tokenAmountOrID);
    try {
      let approved;
      if (!recoverTx) {
        approved = await isApproved(
          web3NetworkDetails,
          amount,
          poolName,
          tokenType
        );
        if (isMainChain) {
          setProgress(0);
        } else {
          setDialogOpened(true);
        }
        if (!approved) {
          // setApprovalNeeded(true);
          if (tokenType === tokenTypes.S_SYNR_SWAP) {
            setLog("To swap your asset you must give permission to handle it.");
          } else {
            setLog(
              "To stake your asset you must give permission to handle it."
            );
          }
          if (
            !(await approveAmount(
              web3NetworkDetails,
              amount,
              poolName,
              tokenType,
              setLog
            ))
          ) {
            throw new Error("Error approving the spend");
          }
        }
      }
      if (isMainChain) {
        setProgress(12);
        let transfer = getTransfer(true);
        setTx("");
        setRecoverTx("");
        setRecoveredPayload("");
        setStakeInfo(undefined);
        setShowTxInput(false);
        setRecoveredPayload("");

        if (transfer && transfer.status >= VAA_BYTES_GOT) {
          setError("A previous deposit is on going");
          // TODO, manage the situation to allow the user to continue or cancel the process
          return;
        }
        if (
          (tokenType === tokenTypes.SYNR_STAKE ||
            tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_SEEDS) &&
          lockPeriod < mainPoolConf.minimumLockupTime
        ) {
          lockPeriod = mainPoolConf.minimumLockupTime;
        }
        const good = await pool.startCrossChainTransfer(
          web3NetworkDetails,
          tokenType,
          lockPeriod,
          amount,
          setLog,
          updateTransfer,
          transfer,
          setProgress,
          setError
        );
        if (good) {
          setLog(
            "Assets staked on Ethereum. Please, switch to BNB Chain to complete the process."
          );
          await pool.switch(web3NetworkDetails);
        } else if (good === false) {
          setLog("");
        } else {
          setLog("");
          if (!error) {
            setError("Something wrong happened, please, refresh the page");
          }
        }
      } else {
        await pool.stakeBlueprint(
          web3NetworkDetails,
          tokenType,
          lockPeriod,
          amount,
          setLog,
          setProgress,
          setError
        );
        setOk("Close");
        setLog(
          <span>
            Congratulations, your blueprint's been staked.
            <br />
            Look at the Vesting section to see your current deposits.
          </span>
        );
      }
    } catch (e) {
      console.error(e);
      setLog("");
      // setDialogOpened(false);
      if (!error) {
        setError(e.message);
      }
    }
  };

  function cancelTransaction() {
    setShowTxInput(false);
    setLoadingTx(false);
  }

  const completeStake = async () => {
    setError("");
    setFinishingStake(true);
    if (pool.isSideChain(web3NetworkDetails)) {
      try {
        // setStakingNow(true);
        const transfer = getTransfer();
        let message;
        const { tokenType } = deserializeInputPayload(transfer.payload);
        if (
          tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_BOOST ||
          tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_BOOST
        ) {
          message = "Congratulations, you are boosting reward on your SEED!";
        } else {
          message = "Congratulations, you are now farming SEED!";
        }
        if (
          transfer.status < VAA_BYTES_GOT ||
          transfer.status === COMPLETE_TRANSFER_MINED
        ) {
          return setError("There is not a transfer to be completed");
        }
        setLog(
          <span>
            Planting your SEED.{" "}
            <div className={"dont"}>
              <div className="unskewed">DO NOT CLOSE OR REFRESH THE PAGE</div>
            </div>
          </span>
        );
        const good = await pool.completeCrossChainTransfer(
          web3NetworkDetails,
          transfer,
          setLog,
          updateTransfer,
          setProgress,
          setError
        );
        setFinishingStake(false);
        if (good) {
          setLog(
            <span>
              {message}
              <br />
              Look at the Vesting section to see your current deposits.
              <br />
              <BoxButton
                buttonText={"close"}
                onClick={resetAll}
                className={"popupClose"}
              />
            </span>
          );
          // db.clearTransfer("main")
          await readBalance(true);
        } else if (good === false) {
          setLog("");
        } else {
          setLog("");
          if (!error) {
            setError("Something wrong happened, please, refresh the page");
          }
        }
      } catch (e) {
        console.error(e);
        setLog("");
        setError(e.message);
      }
    }
  };

  const onStakeClick = (tokenType) => {
    setError("");
    const onMainChain = tokenType < tokenTypes.BLUEPRINT_STAKE_FOR_BOOST;
    if (
      (onMainChain && !pool.isMainChain(web3NetworkDetails)) ||
      (!onMainChain && !pool.isSideChain(web3NetworkDetails))
    ) {
      const otherChainId = onMainChain
        ? web3NetworkDetails.chainId === 56
          ? 1
          : 44787
        : web3NetworkDetails.chainId === 1
        ? 56
        : 43113;
      setModal({
        show: true,
        title: `Wrong network!`,
        body: (
          <span>
            <Button
              onClick={async () => {
                await switchTo(otherChainId);
              }}
            >
              Click to switch to {supportedId[otherChainId].chainName}
            </Button>
          </span>
        ),
        onClose: () => {
          setModal({
            show: false,
          });
        },
      });
    } else {
      if (tokenType < tokenTypes.BLUEPRINT_STAKE_FOR_BOOST && !alertSeen) {
        return bigAlert();
      }
      setActiveTokenType(tokenType);
      if (
        tokenType === tokenTypes.S_SYNR_SWAP ||
        tokenType === tokenTypes.SYNR_STAKE
      ) {
        setStakingOpened(true);
      } else {
        setStakingNftOpened(true);
      }
    }
  };

  const recoverProcess = async (pool) => {
    setRecoverStatus(2);
    setLog("Looking to recover interrupted transfers");
    const transfer = await getTransfer();
    if (!transfer) {
      setLog("");
      return;
    }
    const { status, vaaBytes, payload } = transfer;
    if (
      status === TRANSFER_INITIATED ||
      status === COMPLETE_TRANSFER_MINED ||
      status === SKIP
    ) {
      setLog("");
    } else if (typeof status === "number") {
      if (status < VAA_BYTES_GOT) {
        if (pool.isSideChain(web3NetworkDetails)) {
          setLog("Please, switch to the Main chain");
          await pool.switch(web3NetworkDetails);
        } else {
          const { tokenType, lockupTime, amount } =
            deserializeInputPayload(payload);
          updateAction(tokenType, undefined, amount);
          setLog("");
          await pool.startCrossChainTransfer(
            web3NetworkDetails,
            tokenType,
            lockupTime,
            amount,
            setLog,
            updateTransfer,
            transfer,
            setProgress,
            setError
          );
        }
      } else {
        setProgress(63);
        if (vaaBytes && pool.isMainChain(web3NetworkDetails)) {
          setLog("Please, switch to the Side chain");
          await pool.switch(web3NetworkDetails);
        } else {
          const sx = _.clone(styles.buttonStyle);
          sx.margin = "27px 0 20px";
          if (status === REC_TX_FAILED) {
            setProgress(-1);
            setError(
              <CompleteTransactionError
                chainId={web3NetworkDetails.chainId}
                transfer={transfer}
                updateTransfer={updateTransfer}
                reason={
                  await getRevertReason(
                    transfer.recTx,
                    undefined,
                    undefined,
                    web3NetworkDetails.provider
                  )
                }
              />
            );
          } else if (status === LOST_TRANSACTION) {
            setProgress(-1);
            setError(
              <LostTransactionError
                chainId={web3NetworkDetails.chainId}
                transfer={transfer}
                updateTransfer={updateTransfer}
              />
            );
          } else if (status === SKIP) {
            setProgress(-1);
            setLog("");
          } else {
            const { tokenType } = deserializeInputPayload(payload);
            if (tokenType === tokenTypes.S_SYNR_SWAP) {
              setAction("Finalizing the swap");
            } else {
              setAction("Finalizing the stake");
            }
            setLog(
              <span>
                You are ready to complete the transfer.
                <br />
                <Button
                  style={sx}
                  // variant="outlined"
                  onClick={() => completeStake(pool)}
                >
                  <div style={{ fontSize: "1.2rem" }}>
                    {tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_BOOST ||
                    tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_BOOST
                      ? "Boost my SEED"
                      : "Generate my SEED"}
                  </div>
                </Button>
              </span>
            );
          }
        }
      }
    }
  };

  const makeStakeClickHandler = (pool, tokenType) => {
    return (ev) => {
      onStakeClick(tokenType);
    };
  };

  async function setInitialPool() {
    if (web3NetworkDetails.connectedNetwork) {
      if (pool.isSupported(web3NetworkDetails)) {
        const conf = await pool.getConf(web3NetworkDetails);
        setMainPoolConf(conf.mainPoolConf);
        setSidePoolConf(conf.sidePoolConf);
        setSidePoolExtraConf(conf.sidePoolExtraConf);
        setRecoverStatus(1);
        await readDeposits();
        await readBalance();
        setIsSupported(2);
      } else {
        setIsSupported(0);
      }
    }
  }

  function stakeButton(tokenType, disabled) {
    return (
      <BoxButton
        onClick={makeStakeClickHandler(pool, tokenType)}
        buttonText={tokenType === tokenTypes.S_SYNR_SWAP ? "SWAP" : "STAKE"}
      />
    );
  }

  useEffect(() => {
    if (recoverTx && recoveredPayload && stakeInfo) {
      stake(
        pool,
        ethers.utils.formatEther(stakeInfo.tokenAmountOrID.toString()),
        (stakeInfo.lockedUntil - stakeInfo.lockedFrom) / DAY,
        stakeInfo.tokenType
      );
    }
    if (recoverStatus === 1) {
      recoverProcess(pool);
    }
  });

  useEffect(() => {
    if (!poolSet) {
      setPoolSet(true);
      setInitialPool();
    }
    if (progress > -1 && progress < 100) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
    // eslint-disable-next-line
  }, [progress, poolSet]);

  const hints = [
    "Select and confirm the assets you wish to stake, and for how long. Please note, you will need both ETH and BNB for gas fees.",
    "Please confirm to initialize Tesseract’s cross-chain capabilities. This step can take up to 10 minutes. You can hang around or come back later.",
    "When prompted to do so, switch networks to Binance Chain.",
    "Tesseract is active. Please confirm to send your assets across the metaverse. DO NOT REFRESH!",
    "Congratulations Synner, you have successfully staked your assets and your SEED can be viewed and claimed on the “Rewards” page.",
  ];

  const tiles = [
    {
      title: "STAKE SYNR",
      important: true,
      icon: CoreStakingIcon,
      description: [
        <span>
          Balance
          <br />{" "}
          {availableSynr > -1 ? (
            <span className={"regular"}>
              {enUsFormat(parseAnd2Decimals(availableSynr))}
            </span>
          ) : (
            <SmallCircularProgress />
          )}
        </span>,
        "Stake SYNR -> Generate SEED",
        <br />,
      ],
      buttonText: "STAKE",
      buttonVariant: "outlined",
      buttonComponent: stakeButton(tokenTypes.SYNR_STAKE),
      disable: availableSynr < 1,
    },
    {
      title: "SWAP sSYNR",
      icon: sSynrIcon,
      description: [
        <span>
          Balance
          <br />
          {availableSSynr > -1 ? (
            <span className={"regular"}>
              {enUsFormat(parseAnd2Decimals(availableSSynr))}
            </span>
          ) : (
            <SmallCircularProgress />
          )}
        </span>,
        "Swap sSYNR -> Generate SEED",
        <br />,
      ],
      buttonText: "SWAP",
      buttonVariant: "outlined",
      buttonComponent: stakeButton(tokenTypes.S_SYNR_SWAP),
      disable: availableSSynr < 1,
    },
    {
      title: "STAKE SYNR PASS",
      icon: GenesisIcon,
      description: [
        <span>
          Owned
          <br />
          {availableSynrPass > -1 ? (
            <span className={"regular"}>{availableSynrPass}</span>
          ) : (
            <SmallCircularProgress />
          )}
        </span>,
        "Stake SYNR PASS -> Generate SEED",
        "SYNR Pass is equivalent 180,000 SYNR",
      ],
      buttonText: "STAKE",
      buttonVariant: "outlined",
      buttonComponent: stakeButton(tokenTypes.SYNR_PASS_STAKE_FOR_BOOST),
      disable: availableSynrPass < 1,
    },
    {
      title: "STAKE BLUEPRINT",
      icon: BlueprintIcon,
      description: [
        <span>
          Owned
          <br />
          {availableBlueprint > -1 ? (
            <span className={"regular"}>{availableBlueprint}</span>
          ) : (
            <SmallCircularProgress />
          )}
        </span>,
        "Stake Blueprint -> Generate SEED",
        "Blueprint is equivalent 10,000 SYNR",
      ],
      buttonText: "STAKE",
      buttonVariant: "outlined",
      buttonComponent: stakeButton(tokenTypes.BLUEPRINT_STAKE_FOR_BOOST),
      disable: availableBlueprint < 1,
    },
  ];

  if (!isSupported) {
    if (!poolSet) {
      return <CircularProgress />;
    } else {
      return (
        <>
          <div className={"centeredError"}>
            <Switch section={"seed"} />
          </div>
        </>
      );
    }
  } else {
    if (!poolSet || !pools.length) {
      return <></>;
    } else {
      let possibleMissed = 0;
      if (
        pool.isMainChain(web3NetworkDetails) &&
        sideDeposits &&
        mainDeposits
      ) {
        let depositsByMainIndex = {};
        for (let deposit of sideDeposits) {
          depositsByMainIndex[deposit.mainIndex] = true;
        }
        for (let deposit of mainDeposits) {
          if (!depositsByMainIndex[deposit.mainIndex]) {
            possibleMissed++;
          }
        }
      }

      return (
        <>
          <JumboProgress
            error={error}
            log={log}
            progress={progress}
            totalSteps={totalSteps}
            hints={hints}
            action={action}
            onClose={resetAll}
            doNotOpenIf={dialogOpened}
          />
          <div
            className={"dont-see centered even-smaller light"}
            style={{ paddingBottom: 12 }}
          >
            Don't you see all your assets?{" "}
            <span
              className={"command"}
              style={{
                color: "#b86Df7",
                whiteSpace: isMobile() ? "nowrap" : null,
              }}
              onClick={() => refreshBalances()}
            >
              Refresh the balances
            </span>
          </div>
          <Container maxWidth="lg" component="main">
            {isSupported ? (
              <Grid container spacing={2} alignItems="flex-end">
                {tiles.map((tile, index) => {
                  return (
                    <Grid item xs={12} sm={12} md={3} key={"tiles_" + index}>
                      <TileItem
                        title={tile.title}
                        cls={Case.kebab(tile.title)}
                        icon={tile.icon}
                        alt={tile.title}
                        iconStyle={{ height: 120 }}
                        description={tile.description}
                        description2={tile.balance}
                        buttonText={tile.buttonText}
                        route={tile.route}
                        myref={null}
                        buttonComponent={tile.buttonComponent}
                        balance={tile.balance}
                        disable={tile.disable}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <>
                <div className={"centeredError"}>
                  {/* <Switch section={section} /> */}
                </div>
              </>
            )}

            {possibleMissed > 0 ? (
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={12} sm={12} md={12}>
                  {" "}
                  <TileItem>
                    {showTxInput ? (
                      loadingTx ? (
                        <div className={"centered"}>
                          <SmallCircularProgress />
                          <div>Verifying the transaction...</div>
                        </div>
                      ) : (
                        <div className={"rec-tx"}>
                          <div style={{ padding: "12px 0" }}>
                            <label>
                              Paste the hash of the transaction that started the
                              process:
                            </label>
                            <input
                              id="tx-hash"
                              type="text"
                              value={tx}
                              onChange={onChange}
                            />
                          </div>
                          <div>
                            <BoxButton
                              onClick={cancelTransaction}
                              buttonText={"Cancel"}
                              display={"inline"}
                              buttonClassName={"secondaryButton"}
                            />
                            <BoxButton
                              onClick={setTransaction}
                              buttonText={"Validate"}
                              display={"inline"}
                            />
                          </div>
                        </div>
                      )
                    ) : (
                      <span style={{ color: "#aaa" }}>
                        {possibleMissed} cross-chain transaction
                        {possibleMissed > 1 ? "s" : ""} seem
                        {possibleMissed === 1 ? "s" : ""} to be incomplete.{" "}
                        <span
                          className={"command"}
                          style={{
                            color: "#b86Df7",
                            whiteSpace: isMobile() ? "nowrap" : null,
                          }}
                          onClick={() => setShowTxInput(true)}
                        >
                          Try to recover an interrupted transfer
                        </span>
                      </span>
                    )}
                  </TileItem>
                </Grid>
              </Grid>
            ) : null}

            <UnderTiles />
          </Container>
          {mainPoolConf && mainPoolConf.minimumLockupTime > 0 ? (
            <SeedDialog
              pool={pool}
              opened={stakingOpened}
              setOpened={setStakingOpened}
              stake={stake}
              mainPoolConf={mainPoolConf}
              sidePoolConf={sidePoolConf}
              sidePoolExtraConf={sidePoolExtraConf}
              tokenType={activeTokenType}
            />
          ) : null}
          {stakingNftOpened ? (
            <EquivalentOrBoostDialog
              pool={pool}
              opened={stakingNftOpened}
              setOpened={setStakingNftOpened}
              stake={stake}
              mainPoolConf={mainPoolConf}
              sidePoolConf={sidePoolConf}
              sidePoolExtraConf={sidePoolExtraConf}
              tokenType={activeTokenType}
            />
          ) : null}
          <BasicModal
            show={modal.show}
            title={modal.title}
            body={modal.body}
            onClose={modal.onClose}
          />
          <MessageDialog
            ok={ok}
            message={log}
            title={action}
            error={error || txError}
            opened={!!txError || dialogOpened}
            onClose={resetError}
            borderColor={"#7b16db"}
            color={"#9b36fb"}
          />
        </>
      );
    }
  }
}

export default SeedPool;
