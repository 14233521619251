import * as React from "react";
import { Box } from "@mui/material";

export default function BodyBox(props) {
  return (
    <Box className={"bodyBox " + (props.className || "")} sx={props.sx || {}}>
      {props.children}
    </Box>
  );
}
