import React, { useContext, useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Button2 from "./tile/Button2";
import { StoreContext } from "../contexts/StoreContext";
import { setSection } from "../utils";
import { ListItem, ListItemText } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import Logo from "./Logo";
import { supportedId } from "../config";
import { Web3Context } from "../contexts/Web3Context";
import { switchTo } from "../utils/networkUtils";
import { capitalize, ellipsizeAddress, isMobile } from "../utils";
import MenuPopupState from "./MenuPopupState";

function NavigationBar() {
  const { store, db } = useContext(StoreContext);
  const { section } = store;
  const { web3Connect, web3NetworkDetails } = useContext(Web3Context);
  const { chainId, connectedWallet } = web3NetworkDetails;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [waited, setWaited] = useState(false);

  setTimeout(() => setWaited(true), 1000);

  const currentChain = supportedId[chainId]
    ? supportedId[chainId].chainName
    : "Unsupported";

  const location = useLocation();

  useEffect(() => {
    document.body.classList.remove(
      "seed-container",
      "core-container",
      "info-container"
    );
    document.body.classList.add(section + "-container");
  }, [section]);

  const styles = {
    link: {
      color: section === "core" ? "#FFEE00" : "#d461ff",
      padding: "10px 10px",
      textDecoration: "none",
      fontSize: "92%",
      margin: 4,
      fontWeight: "bold",
    },
    RouterLink: {
      color: "white",
      fontSize: "20px",
      marginRight: "20px",
      textDecoration: "none",
    },
    icon: {
      color: "white",
      marginLeft: "250px",
    },
    drawer: {
      backgroundColor: "black",
      float: "right",
    },
  };
  // const section = window.location.pathname.split('/')[1]

  function secFromLink(link) {
    return link.split("/")[1];
  }

  let menuItems;
  if (section === "core") {
    menuItems = [
      { title: "How To Play", link: "/info/overview" },
      { title: "Dashboard", link: `/core/dashboard` },
      { title: "SEED Farm", link: "/seed/dashboard" },
    ];
  } else if (section === "seed") {
    menuItems = [
      { title: "How To Play", link: "/info/overview" },
      { title: "Core Pool", link: "/core/dashboard" },
    ];
    menuItems.push(
      { title: "Dashboard", link: `/seed/dashboard` },
      { title: "Generator", link: `/seed/staking` },
      { title: "Vesting", link: `/seed/vesting` }
    );
  } else {
    menuItems = [
      { title: "How To Play", link: "/info/overview" },
      { title: "Core Pool", link: `/core/dashboard` },
      { title: "SEED Farm", link: `/seed/dashboard` },
    ];
  }

  const sectionChains =
    section === "core" ? [1, 42] : chainId > 56 ? [44787, 43113] : [1, 56];

  const items = [];
  for (let id in supportedId) {
    if (
      sectionChains.includes(parseInt(id)) &&
      supportedId[id] &&
      supportedId[id].chainName !== currentChain
    ) {
      items.push({
        label: "Switch to " + supportedId[id].chainName,
        onClick: ((id0) => async () => {
          await switchTo(id0);
        })(id),
      });
    }
  }

  return (
    <>
      <AppBar
        position="fixed"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <div className="appbarparent">
          <div className="appbar">
            <nav>
              {isMobile() ? (
                <MenuIcon
                  style={styles.icon}
                  onClick={() => setOpenDrawer(!openDrawer)}
                />
              ) : (
                menuItems.map((item) => {
                  return (
                    <Link
                      key={item.title}
                      // color="text.primary"
                      to={item.link}
                      onClick={() => {
                        setSection(store, db, secFromLink(item.link));
                      }}
                      className={
                        item.link === location.pathname ? "selected" : ""
                      }
                      sx={{ my: 1, mx: 1.5 }}
                      component={RouterLink}
                      style={styles.link}
                    >
                      {/*<span*/}
                      {/*  style={{*/}
                      {/*    color:*/}
                      {/*      location.pathname.split("/")[2] === item.title*/}
                      {/*        ? "#FFEE00"*/}
                      {/*        : "white",*/}
                      {/*  }}*/}
                      {/*>*/}
                      {capitalize(item.title)}
                      {/*</span>*/}
                    </Link>
                  );
                })
              )}
            </nav>
          </div>
        </div>
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 0 }}>
            <div style={{ float: "left", paddingTop: 4 }}>
              <Logo
                style={{
                  height: 36,
                  paddingTop: 3,
                  marginLeft: 0,
                  marginRight: 12,
                }}
              />
            </div>
          </Typography>

          {connectedWallet ? (
            <MenuPopupState
              title={
                <span style={{ textTransform: "none" }}>
                  {currentChain}
                  {" - "}
                  {ellipsizeAddress(connectedWallet)}
                </span>
              }
              items={items}
            />
          ) : null}
          <div
            style={{
              position: "absolute",
              right: isMobile() ? "40px" : 0,
            }}
          >
            {connectedWallet ? null : waited ? (
              <Button2 onClick={web3Connect} buttonText={"Connect Wallet"} />
            ) : null}
          </div>
        </Toolbar>
      </AppBar>
      <div>
        {isMobile() && openDrawer ? (
          <div
            style={{
              paddingTop: 50,
              background: "linear-gradient(black, rgba(255,255,255,.15))",
              backgroundColor: "black",
            }}
          >
            {menuItems.map((item) => (
              <ListItem
                key={`x` + item.title}
                // onClick={() => setOpenDrawer(false)}
              >
                <ListItemText disableTypography style={{ textAlign: "center" }}>
                  <RouterLink
                    style={{
                      color:
                        item.link === location.pathname
                          ? section === "core"
                            ? "#FFEE00"
                            : "#d461ff"
                          : "white",
                      fontSize: "20px",
                      marginRight: "20px",
                      textDecoration: "none",
                    }}
                    to={item.link}
                  >
                    {capitalize(item.title)}
                  </RouterLink>
                </ListItemText>
              </ListItem>
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
}

export default NavigationBar;
