import React from "react";

function PrivacyPolicy() {
  return (
    <div className={"ga"}>
      <p className="bold title">
        MOBLAND Privacy Policy
        <br />
        <span className="light smaller">Last Updated: &nbsp;October 2022</span>
      </p>
      <p></p>
      <p>
        <span>MOBLAND (hereinafter, &ldquo;</span>
        <span className="c3">MOBLAND</span>
        <span>&rdquo;, &ldquo;</span>
        <span className="c3">we</span>
        <span>&rdquo;, &ldquo;</span>
        <span className="c3">us</span>
        <span>&rdquo; and &ldquo;</span>
        <span className="c3">our</span>
        <span>&rdquo;) values your privacy. This Privacy Policy (&ldquo;</span>
        <span className="c3">Privacy Policy</span>
        <span>
          &rdquo;) applies to the information we collect via our website located
          at mob.land
        </span>
        <span>; (the &ldquo;</span>
        <span className="c3">Site</span>
        <span>
          &rdquo;) and to the information we collect via our games (the &ldquo;
        </span>
        <span className="c3">Games</span>
        <span className="c0">
          &rdquo;). &nbsp;The purpose of this Privacy Policy is to provide you
          with information concerning how we collect, use, maintain, and share
          your personal information in accordance with applicable data
          protection laws.
        </span>
      </p>
      <p>
        <span className="c0">
          Personal information is processed by or on behalf of the data
          controller:
        </span>
      </p>
      <p>
        <span className="c3a">Syndicate Production Pte Ltd.</span>
        <br />
        <span className="c3a">68 Circular Road #02-01</span>
      </p>
      <p>
        <span className="c2">Information We Collect and Process</span>
      </p>
      <p>
        <span className="c0">
          We collect the following personal information about the visitors to
          our Site; users of our Games; our business partners; and our potential
          business partners when these individuals (i) visit our Site; (ii)
          register for or use our Games; (iii) request additional information
          about our Site and/or Games; or (iv) otherwise contact or interact
          with us on the Site or via the Games.
        </span>
      </p>
      <table className="c22">
        <tbody>
          <tr className="c14">
            <td className="c21" colSpan="1" rowSpan="1">
              <p className="c9">
                <span className="c2">
                  Category of Personal&nbsp;Information Collected
                </span>
              </p>
            </td>
            <td className="c28" colSpan="1" rowSpan="1">
              <p className="c9">
                <span className="c2">Examples</span>
              </p>
            </td>
            <td className="c5" colSpan="1" rowSpan="1">
              <p className="c9">
                <span className="c2">Sources of Personal Information</span>
              </p>
            </td>
            <td className="c33" colSpan="1" rowSpan="1">
              <p className="c9">
                <span className="c2">
                  Business Purpose for Collection of Personal&nbsp;Information
                </span>
              </p>
            </td>
          </tr>
          <tr className="c14">
            <td className="c8" colSpan="1" rowSpan="1">
              <p className="c13">
                <span className="c0">Identifiers</span>
              </p>
            </td>
            <td className="c11" colSpan="1" rowSpan="1">
              <p className="c23">
                <span className="c0">
                  Name, Business Contact Information, including Email Address,
                  Company Name, Job Title, Online Identifiers/Social Media
                  Account/Login &amp; Password.
                </span>
              </p>
            </td>
            <td className="c26" colSpan="1" rowSpan="1">
              <p className="c19">
                <span className="c0">
                  Directly from the users of our Site and Games.
                </span>
              </p>
            </td>
            <td className="c8" colSpan="1" rowSpan="1">
              <p className="c19">
                <span className="c0">
                  Identification, communications, security, legal
                  issues/compliance
                </span>
              </p>
            </td>
          </tr>
          <tr className="c14">
            <td className="c31" colSpan="1" rowSpan="1">
              <p className="c13">
                <span className="c0">Financial Information</span>
              </p>
            </td>
            <td className="c30" colSpan="1" rowSpan="1">
              <p className="c23">
                <span className="c0">
                  Web3 Wallet Address; Metamask Identification, Other Crypto
                  Wallet Identifiers.
                </span>
              </p>
            </td>
            <td className="c29" colSpan="1" rowSpan="1">
              <p className="c19">
                <span className="c0">
                  Directly from the users of our Site and Games.
                </span>
              </p>
            </td>
            <td className="c31" colSpan="1" rowSpan="1">
              <p className="c19">
                <span className="c0">
                  Identification, communications, security, legal
                  issues/compliance
                </span>
              </p>
            </td>
          </tr>
          <tr className="c14">
            <td className="c25" colSpan="1" rowSpan="1">
              <p className="c13">
                <span className="c0">Commercial Information</span>
              </p>
            </td>
            <td className="c20" colSpan="1" rowSpan="1">
              <p className="c19">
                <span className="c0">
                  Records of personal property, products or services purchased,
                  obtained, or considered, or other purchasing or consuming
                  histories or tendencies.
                </span>
              </p>
            </td>
            <td className="c15" colSpan="1" rowSpan="1">
              <p className="c19">
                <span className="c0">
                  Directly from the users of our Site and Games.
                </span>
              </p>
            </td>
            <td className="c25" colSpan="1" rowSpan="1">
              <p className="c19">
                <span className="c0">
                  Identification, communications, security, legal
                  issues/compliance
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="c18">
        <span className="c0"></span>
      </p>
      <p>
        <span className="c0">
          We process this personal information as necessary for us:
        </span>
      </p>
      <ul className="c4 lst-kix_list_42-0 start">
        <li className="c1 c7 li-bullet-0">
          <span className="c0">
            To provide our Site and Games to our users, potential users,
            partners, potential partners, and visitors;
          </span>
        </li>
        <li className="c1 c7 li-bullet-0">
          <span className="c0">
            To fulfill our legitimate interests, including to manage our
            business operations; to take steps to prevent, detect or investigate
            crime, fraud, misconduct or any unlawful action or omission; to
            comply with policies and procedures under applicable regulations,
            guidelines or notices as well as our own policies and procedures;
          </span>
        </li>
        <li className="c1 c7 li-bullet-0">
          <span className="c0">
            To inform you of updates to our Site, information regarding our
            Games, and other relevant news; and
          </span>
        </li>
        <li className="c1 c7 li-bullet-0">
          <span className="c0">
            For other purposes with notice to you and with your consent where
            necessary.
          </span>
        </li>
      </ul>
      <p>
        <span className="c3 c24">
          Other Automatically Collected Information
        </span>
        <span>:</span>
        <span className="c3">&nbsp; </span>
        <span className="c0">
          Whether you are a visitor to our Site, user of Games, partner or
          potential partner, our servers automatically collect limited
          information about your computer when you visit our Site. This
          information may include the type of browser software you use, the
          operating system you are running, the IP address assigned to your
          computer or Internet connection, and the IP address of the website, if
          any, that referred you to our Site. We use this information to
          understand how visitors navigate through our Site, to enhance your
          experience while using our Site, and to make the materials we post as
          valuable to visitors as possible.
        </span>
      </p>
      <p>
        <span className="c3 c24">Legitimate Interests/Consent</span>
        <span className="c0">
          : &nbsp;To the extent we rely on our legitimate interests as a legal
          basis for processing of your personal information, we have considered
          the balance between our own interests (among other things, the lawful
          and efficient operation of our Site and Games) and your interests and
          we believe that (a) you would reasonably expect us to carry out the
          kind of processing referenced above and (b) such processing will not
          cause you any harm and/or will not seriously impact your rights and
          freedoms with regard to data privacy. You have the right to withdraw
          any consent given to us for the processing of your personal
          information.
        </span>
      </p>
      <p>
        <span className="c3 c24">Collection of Non-Personal Information</span>
        <span>: </span>
        <span className="c3">&nbsp;</span>
        <span className="c0">
          We may collect certain information from you in order to play our
          Games, including your cryptocurrency wallet address. For the purposes
          of this Privacy Policy, this type of information is not considered
          personal information.{" "}
        </span>
      </p>
      <p>
        <span className="c2">Disclosures of Personal Information</span>
      </p>
      <p>
        <span className="c0">
          In order for us to provide our Site and Games, we may share your
          personal information with the following categories of recipients:
        </span>
      </p>
      <ul className="c4 lst-kix_list_43-0 start">
        <li>
          <span className="c0">
            Service Providers, including those who work to enhance our Site or
            Games and to protect the security of our systems.
          </span>
        </li>
        <li className="c7 c10 li-bullet-0">
          <span className="c0">
            Partners, including to provide our Site and Games to you.
          </span>
        </li>
        <li className="c1 c7 li-bullet-0">
          <span className="c0">
            Legal/Regulatory Authorities, including to respond to a subpoena or
            court order, judicial process, or regulatory inquiry; to defend
            against fraud, lawsuits, claims or other liabilities; and to prevent
            physical harm or financial loss in connection with any suspected or
            actual illegal activity.
          </span>
        </li>
      </ul>
      <p>
        <span className="c2">Cross-Border Transfers</span>
      </p>
      <p>
        <span className="c0">
          We may transfer your personal information to a third party that is
          located in a jurisdiction other than the one from which we collected
          your personal information, including to countries that have not been
          deemed to have an adequate level of protection for the rights and
          freedoms of data subjects. If we do transfer your personal information
          to another jurisdiction, we will do so following due diligence and
          provided that the data recipient is subject to contractual agreements
          imposing obligations on it to ensure appropriate technical and
          organization measures are implemented and maintained at all times to
          prevent the unauthorized and unlawful processing of personal
          information, and the accidental loss or destruction of, or damage to,
          personal information, consistent with our obligations under applicable
          data protection laws.
        </span>
      </p>
      <p>
        <span className="c2">Retention of Personal Information</span>
      </p>
      <p>
        <span className="c0">
          We will only retain your personal information for as long as necessary
          to fulfill the purposes for which it was collected, including for the
          purposes of satisfying any legal, regulatory, accounting, or reporting
          requirements. If we collect any biometric information about you, we
          will not store that information for longer than 3 years.{" "}
        </span>
      </p>
      <p>
        <span className="c2">Security of Personal Information</span>
      </p>
      <p>
        <span className="c0">
          The security of all personal information provided to us is important
          and we take reasonable steps designed to protect your personal
          information. We maintain reasonable administrative, technical, and
          physical safeguards designed to protect personal information that we
          receive against accidental, unlawful, or unauthorized destruction,
          loss, alteration, access, disclosure or use.
        </span>
      </p>
      <p>
        <span className="c2">Your Choices &amp; Rights</span>
      </p>
      <p>
        <span>
          Depending upon where you are located, certain choices and rights may
          be available to you under applicable data protection laws, including
          the right to request access to your personal information or to have
          your personal information deleted. If you have questions about what
          rights may apply to you, please contact us at{" "}
        </span>
        <span className="c16">privacy@superpower.io</span>
      </p>
      <p>
        <span className="c3 c24 c37">
          For Individuals Located in the European Economic Area (EEA), the
          United Kingdom (UK) or Switzerland:
        </span>
      </p>
      <p>
        <span className="c0">
          You have a number of rights under applicable data protection laws in
          relation to your personal information. &nbsp;Under certain
          circumstances, you have the right to:
        </span>
      </p>
      <ul>
        <li>
          <span className="c0">
            Have access to your personal information by submitting a request to
            us;
          </span>
        </li>
        <li>
          <span className="c0">Have your personal information deleted;</span>
        </li>
        <li>
          <span className="c0">
            Have your personal information corrected if it is wrong;
          </span>
        </li>
        <li>
          <span className="c0">
            Have the processing of your personal information restricted;
          </span>
        </li>
        <li>
          <span className="c0">
            Object to further processing of your personal information, including
            to object to marketing from us;
          </span>
        </li>
        <li>
          <span className="c0">Make a data portability request;</span>
        </li>
        <li>
          <span className="c0">
            Withdraw any consent you have provided to us;
          </span>
        </li>
        <li>
          <span className="c0">
            Restrict any automatic processing of your personal information; and
          </span>
        </li>
        <li>
          <span>Complain to the appropriate Supervisory Authority.</span>
        </li>
      </ul>
      <p className="c6">
        <span className="c2"></span>
      </p>
      <p>
        <span className="c2">Third-Party Links</span>
      </p>
      <p>
        <span className="c0">
          We may provide links to other sites or resources provided by third
          parties. These links are provided for your convenience only. &nbsp;We
          have no control over the contents of those sites or resources, and
          accept no responsibility for them or for any loss or damage that may
          arise from your use of them. If you decide to access any of the
          third-party links on the Site, you do so entirely at your own risk and
          subject to the terms and conditions of those sites.
        </span>
      </p>
      <p>
        <span className="c2">Children</span>
      </p>
      <p className="c36">
        <span className="c0">
          We do not knowingly collect personal information from children under
          the age of 13 through our Site. If you are under 13, please do not
          give us any personal information. We encourage parents and legal
          guardians to monitor their children&rsquo;s internet usage and to help
          enforce our Privacy Policy by instructing their children never to
          provide us personal information. If you have reason to believe that a
          child under the age of 13 has provided personal information to us,
          please contact us, and we will endeavor to delete that personal
          information from our databases.
        </span>
      </p>
      <p>
        <span className="c2">Changes to this Privacy Policy</span>
      </p>
      <p>
        <span className="c0">
          This Privacy Policy is effective as of the date stated at the top of
          this page. We may change this Privacy Policy from time to time. By
          accessing and using the Site after we notify you of such changes to
          this Privacy Policy, you are deemed to have accepted such changes.
          &nbsp;Please refer back to this Privacy Policy on a regular basis.
        </span>
      </p>
      <p>
        <span className="c2">Contact Us</span>
      </p>
      <p>
        <span>
          If you have any questions regarding this Privacy Policy or our privacy
          practices, please contact us at{" "}
        </span>
        <span className="c16">privacy@superpower.io</span>
        <span className="c0">.</span>
      </p>
      <p>
        <span className="c0">
          If you are located in the European Economic Area (EEA), the United
          Kingdom (UK) or Switzerland and believe we have not adequately
          resolved any issues, you may contact the Supervisory Authority
          concerned.
        </span>
      </p>
    </div>
  );
}

export default PrivacyPolicy;
