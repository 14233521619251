import React, { useEffect, useState } from "react";
import { isMobile } from "../utils";

import Popup from "./modal/Popup";
import TitleBox from "./modal/TitleBox";
import BodyBox from "./modal/BodyBox";
import BoxButton from "./tile/BoxButton";

// import termsAndConditions from "./docs/termsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
// const termsAndConditions = "Some terms. ".repeat(100);

function TermsAndConditions({ showTerms, setShowTerms }) {
  // eslint-disable-next-line
  const [opened, setOpened] = useState(false);
  const [ready, setReady] = useState(false);
  const [checked, setChecked] = useState(false);

  const accept = () => {
    if (showTerms) {
      setShowTerms(false);
    } else {
      setOpened(false);
      localStorage.setItem("_ML_accepted", true);
    }
  };

  useEffect(() => {
    if (!checked) {
      setChecked(true);
      let alreadyAccepted = localStorage.getItem("_ML_accepted");
      if (!alreadyAccepted) {
        setOpened(true);
      }
    }
  }, [checked]);

  if (showTerms || opened) {
    return (
      <Popup
        show={showTerms || opened}
        noClose={true}
        style={{ minWidth: isMobile() ? "90%" : "80%" }}
      >
        <TitleBox id="staking-dialog-title">Terms and conditions</TitleBox>
        <BodyBox>
          <div className={"scrollable"}>
            <PrivacyPolicy />
          </div>
          {showTerms ? null : (
            <div
              className="underRadio centered"
              style={{ margin: "36px 0 6px" }}
            >
              <label className={"light bit-smaller"}>
                <input
                  type={"checkbox"}
                  checked={ready}
                  onChange={() => setReady(!ready)}
                  value={true}
                  style={{
                    filter: "invert(100%) hue-rotate(226deg) brightness(1.0)",
                    marginRight: 8,
                  }}
                />
                I accept the policy above.
              </label>
            </div>
          )}
          <div className={"lowerButton"}>
            <BoxButton
              onClick={accept}
              buttonText={"Ok"}
              disabled={!showTerms && !ready}
              className={"bottomButton"}
            />
          </div>
        </BodyBox>
      </Popup>
    );
  }

  return null;
}

export default TermsAndConditions;
