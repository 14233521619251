import * as React from "react";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BoxButton from "../tile/BoxButton";

export default function Popup({
  onClose = () => {},
  children,
  noClose,
  style,
  show,
  closeButton,
  additionalClass = "",
}) {
  const handleClose = () => {
    onClose();
  };
  return (
    <Modal
      open={show}
      onClose={noClose ? function () {} : handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={Object.assign({ backdropFilter: "blur(12px)" }, style || {})}
    >
      <Box sx={style} className={"popupBox " + additionalClass}>
        <div className={"xclose"}>
          {noClose ? null : (
            <CloseIcon
              onClick={handleClose}
              sx={{
                cursor: "pointer",
              }}
            />
          )}
        </div>
        {children}
        {closeButton ? (
          <BoxButton
            buttonText={closeButton}
            onClick={handleClose}
            className={"popupClose"}
          />
        ) : null}
      </Box>
    </Modal>
  );
}
