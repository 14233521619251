import { Container, Typography } from "@mui/material";
import React from "react";
import { isMobile } from "../utils";

function Error404(props) {
  return (
    <Container
      disableGutters
      maxWidth="sm"
      component="main"
      sx={{ pt: 8, pb: 6, width: isMobile() ? "96%" : "auto" }}
    >
      <div className={"p404"}>
        <Typography component="h1" variant="h2" align="center" gutterBottom>
          404, page not found :-(
        </Typography>
      </div>
    </Container>
  );
}

export default Error404;
