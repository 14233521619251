import { explorerUrl } from "../../utils/networkUtils";
import { SKIP } from "../../config/constants";
import ErrorAlert from "../../images/errorAlert.png";
import BodyBox from "../modal/BodyBox";

function LostTransactionError({ chainId, transfer, updateTransfer }) {
  return (
    <BodyBox className={"errorBox centered"}>
      <div className={"errorIcon"}>
        <img src={ErrorAlert} alt={"error alert"} />
      </div>
      <div style={{ textAlign: "left" }}>
        {chainId === 44787 || chainId === 43113 ? (
          <p>
            WHOOPS, YOU ARE ON A TESTNET. PLEASE, IGNORE THIS ERROR AND CLICK ON
            "hide this error" AT THE BOTTOM OF THIS ALERT. WE ARE UNABLE TO
            HANDLE THIS TYPE OF ERRORS ON TESTNET :-(
          </p>
        ) : null}
        <p>
          It looks like the transaction got lost in the bridge. This is a very
          rare event. To solve it, please,{" "}
          <a
            href={"https://discord.gg/hmHpjvZ4U8"}
            target={"_blank"}
            rel="noreferrer"
            style={{ color: "#FFEE00" }}
          >
            join the Wormhole discord server
          </a>{" "}
          and open a ticket in the <b>get-support</b> channel. Provide them with
          the following transaction:
        </p>
        <p>
          <a
            href={explorerUrl(chainId, transfer.sendTx)}
            target={"_blank"}
            rel="noreferrer"
            style={{ color: "#FFEE00" }}
            className={"ubuntu-mono bit-smaller"}
          >
            {explorerUrl(chainId, transfer.sendTx).substring(0, 60)}...
          </a>
        </p>
        <p>
          Once Wormhole team recovers the transaction, please, contact Mobland
          support providing the transaction above, so that we can complete the
          process for you.
        </p>
        <p>Sorry for the inconvenient. Thanks!</p>
        <p className={"underError"}>
          <span>
            I saved the transaction,{" "}
            <span
              className={"command"}
              onClick={() => {
                updateTransfer({ status: SKIP });
                window.location.reload();
              }}
            >
              hide this error
            </span>{" "}
            and allow me to make another operation
          </span>
        </p>
      </div>
    </BodyBox>
  );
}

export default LostTransactionError;
