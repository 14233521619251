import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Web3Context } from "../../contexts/Web3Context";
import { WEEK } from "../../config/constants";
import LockPeriod from "../seedFarm/LockPeriod";
import SynrDouble from "../../images/SYNR_Double_small.png";
import StakedAmount from "./StakedAmount";
import { isMobile, localeFormat, sleep, addSomeDecimals } from "../../utils";
import { title, tokenTypes } from "../../config";
import CoreLightTooltip from "./CoreLightTooltip";
import { ethers } from "ethers";

import Popup from "../modal/Popup";
import TitleBox from "../modal/TitleBox";
import BodyBox from "../modal/BodyBox";
import BoxButton from "../tile/BoxButton";

function calculateLockWeight(weeks) {
  let factor = 1 / 52;
  return addSomeDecimals(1 + weeks * factor);
}

function StakingDialog({ pool, opened, setOpened, stake }) {
  const { web3NetworkDetails, tokenBalance } = useContext(Web3Context);

  const [stakingType, setStakingType] = useState("locked");
  const [stakedAmount, setStakedAmount] = useState(0);
  const [lockPeriod, setLockPeriod] = useState(16);
  const [minLockTime, setMinLockTime] = useState(16);
  const [lockWeight, setLockWeight] = useState("1.30");
  const [estimatedApy, setEstimatedApy] = useState(0);
  const [balance, setBalance] = useState(0);
  const [error, setError] = useState("");
  const [choseRewards, setChoseRewards] = useState(false);
  const [useSsyn, setUseSsyn] = useState(true);
  const [deposits, setDeposits] = useState("");

  const closeDialog = () => {
    setOpened(false);
    setStakingType("locked");
  };

  const onStakeClick = async (ev) => {
    if (!error) {
      setOpened(false);
      stake(pool, stakedAmount, lockPeriod, tokenBalance, useSsyn);
    }
  };

  const onStakedAmountChange = (ev) => {
    let value = ev.target.value;
    if (!value) {
      value = "0";
    } else {
      value = value.replace(/^0+([1-9])/, "$1");
    }
    setStakedAmount(value);
    if (isNaN(parseFloat(value))) {
      setError("Invalid amount");
    } else if (parseFloat(value) > parseFloat(balance.replace(/,/g, ""))) {
      setError("Insufficient balance");
    } else if (parseFloat(value) < 0) {
      setError("Amount cannot be negative");
    } else {
      setError("");
      pool.apy(web3NetworkDetails, value, lockPeriod).then((apy) => {
        setEstimatedApy(apy);
      });
    }
  };

  const onMaxStakeClick = async (percentage) => {
    const fullBalance = await tokenBalance(
      web3NetworkDetails.connectedWallet,
      undefined,
      true
    );
    const balance = parseFloat(
      ethers.utils.formatEther(fullBalance.mul(percentage).div(100).toString())
    );
    setStakedAmount(balance);
    const apy = await pool.apy(web3NetworkDetails, balance, lockPeriod);
    setEstimatedApy(apy);
  };

  const onLockPeriodChange = (ev, amount, locked) => {
    let weeks = ev.target.value;
    setLockPeriod(weeks);
    setLockWeight(calculateLockWeight(weeks));
    pool.apy(web3NetworkDetails, amount, weeks).then((apy) => {
      setEstimatedApy(apy);
    });
  };

  useEffect(() => {
    (async () => {
      setDeposits(await pool.getDeposits(web3NetworkDetails));
      setBalance(await tokenBalance(web3NetworkDetails.connectedWallet));
      while (!pool || !deposits) {
        await sleep(1000);
      }
      if (deposits.length) {
        setChoseRewards(true);
      }
    })();
  });

  if (pool) {
    (async () => {
      setMinLockTime(
        parseInt(await pool.minLockTime(web3NetworkDetails)) / WEEK
      );
    })();
  }

  function EstimatedApy({ stakedAmount, estimatedApy }) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <div className={"centered"} style={{ paddingTop: 12 }}>
            Estimated APY:{" "}
            <span className={"white"}>
              {stakedAmount !== 0 ? localeFormat(estimatedApy) + "%" : "--"}
            </span>{" "}
          </div>
        </Grid>
      </Grid>
    );
  }

  if (pool && minLockTime) {
    return (
      <Popup
        onClose={closeDialog}
        show={opened}
        style={{ minWidth: isMobile() ? "90%" : "50%" }}
      >
        <TitleBox id="staking-dialog-title" icon={SynrDouble}>
          Core Pool Staking
        </TitleBox>
        <BodyBox>
          <StakedAmount
            balance={balance}
            amount={stakedAmount}
            apy={estimatedApy}
            tokenType={tokenTypes.SYNR_STAKE}
            onChange={onStakedAmountChange}
            onBtnClick={onMaxStakeClick}
          />
          <EstimatedApy
            estimatedApy={estimatedApy}
            stakedAmount={stakedAmount}
          />

          <LockPeriod
            locked={stakingType === "locked"}
            value={lockPeriod}
            weight={lockWeight}
            onChange={onLockPeriodChange}
            amount={stakedAmount}
            minLockTime={minLockTime}
          />
          <div className={"overRadio"}>
            {choseRewards ? (
              <div className="underRadio">
                <label htmlFor={"useSsyn"} className={"radio-form-control"}>
                  <input
                    type={"radio"}
                    id={"useSsyn"}
                    checked={useSsyn}
                    onChange={() => setUseSsyn(true)}
                    value={true}
                  />

                  <div className={"overLabel"}>
                    Receive rewards in sSYNR - immediate access
                    <CoreLightTooltip
                      title={`A new stake will process your pending rewards. sSYNR rewards are sent immediately to your wallet. You can use sSYNR to buy assets in the ${title} marketplace.`}
                      className={"smallTooltip"}
                    >
                      <HelpOutlineIcon
                        style={{
                          position: "relative",
                          top: 6,
                          left: 4,
                        }}
                      />
                    </CoreLightTooltip>
                  </div>
                </label>
                <label htmlFor={"useSyn"} className={"radio-form-control"}>
                  <input
                    type={"radio"}
                    id={"useSyn"}
                    checked={!useSsyn}
                    onChange={() => setUseSsyn(false)}
                    value={true}
                  />
                  <div className={"overLabel"}>
                    Receive rewards in SYNR — locked for 12 months
                    <CoreLightTooltip
                      title="A new stake will process your pending rewards. SYNR rewards are subject to a 12 month vesting period. Though, while they are vesting, they compound your rewards."
                      className={"smallTooltip"}
                    >
                      <HelpOutlineIcon
                        style={{
                          position: "relative",
                          top: 6,
                          left: 4,
                        }}
                      />
                    </CoreLightTooltip>
                  </div>
                </label>
              </div>
            ) : null}
          </div>

          {/*<div style={{ float: "right", paddingRight: 8 }}>*/}

          {/*</div>*/}
          {/*</Dialog>*/}
          <div className={"lowerButton"}>
            <BoxButton
              onClick={onStakeClick}
              buttonText={"STAKE"}
              disabled={!parseFloat(stakedAmount) || !!error}
              className={"bottomButton"}
            />
          </div>
        </BodyBox>
      </Popup>
    );
  }

  return null;
}

export default StakingDialog;
