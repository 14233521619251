import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { Web3Context } from "../../contexts/Web3Context";
import { tokenTypes, supportedId } from "../../config";
import { switchTo } from "../../utils/networkUtils";
import { ethers } from "ethers";

import { BN, timestamp } from "../../utils/PayloadUtils";

import {
  isMobile,
  parseAnd2Decimals,
  enUsFormat,
  localeFormat,
} from "../../utils";
import {
  calculateTaxOnRewards,
  calculateUntaxedRewards,
} from "../../utils/sidePoolViews";
import Popup from "../modal/Popup";
import TitleBox from "../modal/TitleBox";
import BoxButton from "../tile/BoxButton";

import "../../styles/video-react.css";
import Paper from "@mui/material/Paper";
import SYNRCoin from "../../images/SYNR_Forward.png";
import sSynr_Seed from "../../images/sSYNR_Seed.png";
import SYNRPass_Seed from "../../images/SYNRPass_SEED.png";
import Blueprint_SEED from "../../images/Blueprint_SEED.png";
import Loading from "../../images/loading-svgrepo-com.svg";
import { conf } from "../../config";

const styles = {
  buttonStyle: {
    backgroundColor: "#00000060",
    color: "#FFEE00",
    fontSize: "95%",
  },
  body: {
    maxHeight: 140,
    borderRadius: 20,
    backgroundColor: "transparent !important",
    backgroundImage: "none",
  },
};

function VestingDialog({
  pool,
  opened,
  setOpened,
  deposits,
  unstakeClick,
  setUnstakeOpened,
  activeDeposits,
  sidePoolConf,
  sidePoolExtraConf,
}) {
  const { web3NetworkDetails } = useContext(Web3Context);
  const [pendingRewards, setPendingRewards] = useState(false);

  useEffect(() => {
    if (!pendingRewards) {
      readRewards();
    }
    // eslint-disable-next-line
  }, [pendingRewards]);

  const readRewards = async () => {
    let rewards = await pool.pendingRewards(web3NetworkDetails);
    setPendingRewards(enUsFormat(parseAnd2Decimals(rewards)));
  };

  const makeStakeClickHandler = (deposit) => {
    closeDialog();
    if (
      deposit.tokenType <= tokenTypes.SYNR_STAKE &&
      deposit.lockedUntil * 1000 > Date.now()
    ) {
      setUnstakeOpened(true);
      unstakeClick(deposit);
    } else {
      unstakeClick(deposit);
    }
  };

  const closeDialog = () => {
    setOpened(false);
  };

  function reverseGenerator(deposit) {
    return enUsFormat(
      parseAnd2Decimals(
        BN(deposit.generator)
          .div(sidePoolConf.swapFactor)
          .div(sidePoolExtraConf.priceRatio)
          .mul(100 * 10000)
      )
    );
  }
  function getVested(deposit) {
    if (deposit.tokenType === tokenTypes.S_SYNR_SWAP) {
      if (timestamp() > deposit.lockedUntil) {
        return enUsFormat(
          parseFloat(ethers.utils.formatEther(deposit.generator.toString()))
        );
      }
    }
    return enUsFormat(
      parseFloat(
        ethers.utils.formatEther(
          deposit.generator
            .mul(BN(timestamp()).sub(deposit.lockedFrom))
            .div(BN(deposit.lockedUntil).sub(deposit.lockedFrom))
            .toString()
        )
      )
    );
  }
  function estimatedSeedAmount(amount, deposit) {
    amount = BN(amount);
    const untaxed = calculateUntaxedRewards(
      sidePoolConf,
      deposit,
      deposit.lockedUntil,
      deposit.lockedFrom
    );
    const taxes = calculateTaxOnRewards(sidePoolConf, untaxed);
    return ethers.utils.formatEther(untaxed.sub(taxes).toString());
  }

  function assetByTokenType(deposit) {
    const {
      tokenType,
      tokenID,
      stakedAmount,
      generator,
      lockedUntil,
      lockedFrom,
    } = deposit;
    const name =
      tokenType > tokenTypes.SYNR_PASS_STAKE_FOR_SEEDS
        ? "Blueprint"
        : tokenType > tokenTypes.SYNR_STAKE
        ? "SYNR Pass"
        : tokenType === tokenTypes.SYNR_STAKE
        ? "SYNR"
        : "sSYNR";
    const equivalent =
      tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_SEEDS ||
      tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_SEEDS ? (
        enUsFormat(parseAnd2Decimals(stakedAmount))
      ) : (
        <span className={"na"}>N/A</span>
      );
    const amount =
      tokenType < tokenTypes.SYNR_PASS_STAKE_FOR_BOOST
        ? enUsFormat(parseAnd2Decimals(stakedAmount))
        : 1;
    const id =
      tokenType > tokenTypes.SYNR_STAKE ? (
        tokenID
      ) : (
        <span className={"na"}>N/A</span>
      );
    let totalSeed;
    if (tokenTypes.S_SYNR_SWAP === tokenType) {
      totalSeed = enUsFormat(parseAnd2Decimals(generator));
    } else {
      const untaxed = calculateUntaxedRewards(
        sidePoolConf,
        deposit,
        lockedUntil,
        lockedFrom
      );
      const taxes = calculateTaxOnRewards(sidePoolConf, untaxed);
      totalSeed = enUsFormat(parseAnd2Decimals(untaxed.sub(taxes)));
    }
    return {
      name,
      amount,
      id,
      equivalent,
      totalSeed,
    };
  }

  const convertInDays = (ts) => {
    const sec = secondsToUnlock(ts);
    if (sec) {
      if (sec / (24 * 3600) > 1) {
        return Math.round(sec / (24 * 3600)) + " DAYS";
      } else if (sec / 3600 > 1) {
        return Math.round(sec / 3600) + " HOURS";
      } else {
        return Math.round(sec / 60) + " MINUTES";
      }
    } else {
      return "—";
    }
  };

  const secondsToUnlock = (ts) => {
    const now = parseInt(Date.now() / 1000);
    if (ts > now) {
      return ts - now;
    } else {
      return 0;
    }
  };

  function pendingRewardsComponent() {
    return (
      <div className={"floatRight pendingRewards light"}>
        Pending Rewards
        <br />
        <b>
          {pendingRewards || (
            <img
              alt={"Loading..."}
              src={Loading}
              style={{ width: 14, height: 14, filter: "invert(1)" }}
            />
          )}
        </b>{" "}
        SEED
      </div>
    );
  }

  function getTable(deposits) {
    if (pool.isMainChain(web3NetworkDetails)) {
      const sideChainId = web3NetworkDetails.chainId === 1 ? 56 : 43113;
      return (
        <>
          <TitleBox id="staking-dialog-title">Wrong Network!</TitleBox>
          <BoxButton
            buttonClassName={"seed"}
            onClick={async () => {
              await switchTo(sideChainId);
            }}
            buttonText={`Click to switch to ${supportedId[sideChainId].chainName}`}
          />
        </>
      );
    }
    if (activeDeposits === tokenTypes.SYNR_STAKE) {
      let synrStakes = [];
      for (let i in deposits) {
        if (deposits[i].tokenType === tokenTypes.SYNR_STAKE) {
          synrStakes.push(deposits[i]);
        }
      }
      let k = 0;
      return (
        <>
          <TitleBox id="staking-dialog-title" icon={SYNRCoin}>
            {pendingRewardsComponent()}
            SYNR{" "}
          </TitleBox>
          <div className={"over-left-container typed"}>
            <TableContainer
              component={Paper}
              sx={{
                minWidth: isMobile() ? 0 : 560,
                width: isMobile() ? "96%" : "70%",
                margin: "auto",
              }}
              style={styles.body}
              className={"left-container"}
            >
              <Table aria-label="staking pools">
                <TableHead>
                  <TableRow>
                    <TableCell className="tableCell" align="center">
                      Amount
                    </TableCell>
                    <TableCell className="tableCell" align="center">
                      Est. Total SEED
                    </TableCell>
                    <TableCell className="tableCell" align="center">
                      Locked For
                    </TableCell>
                    <TableCell className="tableCell" align="left">
                      &nbsp;
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {synrStakes.map((deposit) => {
                    const { amount, totalSeed } = assetByTokenType(deposit);
                    return (
                      <TableRow
                        key={"deposit" + k++}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" className="tableCell">
                          {amount}
                        </TableCell>
                        <TableCell align="center" className="tableCell">
                          {totalSeed}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ whiteSpace: "nowrap" }}
                          className="tableCell"
                        >
                          {convertInDays(deposit.lockedUntil) === "—"
                            ? " Unlocked"
                            : convertInDays(deposit.lockedUntil)}
                        </TableCell>
                        <TableCell align="center" className="tableCell">
                          <BoxButton
                            buttonClassName={"seed"}
                            onClick={() => makeStakeClickHandler(deposit)}
                            //   disabled={
                            //     secondsToUnlock(deposit.lockedUntil) > 0
                            //   }
                            buttonText={"UNSTAKE"}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      );
    } else if (activeDeposits === tokenTypes.S_SYNR_SWAP) {
      let synrSwaps = [];
      for (let i in deposits) {
        if (deposits[i].tokenType === tokenTypes.S_SYNR_SWAP) {
          synrSwaps.push(deposits[i]);
        }
      }
      let k = 0;
      return (
        <>
          <TitleBox id="staking-dialog-title" icon={sSynr_Seed}>
            {pendingRewardsComponent()}
            sSYNR Swaps{" "}
          </TitleBox>
          <div className={"over-left-container typed"}>
            <TableContainer
              component={Paper}
              sx={{
                minWidth: isMobile() ? 0 : 560,
                width: isMobile() ? "96%" : "70%",
                margin: "auto",
              }}
              style={styles.body}
              className={"left-container"}
            >
              <Table aria-label="staking pools">
                <TableHead>
                  <TableRow>
                    <TableCell className="tableCell" align="center">
                      Amount
                    </TableCell>
                    <TableCell className="tableCell" align="center">
                      Est. Total SEED
                    </TableCell>
                    <TableCell className="tableCell" align="center">
                      Vested
                    </TableCell>
                    <TableCell className="tableCell" align="center">
                      Germination
                    </TableCell>
                    <TableCell className="tableCell" align="left">
                      &nbsp;
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {synrSwaps.map((deposit) => {
                    const totalSeed = assetByTokenType(deposit).totalSeed;
                    let remainingTime = convertInDays(deposit.lockedUntil);

                    return (
                      <TableRow
                        key={"deposit" + k++}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" className="tableCell">
                          {reverseGenerator(deposit)}
                        </TableCell>
                        <TableCell align="center" className="tableCell">
                          {totalSeed}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ whiteSpace: "nowrap" }}
                          className="tableCell"
                        >
                          {getVested(deposit)}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="tableCell"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          {remainingTime === "—" ? "" : remainingTime}{" "}
                          {remainingTime === "—" ? "Complete" : "Remaining"}
                        </TableCell>
                        <TableCell align="center" className="tableCell">
                          <BoxButton
                            buttonClassName={"seed"}
                            onClick={() => makeStakeClickHandler(deposit)}
                            //   disabled={
                            //     secondsToUnlock(deposit.lockedUntil) > 0
                            //   }
                            buttonText={"UNLOCK"}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      );
    } else if (activeDeposits === tokenTypes.SYNR_PASS_STAKE_FOR_BOOST) {
      let stakedPasses = [];
      for (let i in deposits) {
        if (
          deposits[i].tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_BOOST ||
          deposits[i].tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_SEEDS
        ) {
          stakedPasses.push(deposits[i]);
        }
      }
      let k = 0;
      return (
        <>
          <TitleBox id="staking-dialog-title" icon={SYNRPass_Seed}>
            {pendingRewardsComponent()}
            Locked SYNR Passes{" "}
          </TitleBox>
          <div className={"over-left-container typed"}>
            <TableContainer
              component={Paper}
              sx={{
                minWidth: isMobile() ? 0 : 560,
                width: isMobile() ? "96%" : "70%",
                margin: "auto",
              }}
              style={styles.body}
              className={"left-container"}
            >
              <Table aria-label="staking pools">
                <TableHead>
                  <TableRow>
                    <TableCell className="tableCell" align="left">
                      ID
                    </TableCell>
                    <TableCell className="tableCell" align="center">
                      Variation
                    </TableCell>
                    <TableCell className="tableCell" align="center">
                      Est. Total SEED
                    </TableCell>
                    <TableCell className="tableCell" align="center">
                      Locked For
                    </TableCell>
                    <TableCell className="tableCell" align="left">
                      &nbsp;
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stakedPasses.map((deposit) => {
                    const { tokenType, tokenID } = deposit;
                    return (
                      <TableRow
                        key={"deposit" + k++}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left" className="tableCell">
                          {tokenID}
                        </TableCell>
                        <TableCell align="center" className="tableCell">
                          {tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_BOOST
                            ? "Multiplier"
                            : "SYNR EQ"}
                        </TableCell>
                        <TableCell align="center" className="tableCell">
                          {tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_BOOST
                            ? "N/A"
                            : localeFormat(
                                estimatedSeedAmount(
                                  conf.sPSynrEquivalent,
                                  deposit
                                )
                              )}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ whiteSpace: "nowrap" }}
                          className="tableCell"
                        >
                          {convertInDays(deposit.lockedUntil) === "—"
                            ? " Unlocked"
                            : convertInDays(deposit.lockedUntil)}
                        </TableCell>
                        <TableCell align="center" className="tableCell">
                          <BoxButton
                            buttonClassName={"seed"}
                            onClick={() => makeStakeClickHandler(deposit)}
                            disabled={
                              tokenType ===
                                tokenTypes.SYNR_PASS_STAKE_FOR_SEEDS &&
                              secondsToUnlock(deposit.lockedUntil) > 0
                            }
                            buttonText={"UNSTAKE"}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      );
    } else if (activeDeposits === tokenTypes.BLUEPRINT_STAKE_FOR_BOOST) {
      let stakedBlueprints = [];
      for (let i in deposits) {
        if (
          deposits[i].tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_BOOST ||
          deposits[i].tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_SEEDS
        ) {
          stakedBlueprints.push(deposits[i]);
        }
      }
      let k = 0;
      return (
        <>
          <TitleBox id="staking-dialog-title" icon={Blueprint_SEED}>
            {pendingRewardsComponent()}
            Locked Blueprints{" "}
          </TitleBox>
          <div className={"over-left-container typed"}>
            <TableContainer
              component={Paper}
              sx={{
                minWidth: isMobile() ? 0 : 560,
                width: isMobile() ? "96%" : "70%",
                margin: "auto",
              }}
              style={styles.body}
              className={"left-container"}
            >
              <Table aria-label="staking pools">
                <TableHead>
                  <TableRow>
                    <TableCell className="tableCell" align="left">
                      ID
                    </TableCell>
                    <TableCell className="tableCell" align="center">
                      Variation
                    </TableCell>
                    <TableCell className="tableCell" align="center">
                      Est. Total SEED
                    </TableCell>
                    <TableCell className="tableCell" align="center">
                      Locked For
                    </TableCell>
                    <TableCell className="tableCell" align="left">
                      &nbsp;
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stakedBlueprints.map((deposit) => {
                    const { tokenType, tokenID } = deposit;
                    return (
                      <TableRow
                        key={"deposit" + k++}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left" className="tableCell">
                          {tokenID}
                        </TableCell>
                        <TableCell align="center" className="tableCell">
                          {tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_BOOST
                            ? "Multiplier"
                            : "SYNR EQ"}
                        </TableCell>
                        <TableCell align="center" className="tableCell">
                          {tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_BOOST
                            ? "N/A"
                            : localeFormat(
                                estimatedSeedAmount(
                                  conf.bPSynrEquivalent,
                                  deposit
                                )
                              )}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ whiteSpace: "nowrap" }}
                          className="tableCell"
                        >
                          {convertInDays(deposit.lockedUntil) === "—"
                            ? " Unlocked"
                            : convertInDays(deposit.lockedUntil)}
                        </TableCell>
                        <TableCell align="center" className="tableCell">
                          <BoxButton
                            buttonClassName={"seed"}
                            onClick={() => makeStakeClickHandler(deposit)}
                            disabled={
                              tokenType ===
                                tokenTypes.BLUEPRINT_STAKE_FOR_SEEDS &&
                              secondsToUnlock(deposit.lockedUntil) > 0
                            }
                            buttonText={"UNSTAKE"}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      );
    }
  }

  if (pool) {
    if (deposits.length === 0) {
      return (
        <Popup
          onClose={closeDialog}
          show={opened}
          style={{ minWidth: isMobile() ? "90%" : "50%" }}
        >
          <TitleBox id="staking-dialog-title">
            You have no Deposits or Locked Rewards
          </TitleBox>
        </Popup>
      );
    }
    return (
      <Popup
        onClose={closeDialog}
        show={opened}
        style={{
          width: isMobile() ? "90%" : null,
          minWidth: isMobile()
            ? "99%"
            : pool.isMainChain(web3NetworkDetails)
            ? "30%"
            : "50%",
          borderColor: "#745094",
        }}
      >
        {getTable(deposits)}
      </Popup>
    );
  }

  return null;
}

export default VestingDialog;
