import React, { useContext } from "react";
import PageHero from "./PageHero";
import SeedDeposits from "./seedFarm/SeedDeposits";

import { StoreContext } from "../contexts/StoreContext";
import Switch from "./Switch";
import { Web3Context } from "../contexts/Web3Context";
import { PoolContext } from "../contexts/PoolContext";

function Vesting() {
  const { web3NetworkDetails } = useContext(Web3Context);
  const { store } = useContext(StoreContext);
  const { section } = store;

  let { pools } = useContext(PoolContext);
  const pool = section === "core" ? pools[0] : pools[1];

  let isSupported = pool.isSupported(web3NetworkDetails);

  const params = {
    seed: {
      pools: [{ id: "seed", label: "SEED", ui: null }],
      title: (
        <span className={"light"}>
          <span className={"bold"}>SEED GENERATOR VESTING</span>
        </span>
      ),
      description: (
        <span>
          Welcome to the Vesting Page! Here you can view and claim your SEED
          rewards and deposits.
        </span>
      ),
    },
  };

  const param = params[section];

  return (
    <>
      <PageHero
        title={param.title}
        description={param.description}
        img={param.img}
      />
      {isSupported ? (
        <SeedDeposits pools={param.pools} />
      ) : (
        <>
          <div className={"centeredError"}>
            <Switch section={section} />
          </div>
        </>
      )}
    </>
  );
}

export default Vesting;
