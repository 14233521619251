import { addSomeDecimals } from ".";
import clientApi from "./ClientApi";

function toHex(val) {
  return "0x" + Number(val).toString(16);
}

// defaults to Polygon PoS
async function switchTo(chainId) {
  const { supportedId } = require("../config");

  const chain = supportedId[chainId];
  chainId = chain.chainId;
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId }],
    });
    return true;
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [chain],
        });
        return true;
      } catch (addError) {
        console.error(addError);
      }
    } else {
      console.error(switchError);
    }
    // handle other "switch" errors
  }
  return false;
}

function decodeMetamaskError(error) {
  if (error.reason) {
    return [
      "VM Exception while processing transaction:",
      <b>{error.reason}</b>,
    ];
  }
  const message = error.message;
  try {
    if (/denied transaction signature/.test(message)) {
      return ["You denied transaction signature :-("];
    }
    let tmp = message.split('{"code":')[1].split(",");
    let res = tmp[1].split('"message":"')[1].split('"')[0].split(/: */);
    let reason = res.slice(1).join(": ");
    reason = reason.substring(0, 1).toUpperCase() + reason.substring(1);
    return [res[0] + ".", reason];
  } catch (e) {
    return ["VM Exception while processing transaction :-("];
  }
}

async function getSYNRPrice() {
  const { price } = await clientApi.request("gecko/synr-price", "get");
  const { usd, usd_24h_change } = price;
  return {
    usd: parseFloat(addSomeDecimals(usd, 3)),
    change: parseFloat(addSomeDecimals(usd_24h_change, 2)),
  };
}

async function getPriceAndStoreIt(store, db) {
  if (!store.lastPrice || Date.now() - store.lastPriceGotAt > 3600) {
    const lastPrice = await getSYNRPrice();
    db.update({
      lastPrice,
      lastPriceGotAt: Date.now(),
    });
    db.set();
    return lastPrice;
  } else {
    return store.lastPrice;
  }
}

function explorerUrl(chainId, tx) {
  const url =
    chainId === 1
      ? "etherscan.io"
      : // : chainId === 3
      // ? "ropsten.etherscan.io"
      // : chainId === 5
      // ? "goerli.etherscan.io"
      chainId === 56
      ? "bscscan.com"
      : // : chainId === 97
      // ? "testnet.bscscan.com"
      chainId === 43113
      ? "cchain.explorer.avax-test.network"
      : chainId === 44787
      ? "alfajores-blockscout.celo-testnet.org"
      : undefined;
  return `https://${url}/tx/${tx}`;
}

function cleanStruct(struct) {
  let ret = {};
  for (let key in struct) {
    if (isNaN(parseInt(key))) {
      ret[key] = struct[key];
    }
  }
  return ret;
}

export {
  switchTo,
  decodeMetamaskError,
  getSYNRPrice,
  getPriceAndStoreIt,
  explorerUrl,
  toHex,
  cleanStruct,
};
