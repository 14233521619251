import { ethers } from "ethers";
import config, { isDev, isStage } from "../config";
import { isMobile as mobile } from "react-device-detect";
import { BN } from "./PayloadUtils";
import sha256 from "js-sha256";

const devHash =
  "9ac768d5f0eb34986586fc8a883d677286795f7093a84049b24557cf39b26a29";
const stageHash =
  "166e9038357737c69c67e3cd35acfdf0690d5c37bd7b8f1b9201e29b244b9224";
const coreHash =
  "bab8aa87c9cda931b7e6cd621909d972b721b0051b342202cbd6cf6905c2b889";

function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1);
}

function ellipsizeAddress(address, width = 4) {
  return `${address.slice(0, width + 2)}...${address.slice(-width)}`;
}

function setSection(store, db, s) {
  store.section = s;
  db.update(store);
  db.set();
}

function addSomeDecimals(s, c = 2) {
  s = s.toString().split(".");
  if (!s[0]) {
    s[0] = 0;
  }
  s[1] = (s[1] || "").substring(0, c);
  s[1] = s[1] + "0".repeat(c - s[1].length);
  s = s.join(".");
  return s;
}

function localeFormat(num) {
  if (num === "") {
    return "";
  }
  if (typeof num === "string") {
    num = parseFloat(num);
  }
  return num.toLocaleString(getLang());
}

function enUsFormat(num) {
  return parseFloat(num.toString()).toLocaleString("us-en");
}

function getLang() {
  return navigator.language || navigator.languages[0] || "en-us";
}

async function sleep(millis) {
  // eslint-disable-next-line no-undef
  return new Promise((resolve) => setTimeout(resolve, millis));
}

function minimalisticHash(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

async function addTokenToWallet(chainId, sSynr) {
  if (chainId <= 42) {
    if (sSynr) {
      addERC20ToWallet(
        chainId,
        "SyntheticSyndicateERC20",
        "sSYNR",
        "https://data.mob.land/assets/sSYNR_Icon200x200.png"
      );
    } else {
      addERC20ToWallet(
        chainId,
        "SyndicateERC20",
        "SYNR",
        "https://data.mob.land/assets/SYNR_Icon200x200.png"
      );
    }
  } else {
    addERC20ToWallet(
      chainId,
      "SeedToken",
      "SEED",
      "https://data.mob.land/assets/Seed_Icon200x200.png"
    );
  }
}

async function addERC20ToWallet(chainId, token, symbol, image) {
  const contracts = config.contracts[chainId];
  if (contracts) {
    const address = contracts[token];
    if (typeof window.ethereum !== "undefined") {
      try {
        return window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address,
              symbol,
              decimals: 18,
              image,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
}

function isMobile() {
  return mobile;
}

function bigNumberify(obj) {
  const isObject = (o) => {
    return typeof o === "object" && o !== null && !!Object.keys(o).length;
  };
  const manage = (item, i) => {
    if (isObject(item[i])) {
      if (item[i].type === "BigNumber" && item[i].hex) {
        item[i] = ethers.BigNumber.from(item[i].hex);
      } else {
        item[i] = bigNumberify(item[i]);
      }
    }
  };
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      try {
        manage(obj, i);
      } catch (e) {}
    }
  } else if (isObject(obj)) {
    for (let i in obj) {
      try {
        manage(obj, i);
      } catch (e) {}
    }
  }
  return obj;
}

function parseAnd2Decimals(amount, noDecimals, justString) {
  if (!amount || amount.toString() === "0") {
    return noDecimals ? 0 : "0.00";
  }
  if (/\./.test(amount.toString())) {
    return amount;
  }
  let str = ethers.utils.formatEther(amount.toString());
  if (justString) {
    return str;
  }
  if (noDecimals) {
    return str.split(".")[0];
  }
  return addSomeDecimals(str);
}

function authFilter(location) {
  if (isStage || isDev) {
    let auth = localStorage.getItem("auth");
    const query = new URLSearchParams(location.search);
    let authQuery = query.get("auth");
    if (authQuery) {
      let authHash = sha256(authQuery);
      if (authHash !== auth) {
        localStorage.setItem("auth", authHash);
      }
    }
    auth = localStorage.getItem("auth");
    if (window.location.hostname !== "localhost") {
      if (/jeroyafra/.test(window.location.hostname)) {
        if (auth !== devHash) {
          window.location = "https://staking.mob.land";
        }
      } else if (auth !== stageHash && auth !== coreHash) {
        window.location = "https://staking.mob.land";
      }
    }
    return [true, auth === coreHash];
  }
  return [true];
}

export {
  capitalize,
  ellipsizeAddress,
  addSomeDecimals,
  localeFormat,
  getLang,
  sleep,
  isMobile,
  parseAnd2Decimals,
  addTokenToWallet,
  enUsFormat,
  bigNumberify,
  BN,
  setSection,
  devHash,
  stageHash,
  coreHash,
  authFilter,
  minimalisticHash,
};
