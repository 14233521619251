import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import SeedLightTooltip from "./SeedLightTooltip";

import CircleIcon from "@mui/icons-material/Circle";
import RotateRightIcon from "@mui/icons-material/RotateRight";

function getIndexByProgressAndSteps(progress, steps) {
  if (progress === 0) {
    return 0;
  }
  const part = 100 / (steps - 1); // 5 steps => part = 25
  return parseInt(progress / part); // progress = 64 => return 64/25 = 2
}

function ProgressMoon({ progress, totalSteps, hints }) {
  const steps = [];
  const rotatingIndex = getIndexByProgressAndSteps(progress, totalSteps);
  for (let i = 0; i < totalSteps; i++) {
    steps.push(
      <Step key={"step" + i} transition="scale">
        {({ accomplished, index }) => {
          let circle = (
            <CircleIcon
              fontSize={"inherit"}
              color={accomplished ? "primary" : "secondary"}
            />
          );
          let rotating = (
            <RotateRightIcon
              fontSize={"inherit"}
              color={"info"}
              className={"rotating"}
            />
          );
          return (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
              style={{ fontSize: 24 }}
            >
              {hints && hints[i] ? (
                <SeedLightTooltip title={hints[i]}>
                  {/*TODO use MUI Icons instead*/}
                  {progress !== 100 && index === rotatingIndex ? (
                    <div className={"overlap"}>
                      {circle}
                      {rotating}
                    </div>
                  ) : (
                    circle
                  )}
                </SeedLightTooltip>
              ) : !accomplished ? (
                <div className={"overlap"}>
                  {circle}
                  {rotating}
                </div>
              ) : (
                circle
              )}
            </div>
          );
        }}
      </Step>
    );
  }
  return (
    <ProgressBar filledBackground="#8835EF" percent={progress || 0} height={4}>
      {steps}
    </ProgressBar>
  );
}

export default ProgressMoon;
