import React, { useState, useEffect, useContext } from "react";
import TitleBox from "../modal/TitleBox";
import blueprintSeed from "../../images/Blueprint_SEED.png";
import passSeed from "../../images/SYNRPass_SEED.png";
import BoxButton from "../tile/BoxButton";
import { enUsFormat, isMobile, parseAnd2Decimals } from "../../utils";
import Popup from "../modal/Popup";
import SeedDialogNft from "./SeedDialogNft";
import { tokenTypes } from "../../config";
import BodyBox from "../modal/BodyBox";
import { BN } from "../../utils/PayloadUtils";
import SmallCircularProgress from "../SmallCircularProgress";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
} from "@mui/material";

import { Web3Context } from "../../contexts/Web3Context";
import Loading from "../../images/loading-svgrepo-com.svg";

function EquivalentOrBoostDialog({
  pool,
  opened,
  setOpened,
  stake,
  mainPoolConf,
  sidePoolConf,
  sidePoolExtraConf,
  tokenType,
}) {
  const { web3NetworkDetails } = useContext(Web3Context);
  const [assetTokenType, setAssetTokenType] = useState(tokenType);
  const [open, setOpen] = useState(false);

  const [checked, setChecked] = useState(0);
  const [pendingRewards, setPendingRewards] = useState(false);
  const [dailyRewards, setDailyRewards] = useState(0);
  const [estimatedRewards, setEstimatedRewards] = useState(0);
  const [estimatedBoostPercentage, setEstimatedBoostPercentage] = useState("0");

  useEffect(() => {
    if (pool && checked === 0) {
      readRewards();
    }
    // eslint-disable-next-line
  }, [checked]);

  const readRewards = async () => {
    setChecked(1);
    let rewards = await pool.pendingRewards(web3NetworkDetails);
    setPendingRewards(enUsFormat(parseAnd2Decimals(rewards)));

    let { untaxedDailyRewards, boostedDailyRewards } =
      await pool.boostAndUnboostedPendingDailyRewards(
        web3NetworkDetails,
        tokenType
      );
    setDailyRewards(untaxedDailyRewards);
    setEstimatedRewards(boostedDailyRewards);
    if (boostedDailyRewards.gt(untaxedDailyRewards)) {
      const boostPercentage =
        (boostedDailyRewards.div(BN("10000000000000000")).toNumber() /
          untaxedDailyRewards.div(BN("10000000000000000")).toNumber() -
          1) *
        10000;
      setEstimatedBoostPercentage(
        parseInt(boostPercentage / 100) + "." + (boostPercentage % 100)
      );
    }
    setChecked(2);
  };

  const PendingRewards = (
    <div className={"floatRight pendingRewards light"}>
      Pending Rewards
      <br />
      <b>
        {pendingRewards || (
          <img
            alt={"Loading..."}
            src={Loading}
            style={{ width: 14, height: 14, filter: "invert(1)" }}
          />
        )}
      </b>{" "}
      SEED
    </div>
  );

  const closeDialog = () => {
    setOpened(false);
  };

  function onTokenChoose(type) {
    if (type === "BOOST") {
      if (tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_BOOST) {
        setAssetTokenType(tokenTypes.BLUEPRINT_STAKE_FOR_BOOST);
      } else {
        setAssetTokenType(tokenTypes.SYNR_PASS_STAKE_FOR_BOOST);
      }
    } else {
      if (tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_BOOST) {
        setAssetTokenType(tokenTypes.BLUEPRINT_STAKE_FOR_SEEDS);
      } else {
        setAssetTokenType(tokenTypes.SYNR_PASS_STAKE_FOR_SEEDS);
      }
    }
    setOpen(true);
    // setOpened(false);
  }

  let isPass = tokenType < tokenTypes.BLUEPRINT_STAKE_FOR_BOOST;

  function getContent() {
    const icon = isPass ? passSeed : blueprintSeed;
    const what = isPass ? "SYNR Pass" : "Blueprint";

    return (
      <>
        <TitleBox id="staking-dialog-title" icon={icon}>
          {PendingRewards}
          STAKING {what.toUpperCase()} FOR SEED
        </TitleBox>
        <BodyBox>
          <div className={"centered light bit-smaller"}>
            <p>
              Stake your {what} as a <span className={"bolds"}>Multiplier</span>{" "}
              or as a <span className={"bolds"}>SYNR equivalent</span>.
            </p>
          </div>

          <div className={"nftBox centered light bit-smaller"}>
            <span className={"bolds"}>SYNR Equivalent</span>: Stake your {what}{" "}
            as if you were staking{" "}
            <span className={"bolds"}>
              {sidePoolExtraConf[
                isPass ? "sPSynrEquivalent" : "bPSynrEquivalent"
              ].toLocaleString()}{" "}
              SYNR
            </span>
            <br />
            <BoxButton
              onClick={() => onTokenChoose("EQUIVALENT")}
              buttonText={"Stake as a SYNR equivalent"}
              buttonClassName={"wide"}
            />
          </div>

          <div
            className={"nftBox centered light bit-smaller"}
            style={{ marginBottom: 0 }}
          >
            <span className={"bolds"}>Multiplier</span>: Boost rewards on the{" "}
            <span className={"bolds"}>SYNR & SYNR EQ</span> locked in your{" "}
            <span className={"bolds"}>Generator</span>
            <br />
            <br />
            <TableContainer
              sx={{
                minWidth: isMobile() ? 0 : 560,
                width: isMobile() ? "96%" : "70%",
                margin: "auto",
              }}
              className={"left-container"}
            >
              <Table aria-label="staking pools">
                <TableBody>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      align="center"
                      sx={{ whiteSpace: "nowrap" }}
                      className="tableCell light"
                    >
                      Untaxed Daily Rewards
                      <br />
                      <b>
                        {checked === 2 ? (
                          enUsFormat(parseAnd2Decimals(dailyRewards))
                        ) : (
                          <SmallCircularProgress />
                        )}
                      </b>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ whiteSpace: "nowrap" }}
                      className="tableCell light"
                    >
                      Est. Daily Boost
                      <br />
                      <b>
                        {checked === 2 ? (
                          <>
                            {enUsFormat(
                              parseAnd2Decimals(estimatedBoostPercentage)
                            )}
                            %
                          </>
                        ) : (
                          <SmallCircularProgress />
                        )}
                      </b>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ whiteSpace: "nowrap" }}
                      className="tableCell light"
                    >
                      Est. Boosted Daily Rewards
                      <br />
                      <b>
                        {checked === 2 ? (
                          enUsFormat(parseAnd2Decimals(estimatedRewards))
                        ) : (
                          <SmallCircularProgress />
                        )}
                      </b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <BoxButton
              onClick={() => onTokenChoose("BOOST")}
              buttonText={"Stake as multiplier"}
              buttonClassName={"wide"}
            />
          </div>
        </BodyBox>
      </>
    );
  }

  if (pool) {
    return (
      <>
        <Popup
          onClose={closeDialog}
          show={opened && !open}
          style={{ minWidth: isMobile() ? "90%" : "50%" }}
        >
          {getContent()}
          <div className={"lowerButton"} style={{ paddingTop: "0px" }}></div>
        </Popup>
        {mainPoolConf && mainPoolConf.minimumLockupTime > 0 ? (
          <SeedDialogNft
            pool={pool}
            opened={open}
            setOpen={setOpen}
            setOpened={setOpened}
            stake={stake}
            mainPoolConf={mainPoolConf}
            sidePoolConf={sidePoolConf}
            sidePoolExtraConf={sidePoolExtraConf}
            tokenType={assetTokenType}
          />
        ) : null}
      </>
    );
  }

  return null;
}

export default EquivalentOrBoostDialog;
