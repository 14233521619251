import React, { createContext } from "react";

import CorePool from "./pools/CorePool";
import SeedFarm from "./pools/SeedFarm";

const pools = [CorePool, SeedFarm];

export const PoolContext = createContext();

function PoolContextProvider({ children }) {
  return (
    <PoolContext.Provider
      value={{
        pools,
      }}
    >
      {children}
    </PoolContext.Provider>
  );
}

export default PoolContextProvider;
