import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React from "react";

const SeedLightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#241A31",
    color: "#cbe",
    boxShadow: theme.shadows[1],
    border: "1px solid #4805aF",
    fontSize: 12,
    padding: 16,
    borderRadius: 12,
  },
}));

export default SeedLightTooltip;
