import * as React from "react";
import { Box, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "#220022",
  border: "2px solid #550055",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  const handleClose = () => {
    props.onClose();
  };
  return (
    <Modal
      open={props.show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className={"basic-modal"}>
        <div className={"basic-title"}>{props.title}</div>
        <div className={"basic-body"}>{props.body}</div>
      </Box>
    </Modal>
  );
}
