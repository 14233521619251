import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { addSomeDecimals } from "../utils/index";
import { yellow } from "@mui/material/colors";

function Arrow(props) {
  const value = addSomeDecimals(Math.abs(props.value.usd), 3);
  return (
    <>
      {props.value.change > 0 ? (
        <font style={{ color: yellow[500] }}>
          <ArrowUpwardIcon sx={{ fontSize: "medium" }} />$ {value}
        </font>
      ) : props.value.change < 0 ? (
        <font style={{ color: yellow[500] }}>
          <ArrowDownwardIcon sx={{ fontSize: "medium" }} />$ {value}
        </font>
      ) : (
        <font>{value}%</font>
      )}{" "}
    </>
  );
}
export default Arrow;
