import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const usePageViewTracking = () => {
  const location = useLocation();

  useEffect(() => {
    window.gtag("config", "UA-B8BQXYDK8W", {
      page_path: location.pathname + location.search,
    });
  }, [location]);

  return <span />;
};

export default usePageViewTracking;
