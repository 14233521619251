import { ethers } from "ethers";
import _ from "lodash";
import getRevertReason from "eth-revert-reason";
import {
  toChain,
  wormholeConfig,
  isDev,
  isStage,
  isJero,
  isProd,
  tokenTypes,
  chainToChain,
} from "../../config";
import {
  serializeInput,
  fromDepositToTransferPayload,
} from "../../utils/PayloadUtils";
import {
  boostRewards,
  calculateUntaxedRewards,
} from "../../utils/sidePoolViews";
import { switchTo, cleanStruct } from "../../utils/networkUtils";
import { sleep } from "../../utils";
// import LostTransactionError from "../../components/seedFarm/LostTransactionError";
import CompleteTransactionError from "../../components/seedFarm/CompleteTransactionError";
import Cache from "../Cache";
import {
  TRANSFER_INITIATED,
  TRANSFER_STARTED,
  TRANSFER_MINED,
  SEQUENCE_GOT,
  VAA_BYTES_GOT,
  COMPLETE_TRANSFER_STARTED,
  COMPLETE_TRANSFER_MINED,
  WORMHOLE_RPC_HOSTS,
  WORMHOLE_TESTNET_RPC_HOSTS,
  WORMHOLE_BRIDGE_TYPE,
  // LOST_TRANSACTION,
  REC_TX_FAILED,
  // WEEK,
} from "../../config/constants";

import waitingTexts from "./waitingTexts.json";
import clientApi from "../../utils/ClientApi";
import { bigNumberify } from "../../utils";

const pick = [
  "rewardsFactor",
  "decayInterval",
  "decayFactor",
  "passAmount",
  "synrAmount",
  "minimumLockupTime",
  "earlyUnstakePenalty",
  "maximumLockupTime",
  "poolInitAt",
  "lastRatioUpdateAt",
  "swapFactor",
  "stakeFactor",
  "taxPoints",
  "burnRatio",
  "priceRatio",
  "coolDownDays",
  "status",
  "sPSynrEquivalent",
  "sPBoostFactor",
  "sPBoostLimit",
  "bPSynrEquivalent",
  "bPBoostFactor",
  "bPBoostLimit",
];

function isMainChain(net) {
  return net.chainId === 1 || net.chainId === 44787;
}

function isSideChain(net) {
  return net.chainId === 56 || net.chainId === 43113;
}

function netPrefix(net) {
  return net.chainId === 56 || net.chainId === 1 ? "M_" : "T_";
}

function base64ToUint8Array(base64) {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

function contracts(net) {
  const { contracts } = net;

  const {
    SyndicateERC20,
    SyntheticSyndicateERC20,
    SynCityPasses,
    SynCityCoupons,
    Tesseract,
    MainWormholeBridge,
    SideWormholeBridge,
    MainPool,
    SeedPool,
    SeedToken,
    SidePoolViews,
  } = contracts;

  if (isMainChain(net)) {
    return {
      MainPool,
      SyndicateERC20,
      SyntheticSyndicateERC20,
      SynCityPasses,
      Tesseract,
      MainWormholeBridge,
    };
  } else if (isSideChain(net)) {
    return {
      SynCityCoupons,
      SeedPool,
      SeedToken,
      Tesseract,
      SideWormholeBridge,
      SidePoolViews,
    };
  } else {
    return {};
  }
}

function BN(integer = "0") {
  return ethers.BigNumber.from(integer.toString());
}

async function sideRequest(net, api, method, params, query) {
  const otherChainId = chainToChain[net.chainId];
  const prefix = otherChainId === 1 || otherChainId === 44787 ? "main" : "side";

  let res = await clientApi.request(
    `${prefix}/${api}`,
    method,
    params,
    query,
    undefined,
    {
      "Chain-id": otherChainId,
    }
  );
  if (res.success) {
    return res;
  } else {
    await sleep(500);
    return sideRequest(net, api, method, params, query);
  }
}

async function v1Request(api, method, params, query) {
  return clientApi.request(`${api}`, method, params, query, undefined);
}

let storedCache;

const SeedFarm = {
  id: "seed",
  label: "SEED",
  storedCache,
  netPrefix,
  isSupported: (net) => {
    if (net.chainId && !storedCache) {
      storedCache = new Cache(net.prefix + "seedFarmCache", 1);
    }
    if (!net.contracts.Tesseract) {
      return false;
    }
    return (
      isProd
        ? [1, 56]
        : isStage
        ? [44787, 43113]
        : isJero || isDev
        ? [1, 56, 44787, 43113]
        : []
    ).includes(net.chainId);
  },
  state: {
    initialSet: false,
  },
  BN,
  isMainChain,
  isSideChain,
  contracts,
  saveStore: async (api, method, store) => {
    await v1Request(api, method, store);
    return true;
  },
  switch: async (net, newChainId) => {
    if (newChainId) {
      return await switchTo(newChainId);
    } else {
      const { chainId } = net;
      if (isMainChain(net)) {
        return await switchTo(chainId === 1 ? 56 : 43113);
      } else {
        return await switchTo(chainId === 56 ? 1 : 44787);
      }
    }
  },
  init: async (net) => {
    if (thiz.isSupported(net)) {
      await Promise.all([thiz.mainPoolConf(net)]);
      return true;
    } else {
      return false;
    }
  },
  tvl: async (net) => {
    const { MainPool, SeedPool } = contracts(net);
    const pool = MainPool || SeedPool;
    if (!pool) {
      return {
        main: BN(),
        side: BN(),
      };
    } else {
      let res = await sideRequest(net, "tvl");
      if (isMainChain(net)) {
        return {
          main: await pool.tvl(),
          side: res.tvl,
        };
      } else {
        return {
          main: res.tvl,
          side: await pool.tvl(),
        };
      }
    }
  },
  apy: async (net, amount, lockedTime, setMax) => {
    // TODO
    return 0;
  },
  getConf: async (net) => {
    const [mainPoolConf, sidePoolConf, sidePoolExtraConf] = await Promise.all([
      thiz.mainPoolConf(net),
      thiz.sidePoolConf(net),
      thiz.sidePoolExtraConf(net),
    ]);
    return { mainPoolConf, sidePoolConf, sidePoolExtraConf };
  },
  mainPoolConf: async (net) => {
    let conf = storedCache.getValue(net, "mainPoolConf");
    if (!conf) {
      const { MainPool } = contracts(net);
      if (MainPool) {
        conf = _.pick(await MainPool.conf(), pick);
      } else {
        conf = (await sideRequest(net, "conf")).conf;
      }
      storedCache.updateValue(net, "mainPoolConf", conf);
    }
    return conf;
  },
  sidePoolConf: async (net) => {
    let conf = storedCache.getValue(net, "sidePoolConf");
    if (!conf) {
      const { SeedPool } = contracts(net);
      if (SeedPool) {
        conf = _.pick(await SeedPool.conf(), pick);
      } else {
        conf = (await sideRequest(net, "conf")).conf;
      }
      storedCache.updateValue(net, "sidePoolConf", conf);
    }
    return conf;
  },
  sidePoolExtraConf: async (net) => {
    let extraConf = storedCache.getValue(net, "sidePoolExtraConf");
    if (!extraConf) {
      const { SeedPool } = contracts(net);
      if (SeedPool) {
        extraConf = _.pick(await SeedPool.extraConf(), pick);
      } else {
        extraConf = (await sideRequest(net, "extra-conf")).extraConf;
      }
      storedCache.updateValue(net, "sidePoolExtraConf", extraConf);
    }
    return extraConf;
  },
  getVestedPercentage: async (net, deposit) => {
    const { MainPool, SeedPool, SidePoolViews } = contracts(net);
    let timestamp = Math.floor(Date.now() / 1000);
    let vested;
    if (SeedPool) {
      vested = await SidePoolViews.getVestedPercentage(
        timestamp,
        deposit.lockedFrom,
        deposit.lockedUntil
      );
    } else if (MainPool) {
      vested = await MainPool.getVestedPercentage(
        timestamp,
        deposit.lockedFrom,
        deposit.lockedUntil
      );
    }
    return vested;
  },
  pendingRewards: async (net) => {
    const { SeedPool } = contracts(net);
    let pendingRewards;
    if (SeedPool) {
      pendingRewards = await SeedPool.pendingRewards(net.connectedWallet);
    } else {
      pendingRewards = bigNumberify(
        await sideRequest(net, "pending-rewards")
      ).rewards;
    }
    storedCache.updateValue(net, "pendingRewards", pendingRewards);
    return pendingRewards;
  },
  boostedAndUnboostedPendingRewards: async (net) => {
    const { SidePoolViews, SeedPool } = contracts(net);
    let boosted = BN();
    let untaxed = BN();
    let deposits;
    let conf;
    let extraConf;
    const timestamp = (await net.provider.getBlock()).timestamp;
    // this works only on SideChain. Must add a main version with a server side API
    if (SidePoolViews) {
      const {
        lastRewardsAt,
        stakedAmount,
        passAmountForBoost,
        blueprintAmountForBoost,
      } = bigNumberify(cleanStruct(await SeedPool.users(net.connectedWallet)));
      conf = cleanStruct(await thiz.sidePoolConf(net));
      extraConf = cleanStruct(await thiz.sidePoolExtraConf(net));
      deposits = await thiz.getDeposits(net);

      for (let i = 0; i < deposits.length; i++) {
        untaxed = untaxed.add(
          calculateUntaxedRewards(conf, deposits[i], timestamp, lastRewardsAt)
        );
      }
      boosted = boostRewards(
        extraConf,
        untaxed,
        stakedAmount,
        passAmountForBoost,
        blueprintAmountForBoost
      );
    }
    return { untaxed, boosted };
  },
  boostAndUnboostedPendingDailyRewards: async (net, tokenType) => {
    const { SeedPool } = contracts(net);
    let untaxedDailyRewards;
    let boostedDailyRewards;
    let deposits;
    let conf;
    let extraConf;
    let timestamp = Math.round(Date.now() / 1000);
    let user;
    if (SeedPool) {
      user = cleanStruct(await SeedPool.users(net.connectedWallet));
      deposits = await thiz.getDeposits(net);
    } else {
      const userAccount = await sideRequest(net, "user");
      user = userAccount.user;
      deposits = await thiz.getDeposits(net, true);
    }
    let { stakedAmount, passAmountForBoost, blueprintAmountForBoost } =
      bigNumberify(user);
    conf = cleanStruct(await thiz.sidePoolConf(net));
    extraConf = cleanStruct(await thiz.sidePoolExtraConf(net));
    let untaxed = BN();
    for (let i = 0; i < deposits.length; i++) {
      untaxed = untaxed.add(
        calculateUntaxedRewards(
          conf,
          deposits[i],
          timestamp + 3600 * 24,
          timestamp
        )
      );
    }
    untaxedDailyRewards = boostRewards(
      extraConf,
      untaxed,
      stakedAmount,
      passAmountForBoost,
      blueprintAmountForBoost
    );
    if (tokenType === tokenTypes.SYNR_PASS_STAKE_FOR_BOOST) {
      passAmountForBoost++;
    } else {
      blueprintAmountForBoost++;
    }
    boostedDailyRewards = boostRewards(
      extraConf,
      untaxed,
      stakedAmount,
      passAmountForBoost,
      blueprintAmountForBoost
    );
    return { untaxedDailyRewards, boostedDailyRewards };
  },
  lockedRewards: async (net) => {
    const { SeedPool } = contracts(net);
    let lockedRewards = storedCache.getValue(net, "lockedRewards");
    if (lockedRewards) {
      return lockedRewards;
    }
    if (SeedPool) {
      const user = await SeedPool.users(net.connectedWallet);
      await thiz.sidePoolConf(net);
      lockedRewards = user.tokenAmount;
    } else {
      lockedRewards = bigNumberify(
        await sideRequest(net, "locked-rewards")
      ).rewards;
    }
    storedCache.updateValue(net, "lockedRewards", lockedRewards);
    return lockedRewards;
  },
  tokenBalances: async (net, force) => {
    let tokenBalances = force
      ? undefined
      : storedCache.getValue(net, "tokenBalances");
    if (tokenBalances) {
      return tokenBalances;
    }
    if (isMainChain(net)) {
      const { connectedWallet } = net;
      const { SyndicateERC20, SyntheticSyndicateERC20, SynCityPasses } =
        contracts(net);
      tokenBalances = {
        synrAmount: await SyndicateERC20.balanceOf(connectedWallet),
        sSynrAmount: await SyntheticSyndicateERC20.balanceOf(connectedWallet),
        synrPassAmount: await SynCityPasses.balanceOf(connectedWallet),
      };
    } else {
      if (!thiz.readingBalancesNow) {
        thiz.readingBalancesNow = true;
        tokenBalances = bigNumberify(
          await sideRequest(net, "balances")
        ).balances;
        thiz.readingBalancesNow = false;
      } else {
        let now = Date.now();
        while (!tokenBalances && Date.now() - now < 60000) {
          await sleep(300);
        }
        return storedCache.getValue(net, "tokenBalances");
      }
    }
    storedCache.updateValue(net, "tokenBalances", tokenBalances);
    return storedCache.getValue(net, "tokenBalances");
  },
  sideTokenBalances: async (net, force) => {
    let sideTokenBalances = force
      ? undefined
      : storedCache.getValue(net, "sideTokenBalances");
    if (sideTokenBalances) {
      return sideTokenBalances;
    }
    if (isSideChain(net)) {
      const { connectedWallet } = net;
      const { SeedToken, SynCityCoupons } = contracts(net);
      sideTokenBalances = {
        seedAmount: await SeedToken.balanceOf(connectedWallet),
        blueprintAmount: await SynCityCoupons.balanceOf(connectedWallet),
      };
    } else {
      sideTokenBalances = bigNumberify(
        await sideRequest(net, "balances")
      ).balances;
    }
    storedCache.updateValue(net, "sideTokenBalances", sideTokenBalances);
    return sideTokenBalances;
  },
  stakedBalances: async (net, includeUnlocked, force) => {
    const balances = {
      synr: BN(),
      sSynr: BN(),
      pass: 0,
      bp: 0,
    };
    let deposits = await thiz.getDeposits(
      net,
      isMainChain(net),
      includeUnlocked,
      force
    );
    if (!deposits) {
      return balances;
    }
    deposits = deposits.filter((e) => {
      return includeUnlocked || e.unlockedAt === 0;
    });
    for (let deposit of deposits) {
      deposit = bigNumberify(deposit);
      switch (deposit.tokenType) {
        case tokenTypes.SYNR_STAKE:
          balances.synr = balances.synr.add(deposit.stakedAmount);
          break;
        case tokenTypes.S_SYNR_SWAP:
          balances.sSynr = balances.sSynr.add(deposit.stakedAmount);
          break;
        case tokenTypes.SYNR_PASS_STAKE_FOR_SEEDS:
        case tokenTypes.SYNR_PASS_STAKE_FOR_BOOST:
          balances.pass += 1;
          break;
        case tokenTypes.BLUEPRINT_STAKE_FOR_BOOST:
        case tokenTypes.BLUEPRINT_STAKE_FOR_SEEDS:
          balances.bp += 1;
          break;
        default:
      }
    }
    return balances;
  },
  getDeposits: async (net, onOtherChain, includeUnlocked, force) => {
    const { MainPool, SeedPool } = contracts(net);
    let pool = MainPool || SeedPool;
    let main = isMainChain(net);
    let which = onOtherChain
      ? main
        ? "side"
        : "main"
      : main
      ? "main"
      : "side";
    let deposits = force
      ? undefined
      : storedCache.getValue(net, which + "Deposits");
    if (deposits) {
      return deposits;
    } else {
      deposits = [];
    }
    if (thiz.readingDepositsNow) {
      return false;
    }
    thiz.readingDepositsNow = true;
    if (!pool || onOtherChain) {
      deposits = bigNumberify(
        cleanStruct(await sideRequest(net, "deposits")).deposits
      );
    } else {
      const depositLength = (
        await pool.getDepositsLength(net.connectedWallet)
      ).toNumber();
      for (let i = 0; i < depositLength; i++) {
        let deposit = cleanStruct(
          await pool.getDepositByIndex(net.connectedWallet, i)
        );
        deposits.push(bigNumberify(deposit));
      }
    }
    deposits = deposits.filter((e) => {
      return includeUnlocked || e.unlockedAt === 0;
    });
    storedCache.updateValue(net, which + "Deposits", deposits);
    thiz.readingDepositsNow = false;
    return deposits;
  },
  stakeBlueprint: async (
    net,
    tokenType,
    lockupTime = 0,
    tokenId,
    setLog,
    setProgress
  ) => {
    if (tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_BOOST) {
      lockupTime = 0;
    }
    const { connectedWallet, signer } = net;
    const { SeedPool } = contracts(net);
    if (SeedPool) {
      setProgress(25);
      setLog("Ready to stake your Blueprint Genesis NFT");
      const len = (
        await SeedPool.getDepositsLength(connectedWallet)
      ).toNumber();
      const gasLimit = 350000 + len * 20000;
      setProgress(50);
      const tx = await SeedPool.connect(signer).stake(
        tokenType,
        lockupTime,
        tokenId,
        { gasLimit }
      );
      setProgress(75);
      setLog("Waiting for the transaction to be included in a block");
      await tx.wait();
      setProgress(100);
      setLog(
        `Congratulations, you staked your Blueprint ${
          tokenType === tokenTypes.BLUEPRINT_STAKE_FOR_BOOST
            ? "to boost your rewards"
            : "to generate SEED"
        }`
      );
      storedCache.resetCache(net);
      thiz.getNFTIds(net, true);
      thiz.reloadCache(net);
      return true;
    } else {
      throw new Error("Wrong network");
    }
  },
  unstakeBlueprint: async (net, deposit, setLog, setProgress, setError) => {
    const { tokenType } = deposit;
    const { connectedWallet, signer } = net;
    const { SeedPool } = contracts(net);
    if (SeedPool) {
      setProgress(25);
      if (tokenType >= tokenTypes.BLUEPRINT_STAKE_FOR_BOOST) {
        setLog(`Ready to unstake your Blueprint Genesis NFT`);
      }
      const len = (
        await SeedPool.getDepositsLength(connectedWallet)
      ).toNumber();
      const gasLimit = 350000 + len * 20000;
      setProgress(50);
      const tx = await SeedPool.connect(signer).unstake(deposit, { gasLimit });
      setProgress(75);
      setLog("Waiting for the transaction to be included in a block");
      await tx.wait();
      thiz.getNFTIds(net, true);
      setProgress(100);
      setLog(
        `Congratulations, you ${
          tokenType >= tokenTypes.BLUEPRINT_STAKE_FOR_BOOST
            ? "unstaked your Blueprint"
            : "unlocked your SEED tokens"
        }`
      );
      thiz.reloadCache(net);
      return true;
    } else {
      throw new Error("Wrong network");
    }
  },
  reloadCache: async (net) => {
    if (thiz.isMainChain(net)) {
      await Promise.all([
        thiz.getDeposits(net, undefined, undefined, true),
        thiz.getDeposits(net, true, true, true),
      ]);
    } else {
      await Promise.all([
        thiz.getDeposits(net, undefined, true, true),
        thiz.getDeposits(net, true, undefined, true),
      ]);
    }
    await Promise.all([
      thiz.stakedBalances(net, true),
      thiz.tokenBalances(net, true),
      thiz.sideTokenBalances(net, true),
      thiz.pendingRewards(net, true),
    ]);
  },
  //
  //
  //
  startCrossChainTransfer: async (
    net,
    tokenType,
    lockupTime,
    amount,
    setLog,
    updateTransfer,
    transfer,
    setProgress,
    setError
  ) => {
    const { createNonce, getEmitterAddressEth, parseSequenceFromLogEth } =
      global.wormhole || require("@certusone/wormhole-sdk");

    const { getSignedVAAWithRetry } =
      global.wormhole ||
      require("@certusone/wormhole-sdk/lib/esm/rpc/getSignedVAAWithRetry");

    const { chainId, signer } = net;
    const { Tesseract, MainWormholeBridge, SideWormholeBridge, SeedPool } =
      contracts(net);

    const wormholeBridge = MainWormholeBridge || SideWormholeBridge;
    if (Tesseract) {
      const which = isMainChain(net)
        ? tokenType === tokenTypes.S_SYNR_SWAP
          ? "swapping"
          : "staking"
        : "unstaking";
      let sendTx;

      // work around
      if (!transfer.sendTx && transfer.sendReceipt) {
        transfer.sendTx = transfer.sendReceipt.transactionHash;
        updateTransfer({
          sendTx: transfer.sendTx,
        });
      }

      if (!transfer.sendTx) {
        const payload = transfer.deposit
          ? fromDepositToTransferPayload(transfer.deposit)
          : serializeInput(tokenType, lockupTime, amount);
        setLog(`Start cross-chain ${which}...`);
        try {
          let gasLimit = 350000;
          if (isSideChain(net)) {
            const len = (
              await SeedPool.getDepositsLength(net.connectedWallet)
            ).toNumber();
            gasLimit = 350000 + len * 20000;
          }
          sendTx = await Tesseract.connect(signer).crossChainTransfer(
            WORMHOLE_BRIDGE_TYPE,
            payload,
            toChain[chainId],
            createNonce(),
            { gasLimit }
          );
        } catch (e) {
          setError("Transaction rejected");
          return false;
        }
        transfer = updateTransfer({
          sendTx: sendTx.hash,
          payload: payload.toString(),
          status: TRANSFER_STARTED,
        });
      }
      setProgress(25);
      if (!transfer.sendReceipt) {
        setLog(
          `Cross-chain ${which} started. Waiting for transaction to be mined...`
        );
        try {
          const sendReceipt = sendTx
            ? await sendTx.wait()
            : await net.provider.getTransactionReceipt(transfer.sendTx);

          if (sendReceipt.status === 1) {
            transfer = updateTransfer({
              sendReceipt,
              status: TRANSFER_MINED,
            });
            thiz.reloadCache(net);
          } else {
            updateTransfer({
              sendTx: null,
              status: TRANSFER_INITIATED,
            });
            setError(
              await getRevertReason(
                sendReceipt.transactionHash,
                undefined,
                undefined,
                net.provider
              )
            );
            return;
          }
        } catch (e) {
          transfer = updateTransfer({
            sendTx: null,
            status: TRANSFER_INITIATED,
          });
          setError(
            await getRevertReason(
              transfer.sendTx,
              undefined,
              undefined,
              net.provider
            )
          );
          return;
        }
        if (tokenType >= tokenTypes.SYNR_PASS_STAKE_FOR_BOOST) {
          thiz.getNFTIds(net, true);
        }
      }
      setProgress(38);
      const { sendReceipt } = transfer;

      if (!transfer.sequence) {
        setLog("Getting sequence from Wormhole...");
        const wormholeAddress = await wormholeBridge.wormhole();
        const sequence = parseSequenceFromLogEth(sendReceipt, wormholeAddress);

        transfer = updateTransfer({
          sequence,
          status: SEQUENCE_GOT,
        });
      }

      let done = false;

      function setMessages(step) {
        if (done) {
          return;
        }
        setLog(waitingTexts.texts[step - 1]);
        if (step === waitingTexts.texts.length) {
          step = 1;
        }
        setTimeout(() => setMessages(++step), 20000);
      }

      if (!transfer.vaaBytes) {
        const {
          sequence,
          // attempted
        } = transfer;
        setMessages(1);

        const whChainId = wormholeConfig.byChainId[chainId][0];
        let vaaBytes;
        let gotten = false;
        while (!gotten) {
          const data = await clientApi.request(
            `wormhole/vaa/${whChainId}/${getEmitterAddressEth(
              wormholeBridge.address
            )}/${sequence.toString()}?test=${chainId > 56}`
          );
          if (data.success) {
            vaaBytes = base64ToUint8Array(data.content);
            gotten = true;
          }
          if (!gotten) {
            await sleep(10000);
          }
        }

        // console.log(vaaBytes);
        done = true;
        setLog("VAA received. If nothing happens, refresh the page, please.");
        updateTransfer({
          vaaBytes,
          status: VAA_BYTES_GOT,
        });
      }
      setProgress(50);
      storedCache.cleanValue(net, "starting");
      return true;
    } else {
      storedCache.cleanValue(net, "starting");
      setError("Wrong network");
      return false;
    }
  },
  completeCrossChainTransfer: async (
    net,
    transfer,
    setLog,
    updateTransfer,
    setProgress,
    setError
  ) => {
    // trying to avoid the double request
    await sleep(100 + parseInt(100 * Math.random()));
    if (storedCache.getValue(net, "completing")) {
      return;
    }
    storedCache.cleanValue(net, "completing");
    const { signer, connectedWallet, chainId } = net;
    const { Tesseract, SeedPool, MainPool } = contracts(net);
    const pool = SeedPool || MainPool;
    const { payload, deposit } = transfer;
    const tokenType = payload
      ? parseInt(payload.split``.pop())
      : deposit.tokenType;
    if (Tesseract) {
      const what = isMainChain(net)
        ? tokenType === tokenTypes.S_SYNR_SWAP
          ? "swapping"
          : "staking"
        : "unstaking";

      let recTx;
      let startedNow = false;
      setProgress(75);
      setLog(
        <span>
          Ready to complete the cross-chain {what}.<br />
          <div className={"dont"}>
            <div className="unskewed">DO NOT CLOSE OR REFRESH THE PAGE</div>
          </div>
        </span>
      );
      let gasLimit = 400000;
      if (isSideChain(net)) {
        const len = (await pool.getDepositsLength(connectedWallet)).toNumber();
        gasLimit += len * 20000;
      }
      if (transfer.status === REC_TX_FAILED) {
        setError(
          <CompleteTransactionError
            chainId={chainId}
            transfer={transfer}
            updateTransfer={updateTransfer}
            reason={
              await getRevertReason(
                transfer.recTx,
                undefined,
                undefined,
                net.provider
              )
            }
          />
        );
        setLog("");
        storedCache.cleanValue(net, "completing");
        return;
      } else if (!transfer.recTx) {
        let vaaBytes = [];
        for (let k in transfer.vaaBytes) {
          vaaBytes.push(transfer.vaaBytes[k]);
        }
        vaaBytes = new Int32Array(vaaBytes);
        try {
          recTx = await Tesseract.connect(signer).completeCrossChainTransfer(
            WORMHOLE_BRIDGE_TYPE,
            vaaBytes,
            { gasLimit }
          );
        } catch (e) {
          setError("You rejected the transaction. Refresh to try again.");
          setLog("");
          storedCache.cleanValue(net, "completing");
          return;
        }
        transfer = updateTransfer({
          recTx: recTx.hash,
          status: COMPLETE_TRANSFER_STARTED,
        });
        startedNow = true;
      }
      setProgress(88);
      if (!transfer.recReceipt) {
        setLog(
          <span>
            Cross chain process almost completed.
            <br />
            <div className={"dont"}>
              <div className="unskewed">DO NOT CLOSE OR REFRESH THE PAGE</div>
            </div>
          </span>
        );
        let recReceipt;
        try {
          recReceipt = startedNow
            ? await recTx.wait()
            : await net.provider.getTransactionReceipt(transfer.recTx);
          if (recReceipt.status === 1) {
            updateTransfer({
              recReceipt,
              status: COMPLETE_TRANSFER_MINED,
            });
            thiz.reloadCache(net);
          } else {
            storedCache.cleanValue(net, "completing");
            updateTransfer({
              status: REC_TX_FAILED,
              recTx: null,
              gasLimit,
            });
            setError(
              <CompleteTransactionError
                chainId={chainId}
                transfer={transfer}
                updateTransfer={updateTransfer}
                reason={
                  await getRevertReason(
                    transfer.recTx,
                    undefined,
                    undefined,
                    net.provider
                  )
                }
              />
            );
            return;
          }
        } catch (e) {
          storedCache.cleanValue(net, "completing");
          updateTransfer({
            status: REC_TX_FAILED,
            recTx: null,
            gasLimit,
          });
          setError(
            <CompleteTransactionError
              chainId={chainId}
              transfer={transfer}
              updateTransfer={updateTransfer}
              reason={
                await getRevertReason(
                  transfer.recTx,
                  undefined,
                  undefined,
                  net.provider
                )
              }
            />
          );
          return;
        }
        setProgress(100);
        return true;
      } else {
        storedCache.cleanValue(net, "completing");
        setError("Wrong network");
        return false;
      }
    }
  },

  collectUnlockedRewards: async (net, setLog, setError, setProgress) => {
    // before calling this, must switch to side chain
    const { signer } = net;
    const { SeedPool } = contracts(net);
    if (SeedPool) {
      setLog("Ready to claim your SEEDs");
      setProgress(50);
      const tx = await SeedPool.connect(signer).collectRewards();
      setLog("Waiting for transaction to included in a block");
      setProgress(75);
      await tx.wait();
      setLog("Congratulation. You get your SEED");
      setProgress(100);
      storedCache.cleanValue(net, "pendingRewards");
      return tx;
    } else {
      setError("Wrong network");
      return null;
    }
  },

  getNFTIds: async (net, force, onFind = () => {}) => {
    const { connectedWallet } = net;
    let idsPass = [];
    let idsCoupons = [];
    let passIds = storedCache.getValue(net, "passIds");
    let bpIds = storedCache.getValue(net, "bpIds");
    if ((!passIds || force) && isMainChain(net)) {
      const { SynCityPasses } = contracts(net);
      const passBalance = (
        await SynCityPasses.balanceOf(connectedWallet)
      ).toNumber();
      const dataPasses = await clientApi.request(
        "assets/get-my-nfts",
        "get",
        null,
        { contract: "SynCityPasses" }
      );
      if (dataPasses.owned.length === passBalance) {
        for (let x in dataPasses.owned) {
          if (dataPasses.owned[x].locked === false) {
            idsPass.push(dataPasses.owned[x].tokenId);
          }
          onFind(idsPass);
        }
      } else {
        const params = { wallet: connectedWallet };
        const data = await clientApi.request("nft/passes", "get", null, params);
        for (let x in data.content) {
          idsPass.push(data.content[x].tokenId);
          onFind(idsPass);
        }
      }
      passIds = idsPass;
      storedCache.updateValue(net, "passIds", passIds);
    }
    if ((!bpIds || force) && isSideChain(net)) {
      const { SynCityCoupons } = contracts(net);
      const dataCoupons = await clientApi.request(
        "assets/get-my-nfts",
        "get",
        null,
        { contract: "SynCityCoupons" }
      );
      const blueprintBalance = (
        await SynCityCoupons.balanceOf(connectedWallet)
      ).toNumber();
      if (dataCoupons.owned.length === blueprintBalance) {
        for (let x in dataCoupons.owned) {
          if (dataCoupons.owned[x].locked === false) {
            idsCoupons.push(dataCoupons.owned[x].tokenId);
          }
          onFind(idsCoupons);
        }
      } else {
        for (let i = 0; i < blueprintBalance; i++) {
          idsCoupons.push(
            (
              await SynCityCoupons.tokenOfOwnerByIndex(connectedWallet, i)
            ).toNumber()
          );
          onFind(idsCoupons);
        }
      }
      bpIds = idsCoupons;
      storedCache.updateValue(net, "bpIds", bpIds);
    }
    if (isMainChain(net)) {
      return passIds;
    } else if (isSideChain(net)) {
      return bpIds;
    }
  },
};

const thiz = SeedFarm;

export default SeedFarm;
