import { explorerUrl } from "../../utils/networkUtils";
import { SKIP } from "../../config/constants";
import ErrorAlert from "../../images/errorAlert.png";
import BodyBox from "../modal/BodyBox";

function CompleteTransactionError({
  chainId,
  transfer,
  updateTransfer,
  reason,
}) {
  return (
    <BodyBox className={"errorBox centered"}>
      <div className={"errorIcon"}>
        <img src={ErrorAlert} alt={"error alert"} />
      </div>
      <div style={{ textAlign: "left" }}>
        <p>Unfortunately, the transaction failed with the following reason:</p>
        <p>{reason || "Fail"}</p>
        <p>
          <a
            href={explorerUrl(chainId, transfer.recTx)}
            rel="noreferrer"
            target={"_blank"}
            style={{ color: "#FFEE00" }}
            className={"ubuntu-mono bit-smaller"}
          >
            {explorerUrl(chainId, transfer.recTx).substring(0, 60)}...
          </a>
        </p>
        <p>
          Most likely, there's been an issue with the bridge. Please, contact{" "}
          <a
            href={"https://discord.gg/mobland"}
            target={"_blank"}
            rel="noreferrer"
            style={{ color: "#FFEE00" }}
          >
            Mobland support
          </a>
          , letting them know the transaction above.
        </p>
        <p>Sorry for the inconvenient. Thanks!</p>
        <p className={"underError"}>
          <span>
            I saved the transaction,{" "}
            <span
              className={"command"}
              onClick={() => {
                updateTransfer({ status: SKIP });
                window.location.reload();
              }}
            >
              hide this error
            </span>{" "}
            and allow me to do other operations.
          </span>
        </p>
      </div>
    </BodyBox>
  );
}

export default CompleteTransactionError;
