import React, { useContext, useState } from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import SynrPile from "../../images/SYNR_Pile.png";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Web3Context } from "../../contexts/Web3Context";
import { WEEK } from "../../config/constants";

import {
  isMobile,
  parseAnd2Decimals,
  addSomeDecimals,
  enUsFormat,
} from "../../utils";
import { title } from "../../config";
import CoreLightTooltip from "./CoreLightTooltip";

import Popup from "../modal/Popup";
import TitleBox from "../modal/TitleBox";
import BoxButton from "../tile/BoxButton";
import BodyBox from "../modal/BodyBox";

import "../../styles/video-react.css";
// import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
// import config from "../../config";
import coin from "../../images/SYNR_Forward.png";

function UnstakingDialog({ pool, opened, setOpened, unstake, pendingRewards }) {
  const [useSsyn, setUseSsyn] = useState(true);
  // eslint-disable-next-line
  const [unstakeNow, setUnstakeNow] = useState(false);

  const closeDialog = () => {
    setUnstakeNow(false);
    setOpened(false);
  };

  const onStakeClick = async (ev) => {
    setUnstakeNow(false);
    setOpened(false);
    unstake(0, useSsyn);
  };

  return (
    <Popup
      onClose={closeDialog}
      show={opened}
      style={{ minWidth: isMobile() ? "90%" : "50%" }}
    >
      <TitleBox id="staking-dialog-title" icon={SynrPile}></TitleBox>
      {!!pool ? pool.label : ""}
      <BodyBox>
        <div className={"overRadio"}>
          <Typography align="left">
            <span className={"light"}>
              Unstaking your SYNR, will automatically process your pending
              rewards.
            </span>
          </Typography>
          <Typography align="left" style={{ paddingTop: 15 }}>
            <span className={"light"}>Rewards:</span>{" "}
            <span className={"bold"}>
              {enUsFormat(parseAnd2Decimals(pendingRewards))} SYNR
            </span>
          </Typography>

          <div className="underRadio">
            <label htmlFor={"useSsyn"} className={"radio-form-control"}>
              <input
                type={"radio"}
                id={"useSsyn"}
                checked={useSsyn}
                onChange={() => setUseSsyn(true)}
                value={true}
              />

              <div className={"overLabel"}>
                Receive rewards in sSYNR - immediate access
                <CoreLightTooltip
                  title={`sSYNR rewards are sent immediately to your wallet. You can use sSYNR to buy assets in the ${title} marketplace.`}
                  className={"smallTooltip"}
                >
                  <HelpOutlineIcon
                    style={{
                      position: "relative",
                      top: 6,
                      left: 4,
                    }}
                  />
                </CoreLightTooltip>
              </div>
            </label>
            <label htmlFor={"useSyn"} className={"radio-form-control"}>
              <input
                type={"radio"}
                id={"useSyn"}
                checked={!useSsyn}
                onChange={() => setUseSsyn(false)}
                value={true}
              />
              <div className={"overLabel"}>
                Receive rewards in SYNR — locked for 12 months
                <CoreLightTooltip
                  title="SYNR rewards are subject to a 12 month vesting period. Though, while they are vesting, they compound your rewards."
                  className={"smallTooltip"}
                >
                  <HelpOutlineIcon
                    style={{
                      position: "relative",
                      top: 6,
                      left: 4,
                    }}
                  />
                </CoreLightTooltip>
              </div>
            </label>
          </div>
        </div>
        <div className={"lowerButton"}>
          <BoxButton
            onClick={onStakeClick}
            buttonText={"Unstake"}
            className={"bottomButton"}
          />
        </div>
      </BodyBox>
    </Popup>
  );
}

function calculateLockWeight(deposit) {
  const diff = deposit.lockedUntil.sub(deposit.lockedFrom).toNumber();
  let weeks = Math.round(diff / (24 * 7 * 3600));
  let factor = 1 / 52;
  return addSomeDecimals(1 + weeks * factor);
}

const styles = {
  buttonStyle: {
    backgroundColor: "#00000060",
    color: "#FFEE00",
    fontSize: "95%",
  },
  body: {
    maxHeight: 140,
    borderRadius: 20,
    backgroundColor: "transparent !important",
    backgroundImage: "none",
  },
};

function VestingDialog({
  pool,
  opened,
  setOpened,
  deposits,
  setDeposits,
  setMessage,
  setError,
  setOk,
  resetMessageDialog,
  error,
  pendingRewards,
  setFlowNumber,
  flowNumber,
}) {
  const { web3NetworkDetails } = useContext(Web3Context);
  // eslint-disable-next-line
  const [unstakingNow, setUnstakingNow] = useState(false);
  // eslint-disable-next-line
  const [stakingType, setStakingType] = useState("locked");
  const [minLockTime, setMinLockTime] = useState(16);
  const [unstakingOpened, setUnstakingOpened] = useState(false);
  const [activeDeposit, setActiveDeposit] = useState(false);

  const makeStakeClickHandler = (deposit) => {
    setUnstakingOpened(true);
    setActiveDeposit(deposit);
  };

  const closeDialog = () => {
    setOpened(false);
    setStakingType("locked");
  };

  const convertInDays = (ts) => {
    const sec = secondsToUnlock(ts);
    if (sec) {
      if (sec / (24 * 3600) > 1) {
        return Math.round(sec / (24 * 3600)) + " DAYS";
      } else if (sec / 3600 > 1) {
        return Math.round(sec / 3600) + " HOURS";
      } else {
        return Math.round(sec / 60) + " MINUTES";
      }
    } else {
      return "—";
    }
  };

  const secondsToUnlock = (ts) => {
    const now = parseInt(Date.now() / 1000);
    if (ts > now) {
      return ts - now;
    } else {
      return 0;
    }
  };

  function getTotal(deposits) {
    let total = 0;
    for (let i in deposits) {
      total = total + Number(parseAnd2Decimals(deposits[i].tokenAmount));
    }
    return total.toLocaleString("en-US");
  }
  let k = 0;

  function getTable(deposits, type) {
    let some = false;
    for (let deposit of deposits) {
      if (deposit.isYield === type) {
        some = true;
        break;
      }
    }
    if (!some) {
      return null;
    }
    return (
      <>
        {type ? (
          <TitleBox id="staking-dialog-title" style={{ padding: "8px 24px" }}>
            Locked Rewards
          </TitleBox>
        ) : (
          <TitleBox id="staking-dialog-title" style={{ padding: "8px 24px" }}>
            <div
              align="right"
              style={{
                float: "right",
                maxWidth: "50%",
                fontSize: "0.8rem",
                paddingTop: 4,
                textTransform: "none",
              }}
              className={"light"}
            >
              Current balance:{" "}
              <span className={"bold"}> {getTotal(deposits)}</span>
            </div>
            Your Deposits
          </TitleBox>
        )}
        <div className={"over-left-container " + (type ? "typed" : "")}>
          <TableContainer
            component={Paper}
            sx={{
              minWidth: isMobile() ? 0 : 560,
              width: isMobile() ? "96%" : "70%",
              margin: "auto",
              boxShadow: "none",
            }}
            style={styles.body}
            className={"left-container"}
          >
            <Table aria-label="staking pools">
              <TableHead>
                <TableRow>
                  <TableCell className="tableCell" align="center">
                    Asset
                  </TableCell>
                  <TableCell className="tableCell" align="center">
                    Amount
                  </TableCell>
                  <TableCell className="tableCell" align="center">
                    Weight
                  </TableCell>
                  <TableCell className="tableCell" align="center">
                    Locking Period
                  </TableCell>
                  <TableCell className="tableCell" align="left">
                    &nbsp;
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deposits.map((deposit) => {
                  if (
                    type !== deposit.isYield ||
                    deposit.tokenAmount.toString() === "0"
                  ) {
                    return null;
                  } else {
                    return (
                      <TableRow
                        key={"deposit" + k++}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="tableCell"
                        >
                          <div style={{ display: "inline-flex" }}>
                            <img
                              src={coin}
                              alt={"coin"}
                              style={{
                                height: 20,
                                float: "left",
                                marginRight: 6,
                              }}
                            />
                            {isMobile() ? "" : "SYNR"}
                          </div>
                        </TableCell>
                        <TableCell align="center" className="tableCell">
                          {enUsFormat(parseAnd2Decimals(deposit.tokenAmount))}
                        </TableCell>
                        <TableCell align="center" className="tableCell">
                          {calculateLockWeight(deposit)}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ whiteSpace: "nowrap" }}
                          className="tableCell"
                        >
                          {convertInDays(deposit.lockedUntil.toNumber()) === "—"
                            ? " Unlocked"
                            : convertInDays(deposit.lockedUntil.toNumber())}
                        </TableCell>
                        <TableCell align="center" className="tableCell">
                          <BoxButton
                            onClick={() => makeStakeClickHandler(deposit)}
                            disabled={
                              secondsToUnlock(deposit.lockedUntil.toNumber()) >
                              0
                            }
                            buttonText={"UNSTAKE"}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <UnstakingDialog
            pool={pool}
            opened={unstakingOpened}
            setOpened={setUnstakingOpened}
            unstake={unstake}
            pendingRewards={pendingRewards}
          />
        </div>
      </>
    );
  }

  const unstake = async (amount, useSsyn) => {
    setError("");
    const depositId = activeDeposit.id;

    // TODO Manage it as a variable amount
    amount = activeDeposit.tokenAmount;

    try {
      setUnstakingNow(true);
      setMessage("Unstaking your SYNR. Please wait...");
      await pool.unstake(web3NetworkDetails, depositId, amount, useSsyn);
      setDeposits(await pool.getDeposits(web3NetworkDetails, true));
      setUnstakingNow(false);
      setMessage("Unstake completed successfully");
      setOk("Close");
      setFlowNumber(flowNumber + 1);
    } catch (e) {
      setUnstakingNow(false);
      if (/cannot estimate gas/.test(e.message)) {
        setError(
          "Your wallet can't estimate the gas required by the transaction"
        );
      } else {
        setError(e.message);
      }
    }
  };

  if (pool) {
    (async () => {
      setMinLockTime(
        parseInt(await pool.minLockTime(web3NetworkDetails)) / WEEK
      );
    })();
  }

  if (pool && minLockTime) {
    if (deposits.length === 0) {
      return (
        <Popup
          onClose={closeDialog}
          show={opened}
          style={{ minWidth: isMobile() ? "90%" : "50%" }}
        >
          <TitleBox id="staking-dialog-title">
            You have no Deposits or Locked Rewards
          </TitleBox>
        </Popup>
      );
    }
    return (
      <Popup
        onClose={closeDialog}
        show={opened}
        style={{
          minWidth: isMobile() ? "99%" : "50%",
          width: isMobile() ? "99%" : null,
        }}
      >
        {getTable(deposits, false)}
        {getTable(deposits, true)}
      </Popup>
    );
  }

  return null;
}

export default VestingDialog;
