import { bigNumberify } from "../utils";
import _ from "lodash";
import { TRANSFER_INITIATED, TRANSFER_STARTED } from "../config/constants";

export default class Db {
  key(k) {
    return k.replace(/^_ML_(|M_|T_)/, "");
  }

  isKey(k) {
    return /_ML_/.test(k);
  }

  constructor(setStore) {
    this.setStore = setStore;
    this.store = {};
    let store = {};
    const keys = Object.keys(localStorage);
    for (let key of keys) {
      if (this.isKey(key)) {
        store[this.key(key)] = JSON.parse(localStorage.getItem(key));
      }
    }
    if (!store.nextId) {
      store.nextId = 1;
    }
    const section = window.location.pathname.split("/")[1];
    store.section = section || "core";
    if (!store.M_transfers) {
      store.M_transfers = {};
    }
    if (!store.T_transfers) {
      store.T_transfers = {};
    }
    if (!store.lastPrice) {
      store.lastPrice = 0;
      store.lastPriceGotAt = 0;
    }
    store = bigNumberify(store);
    this.update(store);
    this.set();
  }

  get(key) {
    return this.store[key];
  }

  set() {
    this.setStore(this.store);
  }

  update(obj) {
    for (let key in obj) {
      this.updateOne(key, obj[key]);
    }
  }

  updateOne(key, val) {
    this.store[key] = val;
    localStorage.setItem("_ML_" + key, JSON.stringify(val));
  }

  updateItem(key, props) {
    let item = _.clone(this.store[key]) || {};
    if (!item) {
      item = {};
    }
    for (let k in props) {
      if (props[k] === null) {
        delete item[k];
      } else {
        item[k] = props[k];
      }
    }
    this.updateOne(key, item);
  }

  initTransfers() {
    const { prefix, connectedWallet } = this.store;
    const transfer = this.store[prefix + "transfers"];
    if (!transfer[connectedWallet]) {
      transfer[connectedWallet] = {
        main: 0,
        side: 0,
      };
    }
    let updates = {};
    updates[prefix + "transfers"] = transfer;
    this.update(updates);
    this.set();
  }

  getTransferId(type) {
    const { prefix, connectedWallet } = this.store;
    return this.store[prefix + "transfers"][connectedWallet][type];
  }

  getTransfer(type, withKey) {
    const { prefix } = this.store;
    const bridge = this.getTransferId(type);
    if (bridge !== 0) {
      const key = prefix + "transfer_" + type + "_" + bridge;
      const transfer = bigNumberify(this.store[key]);
      if (withKey) {
        return {
          key,
          transfer,
        };
      } else {
        return transfer;
      }
    }
  }

  newTransfer(type, deposit, sendTx, recoveredPayload) {
    const { chainId, nextId, connectedWallet, prefix } = this.store;
    const transfers = this.store[prefix + "transfers"];
    const transfer = {
      wallet: connectedWallet,
      testnet: chainId === 44787 || chainId === 43113,
      status: sendTx ? TRANSFER_STARTED : TRANSFER_INITIATED,
      id: nextId,
      deposit,
      type,
      sendTx,
      payload: recoveredPayload,
    };
    transfers[connectedWallet][type] = nextId;
    const data = {
      nextId: nextId + 1,
    };
    data[prefix + "transfers"] = transfers;
    const key = prefix + "transfer_" + type + "_" + nextId;
    data[key] = transfer;
    this.update(data);
    this.set();
    return transfer;
  }

  updateTransfer(type, props) {
    const { key } = this.getTransfer(type, true);
    this.updateItem(key, props);
    this.set();
  }
}
