import React from "react";

import kucoin from "../images/kucoin.png";
import uniswap from "../images/uniswap.png";
import bybit from "../images/bybit.png";
import someb from "../images/some-b.png";
import UnderTilesNFT from "./UnderTilesNft";

function UnderTiles({ justFT }) {
  return (
    <div className={"centered under-tiles"}>
      <div>No tokens? SYNR is available on these platforms:</div>
      <div className={"under-tiles-icons"}>
        <a
          href={
            "https://www.kucoin.com/trade/SYNR-USDT?spm=kcWeb.B5markets.tradeList.1"
          }
        >
          <img src={kucoin} alt={"Kucoin"} />
        </a>
        <a href={"https://www.bybit.com/en-US/trade/spot/SYNR/USDT"}>
          <img src={bybit} alt={"ByBit"} />
        </a>
        <a
          href={
            "https://www.bitmart.com/trade/en?layout=basic&symbol=SYNR_USDT"
          }
        >
          <img src={someb} alt={"Bitmart"} />
        </a>
        <a href={"https://app.uniswap.org/#/swap?chain=mainnet"}>
          <img src={uniswap} alt={"Uniswap"} />
        </a>
        {justFT ? null : <UnderTilesNFT />}
      </div>
    </div>
  );
}

export default UnderTiles;
