import { addTokenToWallet } from "../utils";
import React, { useContext } from "react";
import { Web3Context } from "../contexts/Web3Context";

function Add() {
  const { web3NetworkDetails } = useContext(Web3Context);
  const { chainId, connectedNetwork } = web3NetworkDetails;
  return (
    <div>
      {connectedNetwork ? (
        chainId === 42 || chainId === 1 || chainId === 44787 ? (
          <div className={"addSynr"}>
            Add{" "}
            <span
              className={"command"}
              onClick={() => addTokenToWallet(chainId, false)}
            >
              SYNR
            </span>{" "}
            and{" "}
            <span
              className={"command"}
              onClick={() => addTokenToWallet(chainId, true)}
            >
              sSYNR
            </span>{" "}
            to your wallet
          </div>
        ) : chainId === 56 || chainId === 43113 ? (
          <div className={"addSynr"}>
            Add{" "}
            <span
              className={"command"}
              onClick={() => addTokenToWallet(chainId, false)}
            >
              SEED
            </span>{" "}
            to your wallet
          </div>
        ) : null
      ) : null}
    </div>
  );
}
export default Add;
