import React from "react";
import Link from "@mui/material/Link";
import logo from "../images/M.png";
/**
 * Copyright text and link for page footer.
 */
function Copyright(props) {
  return (
    <div className={"centered"}>
      <img alt={"logo"} src={logo} style={{ width: 44, marginBottom: 8 }} />
      {props.children}
      <div className={"footerNote"}>
        <Link color="inherit" href={"https://mob.land"}>
          Copyright © 2022 MOBLAND - All right reserved
        </Link>
      </div>
    </div>
  );
}

export default Copyright;
