import React, { useState, useContext, useEffect } from "react";

import "../../styles/video-react.css";
// import { Link } from "react-router-dom";
// import config from "../../config";
import Switch from "../Switch";
import { PoolContext } from "../../contexts/PoolContext";
import { Web3Context } from "../../contexts/Web3Context";
import VestingDialogue from "./VestingDialogue";

import BoxButton from "../tile/BoxButton";

import MessageDialog from "../modal/MessageDialog";

function VestingButton({
  flowNumber,
  setFlowNumber,
  setTotaldeposits,
  setLockedRewards,
}) {
  let { web3NetworkDetails } = useContext(Web3Context);

  let { pools } = useContext(PoolContext);
  const pool = pools[0];
  const [poolSet, setPoolSet] = useState(false);
  const [isSupported, setIsSupported] = useState(1);
  const [vestingOpened, setVestingOpened] = useState(false);
  const [pendingRewards, setPendingRewards] = useState(0);
  const [myFlowNumber, setMyFlowNumber] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [activeDeposit, setActiveDeposit] = useState(false);
  const [deposits, setDeposits] = useState(false);

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [ok, setOk] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");

  const resetMessageDialog = () => {
    setDialogTitle("");
    setMessage("");
    setOk("");
    setError("");
  };

  useEffect(() => {
    if (!poolSet || flowNumber !== myFlowNumber) {
      setPoolSet(true);
      if (web3NetworkDetails.connectedNetwork) {
        (async () => {
          let deps = await pool.getDeposits(web3NetworkDetails, true);
          setPendingRewards(await pool.pendingRewards(web3NetworkDetails));
          setDeposits(deps);
          setIsSupported(2);

          let depositAmount = 0;
          let rewardAmount = 0;
          for (let i in deps) {
            if (deps[i].isYield) {
              rewardAmount++;
            } else {
              depositAmount++;
            }
          }
          setTotaldeposits(depositAmount);
          setLockedRewards(rewardAmount);
        })();
      }
      setMyFlowNumber(flowNumber);
    }
    // eslint-disable-next-line
  }, [flowNumber, myFlowNumber, poolSet]);

  const vestingClickHandler = () => {
    setVestingOpened(true);
  };

  let styles = {
    buttonStyle: {
      backgroundColor: "#ffffff20",
      fontSize: "95%",
      borderColor: "#660",
      borderWidth: 1,
      color: pendingRewards === "0" ? "#990" : "#FFEE00",
    },
    body: { backgroundColor: "transparent" },
  };

  if (!isSupported) {
    return (
      <>
        <div className={"centeredError"}>
          <Switch section={"core"} />
        </div>
      </>
    );
  } else if (!poolSet || deposits === false) {
    styles.buttonStyle.color = "#FFEE00";
    return (
      <BoxButton
        disabled={
          //disabled
          true
        }
        buttonText={"Vesting"}
      />
    );
  } else {
    deposits.sort((a, b) => {
      a = a.lockedUntil.toNumber();
      b = b.lockedUntil.toNumber();
      return a > b ? 1 : a < b ? -1 : 0;
    });

    const disabled = deposits.length === 0;

    return (
      <>
        <BoxButton
          onClick={vestingClickHandler}
          disabled={disabled}
          buttonText={"Vesting"}
        />
        <VestingDialogue
          pool={pool}
          opened={vestingOpened}
          setOpened={setVestingOpened}
          deposits={deposits}
          setDeposits={setDeposits}
          setMessage={setMessage}
          setError={setError}
          setOk={setOk}
          resetMessageDialog={resetMessageDialog}
          error={error}
          pendingRewards={pendingRewards}
          flowNumber={flowNumber}
          setFlowNumber={setFlowNumber}
        />
        <MessageDialog
          message={message}
          error={error}
          ok={ok}
          title={dialogTitle}
          opened={!!(message || error)}
          onClose={resetMessageDialog}
          borderColor={"#ffee00"}
          color={"#ffee00"}
        />
      </>
    );
  }
}

export default VestingButton;
