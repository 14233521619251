import React, { useContext, useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
// import config from "../../config";
import { ethers } from "ethers";
import Switch from "../Switch";
import {
  // localeFormat,
  parseAnd2Decimals,
  enUsFormat,
  // str,
} from "../../utils";

import StakingDialog from "./StakingDialog";
import { PoolContext } from "../../contexts/PoolContext";
import { Web3Context } from "../../contexts/Web3Context";
import { approveAmount, isApproved } from "../../utils/poolUtils";

import BoxButton from "../tile/BoxButton";

import MessageDialog from "../modal/MessageDialog";

// import PendingRewards from "./PendingRewards";
// import ls from "local-storage";

function StakingButton({ flowNumber, setFlowNumber, setBalance }) {
  let { web3NetworkDetails, tokenBalance } = useContext(Web3Context);

  const { pools } = useContext(PoolContext);
  const pool = pools[0];
  const [poolSet, setPoolSet] = useState(false);
  // const [detailsOpened, setDetailsOpened] = useState(false);
  const [stakingOpened, setStakingOpened] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [ok, setOk] = useState("");
  const [stakingNow, setStakingNow] = useState(false);
  const [approvalNeeded, setApprovalNeeded] = useState(false);
  const [myFlowNumber, setMyFlowNumber] = useState(0);
  // const [stakingDone, setStakingDone] = useState(false);
  const [isSupported, setIsSupported] = useState(1);
  // const [maxAPY, setMaxAPY] = useState("");
  // const [tvl, setTvl] = useState("");
  // const [liquidity, setLiquidity] = useState(0);
  // const [pendingRewards, setPendingRewards] = useState(0);

  const [readingBalance, setReadingBalance] = useState(0);
  const [availableSynr, setAvailableSynr] = useState(0);
  const [synrBalance, setSynrBalance] = useState([]);
  // let pendingRewards;

  function readBalance() {
    let synrAmount = -1;
    if (!readingBalance) {
      setSynrBalance([<CircularProgress />]);
      setReadingBalance(1);
      (async () => {
        let deposits = await pool.getDeposits(web3NetworkDetails);
        let staked = ethers.BigNumber.from(0);
        if (deposits) {
          for (let deposit of deposits) {
            staked = staked.add(deposit.tokenAmount);
          }
        }
        synrAmount = await tokenBalance(
          web3NetworkDetails.connectedWallet,
          undefined,
          true
        );
        setAvailableSynr(parseFloat(parseAnd2Decimals(synrAmount)));
        setSynrBalance([
          <b>Your balance</b>,
          enUsFormat(parseAnd2Decimals(synrAmount)) + " SYNR",
        ]);
        setBalance(synrAmount);
        setReadingBalance(2);
      })();
    }
  }

  // const styles = {
  //   buttonStyle: {
  //     backgroundColor: "#ffffff20",
  //     color: approvalNeeded || stakingNow ? "#990" : "#FFEE00",
  //     fontSize: "95%",
  //     borderColor: "#660",
  //     borderWidth: 1,
  //   },
  //   body: { backgroundColor: "transparent" },
  // };
  //
  // const makeDetailsClickHandler = (pool) => {
  //   return () => {
  //     selectPool(pool);
  //     setDetailsOpened(true);
  //   };
  // };

  const resetMessageDialog = () => {
    setDialogTitle("");
    setMessage("");
    setOk("");
    setError("");
  };

  const stake = async (
    pool,
    stakedAmount,
    lockPeriod,
    tokenBalance,
    useSsyn
  ) => {
    resetMessageDialog();
    try {
      let amount = ethers.utils.parseEther(stakedAmount.toString());
      let approved = await isApproved(web3NetworkDetails, amount);
      if (!approved) {
        setApprovalNeeded(true);
        setDialogTitle("Spend approval");
        setMessage(
          "To stake your SYNR you must approve the pool to spend them"
        );
        await approveAmount(
          web3NetworkDetails,
          amount,
          undefined,
          undefined,
          setMessage
        );
      }
      approved = await isApproved(web3NetworkDetails, amount);
      if (approved) {
        setApprovalNeeded(false);
        setStakingNow(true);
        setDialogTitle("Staking your SYNR");
        setMessage("Please, confirm the stake");
        await pool.stake(
          stakedAmount,
          lockPeriod,
          web3NetworkDetails,
          tokenBalance,
          useSsyn,
          setMessage
        );
        setMessage("Stake completed successfully");
        setOk("Close");
        setStakingNow(false);
        setFlowNumber(flowNumber + 1);
      } else {
        throw new Error("Approval not given");
      }
    } catch (e) {
      setMessage("");
      setOk("");
      setApprovalNeeded(false);
      setStakingNow(false);
      setError(e.message);
    }
  };

  const makeStakeClickHandler = () => {
    setStakingOpened(true);
  };

  useEffect(() => {
    if (!poolSet || flowNumber !== myFlowNumber) {
      setPoolSet(true);
      if (web3NetworkDetails.connectedNetwork) {
        (async () => {
          if (await pool.init(web3NetworkDetails)) {
            readBalance();
          } else {
            setIsSupported(0);
          }
        })();
      }
      setMyFlowNumber(flowNumber);
    }
    // eslint-disable-next-line
  }, [flowNumber, myFlowNumber, poolSet]);

  if (!isSupported) {
    if (!poolSet) {
      return <CircularProgress />;
    } else {
      return (
        <>
          <div className={"centeredError"}>
            <Switch section={"core"} />
          </div>
        </>
      );
    }
  } else if (!poolSet || !pools.length) {
    return <CircularProgress />;
  } else {
    const disabled =
      synrBalance.length < 1 ||
      approvalNeeded ||
      stakingNow ||
      availableSynr === 0;
    return (
      <>
        <BoxButton
          onClick={makeStakeClickHandler}
          disabled={disabled}
          buttonText={"Stake Now"}
        />
        <StakingDialog
          pool={pool}
          opened={stakingOpened}
          setOpened={setStakingOpened}
          stake={stake}
        />
        <MessageDialog
          message={message}
          error={error}
          ok={ok}
          title={dialogTitle}
          opened={!!(message || error)}
          onClose={resetMessageDialog}
          borderColor={"#ffee00"}
          color={"#ffee00"}
        />
      </>
    );
  }
}

export default StakingButton;
