import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const styles = {
  buttonStyle: {
    backgroundColor: "#00000060",
    color: "#FFEE00",
    fontSize: "95%",
    // border: '1px solid #660',
    // padding: 0
  },
  body: {
    backgroundColor: "#00000080",
    color: "white",
    border: "1px solid #707",
    padding: "20px",
    backdropFilter: "blur(4px)",
  },
};

function GenerateSeed({ pool, opened, setOpened }) {
  const closeDialog = () => {
    setOpened(false);
  };

  if (pool) {
    return (
      <Dialog
        open={opened}
        onClose={closeDialog}
        aria-describedby="staking-dialog-description"
        aria-labelledby="staking-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "",
            opacity: 0.95,
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle
          id="staking-dialog-title"
          style={{
            backgroundColor: "#00000044",
            backdropFilter: "blur(12px)",
          }}
        >
          {"Generate Seeds"}
        </DialogTitle>
        <DialogContent style={styles.body} />
        <DialogActions
          style={{
            backdropFilter: "blur(4px)",
          }}
        />
      </Dialog>
    );
  }

  return null;
}

export default GenerateSeed;
