import React from "react";
import { Card, CardContent } from "@mui/material";

import { Box } from "@mui/system";

function SmallItem({ cls = "", title, description }) {
  return (
    <div component="main" className={"tile-base smaller"}>
      <Card className={"paperContainer smaller"} sx={{ padding: "12px  0 0" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 2,
              padding: 0,
              margin: 0,
            }}
          >
            <div className={"tile-title "}>{title}</div>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default SmallItem;
