import _ from "lodash";

import { BN } from "./PayloadUtils";

import { getVestedPercentage } from "./sidePoolViews";

function calculatePenaltyForEarlyUnstake(conf, when, deposit) {
  try {
    if (BN(when).gt(deposit.lockedUntil)) {
      return BN();
    }
    let tokenAmount = BN(deposit.tokenAmountOrID || deposit.stakedAmount);
    const vestedPercentage = getVestedPercentage(
      when,
      deposit.lockedFrom,
      deposit.lockedUntil
    );
    const vestedAmount = tokenAmount.mul(vestedPercentage).div(10000);
    return tokenAmount
      .sub(vestedAmount)
      .mul(conf.earlyUnstakePenalty)
      .div(10000);
  } catch (e) {
    // wrong network
    return BN();
  }
}

async function getConf(pool) {
  return _.pick(await pool.conf(), [
    "status",
    "minimumLockupTime",
    "maximumLockupTime",
    "earlyUnstakePenalty",
    "passAmount",
    "synrAmount",
    "reserved1",
    "reserved2",
    "reserved3",
  ]);
}

async function getUser(pool, address) {
  let user = _.pick(await pool.users(address), ["synrAmount", "passAmount"]);
  user.deposits = [];
  let len = await pool.getDepositsLength(address);
  for (let i = 0; i < len; i++) {
    user.deposits.push(await getDeposit(pool, address, i));
  }
  return user;
}

async function getDeposit(pool, address, index) {
  return _.pick(await pool.getDepositByIndex(address, index), [
    "tokenType",
    "lockedFrom",
    "lockedUntil",
    "tokenAmountOrID",
    "unlockedAt",
    "otherChain",
    "mainIndex",
    "extra",
  ]);
}

async function getMainTvl(pool) {
  return _.pick(await pool.conf(), ["synrAmount", "passAmount"]);
}

export {
  getMainTvl,
  getConf,
  getDeposit,
  getUser,
  calculatePenaltyForEarlyUnstake,
};
